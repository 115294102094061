import React, { createContext } from "react";
import useContactUrls, { ContactUrls } from "api/url/useContactUrls";
import { useTrans } from "i18n";

export const UrlContext = createContext<ContactUrls>({
  contactUrl: "",
  faqUrl: "",
  frontPageUrl: "",
  excursionUrl: "",
  termsAndConditionsUrl: "",
  cookiesUrl: "",
  privacyUrl: ""
});

const UrlLoader: React.FC = ({ children }) => {
  const { langCode } = useTrans();
  const contactUrlState = useContactUrls(langCode);

  return (
    <UrlContext.Provider
      value={{
        faqUrl: contactUrlState.data?.faqUrl || "",
        contactUrl: contactUrlState.data?.contactUrl || "",
        frontPageUrl: contactUrlState.data?.frontPageUrl || "",
        excursionUrl: contactUrlState.data?.excursionUrl || "",
        termsAndConditionsUrl:
          contactUrlState.data?.termsAndConditionsUrl || "",
        cookiesUrl: contactUrlState.data?.cookiesUrl || "",
        privacyUrl: contactUrlState.data?.privacyUrl || ""
      }}
    >
      {children}
    </UrlContext.Provider>
  );
};

export default UrlLoader;
