import styled from "styled-components/macro";
import { spacings } from "styling/spacing";

export const SeparatorLine = styled.hr`
  width: 100%;
  background: #dadada;
  height: 1px;
  border: 0;

  margin: ${spacings.three} 0;
`;
