import React from "react";
import styled from "styled-components/macro";
import { AlertBox, colors, Overline } from "@hurtigruten/shared-components";
import { Info } from "components/Grid";
import EndPort from "./EndPort";
import SailingPlan from "./SailingPlan";
import { useTrans } from "i18n";
import { dateToString, getDuration } from "utilities/time";
import { spacings } from "styling/spacing";
import { Port } from "models/Port";
import { SailingPlanDetail } from "models/BookingOverviewData";

const DepartureAlertBox = styled(AlertBox)`
  margin: ${spacings.one} 0;
`;

interface FromToProps {
  fromPort: Port;
  toPort: Port;
  departure: Date;
  arrival: Date;
  durationString: string;
  sailingPlan?: SailingPlanDetail[];
}

const PaddingLeft = styled.div`
  padding-left: ${spacings.three};
`;

const SolidLine = styled(PaddingLeft)`
  position: relative;

  &:before {
    border-left: 2px solid ${colors.foggyBlue.shade3};

    content: "";
    position: absolute;
    height: calc(100% + ${spacings.three});
    left: 0;
    top: 11px;
  }
`;

const FromTo: React.FC<FromToProps> = ({
  fromPort,
  toPort,
  departure,
  arrival,
  sailingPlan
}) => {
  const { translate, langCode } = useTrans();
  const departureHour = departure.getUTCHours();

  const duration = getDuration({
    useHours: true,
    useMinutesIfLessThanDay: true,
    startDateTime: departure,
    endDateTime: arrival,
    translate: translate
  });

  return (
    <Info>
      {departureHour > 0 && departureHour < 7 && (
        <DepartureAlertBox type="info">
          {translate(x => x.warning.departureTime)}
        </DepartureAlertBox>
      )}
      <Overline>{translate(x => x.labels.fromTo)}</Overline>
      <SolidLine>
        <EndPort
          time={dateToString(departure, langCode, true)}
          port={fromPort}
        />
      </SolidLine>
      <SolidLine>
        <SailingPlan sailingPlan={sailingPlan} duration={duration} />
      </SolidLine>
      <PaddingLeft>
        <EndPort time={dateToString(arrival, langCode, true)} port={toPort} />
      </PaddingLeft>
    </Info>
  );
};

export default FromTo;
