import React from "react";

const HrgSymbolTitleWhite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="165.4" height="70">
    <circle cx="34.7" cy="35.1" r="11.5" fill="#d40000" />
    <g fill="#fff">
      <path d="M37.1 29h3.1v12.4h-3.1z" fillRule="evenodd" />
      <path d="M29.1 41.5l7.8-7.7h-4.6V29h-3.2zm119.6-.1h1.8v-3.7l-.3-6.6h.2l4.8 10.2h2.1V28.9h-1.8v2.8l.3 7.1h-.2l-4.6-9.9h-2.3v12.5zm-9.5 0h7.4v-1.7H141v-3.9h4.8v-1.7H141v-3.6h5.5v-1.7h-7.3v12.6zm-6.7 0h1.8V30.5h3.4v-1.6h-8.6v1.6h3.4v10.9h0zm-9.4.2h.3c2.4 0 4.1-1.2 4.1-3.7v-9h-1.8v8.9c0 1.3-.7 2.1-2.4 2.1h-.3c-1.7 0-2.4-.8-2.4-2.1v-8.9H119v9c0 2.5 1.7 3.7 4.1 3.7zm-12.6-6.8v-4.3h2.6c1.5 0 2.2.7 2.2 2v.3c0 1.3-.7 2-2.1 2h-2.7zm-1.8 6.6h1.8v-4.9h2.9l2 4.9h1.9l-2.4-5.4c1.1-.5 2.1-1.5 2.1-3.2v-.3c0-2.4-1.7-3.6-4-3.6h-4.4l.1 12.5h0zm-15.3 0h1.8V28.9h-1.8v12.5zm-6.8 0h1.8V30.5h3.4v-1.6h-8.6v1.6h3.4v10.9h0zm-11-6.6v-4.3h2.6c1.5 0 2.2.7 2.2 2v.3c0 1.3-.7 2-2.1 2h-2.7zm-1.8 6.6h1.8v-4.9h2.9l2 4.9h1.9L80 36c1.1-.5 2.1-1.5 2.1-3.2v-.3c0-2.4-1.7-3.6-4-3.6h-4.4l.1 12.5h0zm-7 .2h.3c2.4 0 4.1-1.2 4.1-3.7v-9h-1.8v8.9c0 1.3-.7 2.1-2.4 2.1h-.3c-1.7 0-2.4-.8-2.4-2.1v-8.9h-1.8v9c.2 2.5 1.9 3.7 4.3 3.7zm-15.2-.2h1.8v-5.6h5v5.6h1.8V28.9h-1.8v5.2h-5v-5.2h-1.8v12.5zm49.7.2h.3c1.3 0 2.2-.7 2.7-1.7h.2l.4 1.5h1.2v-6.6h-4.5v1.5h2.9v1.2c0 1.6-.8 2.6-2.5 2.6h-.3c-1.6 0-2.4-.9-2.4-2.6V33c0-1.6.8-2.5 2.5-2.5h.3c1.6 0 2.2.8 2.2 2.2v.2h1.7v-.3c0-2.4-1.4-3.7-3.9-3.7h-.3c-2.5 0-4.3 1.4-4.3 4.1v4.7c.1 2.5 1.6 3.9 3.8 3.9z" />
    </g>
  </svg>
);

export default HrgSymbolTitleWhite;
