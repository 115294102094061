import { ApiResponse, ResponseStatus } from "../models";
import { post } from "../utils";

export const postPlatinumUpgrade = async (
  bookingId: string
): Promise<ApiResponse<any>> => {
  const response = await post(
    `/api/platinumUpgrade`,
    JSON.stringify({ BookingId: bookingId })
  );

  if (response.status === ResponseStatus.SUCCESS) {
    return {
      ...response
    };
  }

  return {
    status: ResponseStatus.ERROR,
    error: {
      errorCode: response.status,
      errorMessage: ""
    }
  };
};
