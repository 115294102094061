import { css } from "styled-components";

export interface PrintProps {
  hideOnPrint?: boolean;
}

const hideOnPrint = css`
  @media print {
    ${(props: PrintProps) => (props.hideOnPrint ? "display:none;" : "")}
  }
`;

export default hideOnPrint;
