import { LanguageResource } from "i18n/models/resources";

const norwegian: LanguageResource = {
  cancelled: {
    title: "Bestilling kansellert",
    text: "Din bestilling ble desverre kansellert {{date}}"
  },
  error: {
    notsupported: {
      title: "Beklager!",
      secondaryTitle:
        "Det ser ut til at bestillingen din ikke er tilgjengelig.",
      description:
        "Vi støtter for øyeblikket ikke reiser som er bestilt via reisebyrå og enkelte samarbeidspartnere.",
      helpText: "Hvis dette ikke er tilfellet, kontakt oss for videre hjelp.",
      button: "Logg inn"
    },
    general: {
      title: "Beklager!",
      secondaryTitle: "Det fungerte ikke.",
      description:
        "Det kan være noe feil med lenken du bruker. Logg inn på nytt.",
      helpText:
        "Hvis du ser denne feilen igjen, kan det hende at My Booking ikke fungerer som den skal. Kontakt oss for videre hjelp.",
      button: "Logg inn"
    },
    bookingCancelled: {
      title: "Beklager!",
      secondaryTitle:
        "Det ser ut til at dette bestillingsnummeret har blitt endret.",
      description:
        "Hvis du har et nytt bestillingsnummer, bruk dette for å logge inn og se oppdatert bestillingsinformasjon.",
      helpText: "Hvis dette ikke er tilfellet, kontakt oss for videre hjelp.",
      button: "Logg inn"
    }
  },
  login: {
    applicationName: "My Booking",
    description:
      "Se bestillingen din, og forbered deg på reisen som ligger foran deg",
    formExplanation:
      "Bruk bestillingsdetaljene dine til å få en oversikt over reisen.",
    button: { toMyBooking: "Til My Booking", help: "Har du spørsmål?" },
    errorMessage: {
      unauthorized:
        "Beklager, det ser ut til at bestillingsnummer, e-postadresse og/eller navn er feil. Sjekk at oppgitt e-postadresse og navn tilhører personen som bestilte reisen.",
      systemDown:
        "Beklager, det ser ut til at systemet vårt er nede. Prøv igjen senere.",
      default: "Noe gikk galt. Prøv igjen senere, eller kontakt kundeservice.",
      bookingRef: "Dette feltet skal kun inneholde tall."
    }
  },
  faq: {
    title: "Hvordan kan vi hjelpe deg?",
    description: "Nedenfor finner du svar på spørsmålene vi oftest blir stilt.",
    backLink: "Tilbake til innlogging",
    questions: {
      about: "Hva er My Booking?",
      credentials: "Hvor finner jeg informasjonen jeg trenger for å logge inn?",
      content: "Hva finner jeg i My Booking?",
      cancelled: "Kan jeg se en kansellert bestilling?",
      agency: "Kan jeg se en bestilling gjort gjennom en tredjepart?",
      otherBookings: "Kan jeg se mine andre bestillinger i My Booking?",
      cancel: "Jeg ønsker å kansellere sjøreisen min, hvordan går jeg frem?",
      misspelled: "Navnet mitt er stavet feil, hva gjør jeg?"
    },
    answers: {
      about:
        "Vi ønsker å gi deg den beste servicen hele veien fra du bestiller til du har kommet trygt hjem etter en av reisene våre. My Booking er en webtjeneste der du kan finne nøkkelinformasjon om bestillingen din.",
      credentials:
        "Bruk referansenummeret for bestillingen din (slik det står på fakturaen), e-postadresse og fullt navn på personen som bestilte (også kalt hovedpassasjer).",
      content:
        "Få en rask oversikt over detaljene for sjøreisen din. Se avreise- og ankomstdatoer, bestilte utflukter og transport relatert til reisen din. Gå gjennom informasjon om gjest, lugar og betaling. Les mer om reiseruten, og bli kjent med skipet før reisen.",
      cancelled:
        "Nei, hvis du fyller inn referansen for en kansellert bestilling, vil du ikke kunne se den.",
      agency:
        "Nei, bestillinger gjort gjennom reisebyrå og enkelte samarbeidspartnere støttes for øyeblikket ikke.",
      otherBookings:
        "My Booking er en løsning der du kan se informasjon om en enkelt bestilling.",
      cancel:
        "Hvis du ønsker å kansellere bestillingen, kan du sende oss en e-post, så håndterer vi det for deg.",
      misspelled: "Hvis navnet ditt er stavet feil, kontakt oss øyeblikkelig."
    }
  },
  contact: {
    contactUs: "Kontakt oss",
    stillGotQuestions: "Har du fortsatt spørsmål?",
    pleaseContactUsPhrase:
      "Nøl ikke med å ta kontakt med oss, vi hjelper deg gjerne."
  },
  aboutVoyage: {
    title: "Få hjelp med din reise",
    description: "Vi hjelper deg gjerne",
    mailto: "Send oss en e-epost"
  },
  feedback: {
    title: "Vil du hjelpe oss å forbedre My Booking?",
    placeholder:
      "Takk for at du hjelper oss å gjøre My Booking bedre! Hva skulle du ønske var annerledes?",
    disclaimer:
      "Vær oppmerksom på at tilbakemeldingene ikke blir besvart. Hvis du har spørsmål eller spørsmål angående en bestemt reise, kan du kontakte kundeservice.",
    buttonText: "Send tilbakemelding"
  },
  thankyou: {
    title: "Takk!",
    description:
      "Tilbakemeldingen din er nå sendt inn. Vi tar den i betraktning for fremtidige forbedringer av My Booking.",
    button: "Send flere tilbakemeldinger"
  },
  questionsTile: {
    title: "Har du spørsmål?",
    description:
      "Før du kontakter oss kan du ta en titt på våre ofte stilte spørsmål. Hvis du imidlertid foretrekker å kontakte oss direkte hjelper vi deg gjerne.",
    linkText: "Les ofte stilte spørsmål"
  },
  bookingOverview: {
    links: {
      learnMoreAboutYourVoyage: "Les mer om reisen din",
      managePayment: "Betalingsoversikt",
      manageQuotePayment: "Betal og bestill"
    },
    detailedInformation:
      "Info: Billetten din og spesifikk informasjon om reiseruten og flygningene dine vil bli sendt til deg på e-post 2–4 uker før reisen starter."
  },
  paymentStatus: {
    title: "Oppsummering og betaling",
    paymentStatus: "Betalingsstatus",
    partlyPaid: "Delvis betalt",
    fullyPaid: "Betalt i sin helhet",
    noPaymentReceived: "Ingen betaling mottatt",
    due: "Forfaller",
    depositDue: "Depositum forfaller",
    pendingPayment: "Avventer betaling"
  },
  cabins: {
    header: "Mine lugarer",
    notYetAvailable: "Ikke tilgjengelig enda",
    facilities: {
      window: {
        pothole: "Kuøye",
        window: "Vindu",
        windowLimitedView: "Vindu med begrenset utsikt",
        noInformation: ""
      },
      bed: {
        doubleBed: "Dobbeltseng",
        twinBed: "To enkeltsenger",
        oneBedOneCouch: "1 seng/1 sofa",
        bunkBeds: "Køyesenger",
        beds: "Senger",
        mixed: "Blandet",
        noInformation: ""
      },
      bathroom: "Bad",
      balcony: "Balkong",
      sofa: "Sofa",
      tv: "TV",
      dinnerTable: "Spisebord"
    },
    cabinGrade: "Lugarklasse"
  },
  excursions: {
    yourExcursions: "Dine utflukter",
    othersExcursions: "Utflukter for andre i ditt reisefølge",
    links: {
      moreInfo: "Mer info",
      viewMore: "Se flere utflukter"
    },
    customize: "Tilpass reisen din!",
    loveToHelp: "Vi vil gjerne hjelpe deg å få mest mulig ut av turen din.",
    summary: "{{date}} for {{count}} person",
    summary_plural: "{{date}} for {{count}} personer",
    information: {
      noExcursions: "Du har ikke forhåndsbestilt noen utflukter enda.",
      moreExcursions:
        "Noen utflukter relatert til denne reisen er muligens ikke tilgjengelig for bestilling før 6–9 måneder før reisen starter."
    }
  },
  logout: {
    logout: "Logg ut"
  },
  consents: {
    button: {
      accept: "Jeg forstår",
      cookieLinkText: "Mer informasjon om cookies"
    }
  },
  meals: {
    bookedMeals: "Bestilte måltider"
  },
  words: {
    minute: "minutt",
    minute_plural: "minutter",
    hour: "time",
    hour_plural: "timer",
    day: "dag",
    day_plural: "dager",
    cabin: "lugar",
    cabin_plural: "lugarer",
    excursion: "utflukt",
    excursion_plural: "utflukter",
    person: "person",
    person_plural: "personer",
    car: "bil",
    car_plural: "biler",
    motorcycle: "motorsykkel",
    motorcycle_plural: "motorsykler",
    flight: "Fly",
    hotel: "Hotell",
    rail: "Tog",
    transfer: "Transport",
    breakfast: "frokost",
    lunch: "lunsj",
    dinner: "middag",
    and: "og",
    map: "Kart",
    yes: "Ja",
    no: "Nei",
    add: "Legg til",
    edit: "Rediger",
    readMore: "Les mer",
    arrival: "Ankomst",
    departure: "Avgang",
    stop: "stopp",
    stop_plural: "stopp"
  },
  labels: {
    facilities: "Fasiliteter",
    category: "Kategori",
    guests: "Gjester",
    leadPassenger: "Hovedkontakt",
    membershipLevel: "Medlemsnivå",
    date: "Dato",
    voyage: "Reise",
    ship: "Skip",
    included: "Inkludert",
    bookedServices: "Bestilte tjenester",
    travelPeriod: "Reiseperiode",
    sailingPeriod: "Seilingsperiode",
    duration: "Varighet",
    bookingReferenceNumber: "Reservasjonsnummer",
    quoteReferenceNumber: "Tilbud",
    quoteValidUntil: "Dette tilbudet er gyldig til",
    quoteDisclaimer:
      "Hvis ingen betaling foretas før den tid, vil tilbudet ditt automatisk kanselleres. Vennligst les bestillingsdetaljene nøye og kontakt oss hvis du ønsker å endre noen av detaljene. Hvis du ønsker å konvertere tilbudet ditt til en bestilling, må du betale.",
    bookingEmail: "E-post",
    fullName: "Fullt navn",
    cabinNumber: "Lugarnummer",
    vehicle: "Kjøretøy",
    vehicle_plural: "Kjøretøy",
    fromTo: "Fra — til",
    passengerInfo: "Passasjerinfo",
    travellingWith: "Reiser med",
    exploring: "Utforsker",
    total: "Totalt",
    perPerson: "Per person"
  },
  alternativeTexts: {
    excursionImage: "Bilde av utflukt"
  },
  warning: {
    departureTime: "Vennligst merk at skipet har avreise etter midnatt."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "Edit email",
    close: "Close",
    updateButtonText: "Update Email",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Vis tilgangslenke",
    hideLink: "Skjul tilgangslenke",
    copy: "Kopier",
    close: "lukk",
    description: "Med denne lenken kan {{name}} logge seg inn."
  },
  whosTraveling: {
    title: "Hvem reiser?"
  },
  upcomingVoyage: {
    voyageDateDeteails: `Reisen begynner {{startDate}}, slutter {{endDate}}`
  },
  footer: {
    termsAndConditions: "Vilkår og betingelser",
    statementOfPrivacy: "Personvernerklæring",
    cookies: "Cookies",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "Les mer om platinum-billetter",
    button: "Be om platinum",
    loading: "Loading",
    requestSuccess:
      "Forespørselen din er sendt. Vi tar kontakt med deg for å informere deg nærmere",
    requestError:
      "Det ser ut til at noe gikk galt. Prøv igjen. Eller kontakt oss på telefon eller e-post.",
    upgradeInitiated:
      "Takk for at du oppgraderte til Platinum. Vi tar snart kontakt med flere detaljer.",
    upgradeInProgress:
      "Vi forbereder oss for å sende deg en e-post om Platinum-oppgraderingen din.",
    upgradeDeclined:
      "Vi mottok ikke bekreftelsen din, så vi har ikke kunnet oppgradere deg til Platinum. Ta kontakt med oss ​​hvis du bestemmer deg for å oppgradere.",
    upgradeApproved: "Gratulerer – du har blitt oppgradert til Platinum!"
  }
};

export default norwegian;
