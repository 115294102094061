import { createDateAsUTC } from "api/bookingoverview/mapping";
import { PaymentInfo } from "models/Payment";

const mapPaymentData = (responseJson: any): PaymentInfo => {
  return {
    ...responseJson,
    deposit: {
      ...responseJson.deposit,
      dueDate: createDateAsUTC(responseJson.deposit.dueDate)
    },
    payment: {
      ...responseJson.payment,
      dueDate: createDateAsUTC(responseJson.payment.dueDate)
    }
  };
};

export default mapPaymentData;
