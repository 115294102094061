import { MealDetail, Meal } from "models/Meal";
import { Voyage } from "models/BookingOverviewData";
import { localizedJoin } from "utilities/string";

import { Translate } from "i18n";

interface MealTypes {
  hasBreakfast: boolean;
  hasLunch: boolean;
  hasDinner: boolean;
}

export interface MealStrings {
  voyage: string;
  description: string;
}

/*
  Returns an object that tells if a specific passenger has breakfast, lunch and dinner booked
*/
const getMealTypesForPassenger = (
  passengerId: string,
  mealDetails?: MealDetail[]
) => {
  let passengerMeals: Meal[] = [];
  const mealTypes = {
    hasBreakfast: false,
    hasLunch: false,
    hasDinner: false
  };

  if (!mealDetails) {
    return mealTypes;
  }

  mealDetails.forEach(md => {
    if (md.passenger.passengerId === passengerId) {
      passengerMeals = md.meals;
    }
  });

  passengerMeals.reduce((result, meal: Meal) => {
    if (meal.description === "BREAKFAST") {
      result.hasBreakfast = true;
    }
    if (meal.description === "LUNCH") {
      result.hasLunch = true;
    }
    if (meal.description === "DINNER") {
      result.hasDinner = true;
    }

    return result;
  }, mealTypes);

  return mealTypes;
};

/*
  Builds a translated string of what meals a customer has booked. For instance: Breakfast, lunch and dinner.
*/
const buildMealDescription = (translate: Translate, mealTypes: MealTypes) => {
  const meals: string[] = [];

  if (mealTypes.hasBreakfast) {
    meals.push(translate(x => x.words.breakfast));
  }

  if (mealTypes.hasLunch) {
    meals.push(translate(x => x.words.lunch));
  }

  if (mealTypes.hasDinner) {
    meals.push(translate(x => x.words.dinner));
  }

  return localizedJoin(meals, translate);
};

/*
  Builds a translated string of what meals a customer has booked for each leg in the voyage. If all legs are equal the strings are combined.
*/
export const getMealStringForAllVoyages = (
  translate: Translate,
  voyages: Voyage[],
  passengerId: string
): MealStrings[] => {
  const meals = voyages.map(voyage => {
    const fromPort = voyage.fromPort.name || voyage.fromPort.code;
    const toPort = voyage.toPort.name || voyage.toPort.code;

    const fromTo = `${fromPort}-${toPort}`;
    const packageName =
      voyage.editorialContent?.heading || voyage.packageDescription;
    const description = buildMealDescription(
      translate,
      getMealTypesForPassenger(passengerId, voyage.meals)
    );

    return {
      voyage: packageName || fromTo,
      description
    };
  });

  const filteredMeals = meals.filter(meal => meal.description !== "");

  const sameMealsForWholeVoyage = filteredMeals.every((meal, index, array) => {
    return meal.description === array[0].description;
  });

  if (filteredMeals.length > 0 && sameMealsForWholeVoyage) {
    return [filteredMeals[0]];
  }

  return filteredMeals;
};
