import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "core-js/features/array/flat";
import "core-js/features/array/includes";
import "core-js/features/symbol";
import "url-search-params-polyfill";
import App from "./App";
import { Brand, BrandProvider } from "@hurtigruten/shared-components";

const urlParamToBrand = (num: string | null): Brand | undefined => {
  switch (num) {
    case "1":
      return "group";
    case "2":
      return "coastal";
    case "3":
      return "expedition";
    default:
      return undefined;
  }
};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require("react-axe");
  axe(React, ReactDOM, 1000);
}

const urlParams = new URLSearchParams(window.location.search);
const urlBrand = urlParams.get("_hrgb");
const localStorageBrand = localStorage.getItem("_hrgb");

const brand = urlParamToBrand(urlBrand ?? localStorageBrand);

if (urlBrand) {
  localStorage.setItem("_hrgb", urlBrand);
}

ReactDOM.render(
  <BrandProvider fixedBrand={brand}>
    <App />
  </BrandProvider>,
  document.getElementById("root")
);
