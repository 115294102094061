import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

interface Alert {
  title: string;
  description: string;
  url: string;
  showXDaysBeforeDeparture: string;
}

const getAlert = async (language: string): Promise<ApiResponse<Alert>> => {
  const response = await get(`/api/alert/${language}`);

  return mapToApiResponse<Alert>({
    response,
    propertyToCheck: "title",
    fallBackErrorMessage: "Unexpected response type: Type did not match Alert"
  });
};

const useAlert = (
  language: string
): {
  data?: Alert;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<Alert, string>(getAlert, language);
};

export default useAlert;
