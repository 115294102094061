import React from "react";
import styled from "styled-components/macro";

import HeaderBar from "components/HeaderBar";
import { useTrans } from "i18n";
import { RouteComponentProps, Link } from "@reach/router";
import ArrowLeft from "icons/iconComponents/ArrowLeft";
import {
  Header1,
  LinkText,
  Subtitle,
  TabularHeader,
  TabularText
} from "@hurtigruten/shared-components";
import Contact from "./Contact";
import { spacings } from "styling/spacing";
import { Footer } from "components/Footer";
import useTrackPageView from "hooks/useTrackPageView";

const CenterContent = styled.div`
  max-width: 700px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 50px auto;
`;

const CenteredMaxWidth = styled.nav`
  max-width: 1240px;
  padding: 0 20px;
  margin: 20px auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 30px 0 50px 0;
`;

const Question = styled.li`
  margin-bottom: ${spacings.four};
`;

const BackLink = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
`;

const AboutPage: React.FC<RouteComponentProps> = () => {
  const { translate, langCode } = useTrans();
  useTrackPageView("/help", "HelpPage");

  return (
    <>
      <HeaderBar isLoggedIn={false} />
      <CenteredMaxWidth>
        <BackLink as={Link} to={`/${langCode}`}>
          <ArrowLeft />
          <LinkText>{translate(x => x.faq.backLink)}</LinkText>
        </BackLink>
      </CenteredMaxWidth>
      <CenterContent>
        <Header1>{translate(x => x.faq.title)}</Header1>
        <Subtitle>{translate(x => x.faq.description)}</Subtitle>
        <List>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.about)}
            </TabularHeader>
            <TabularText>{translate(x => x.faq.answers.about)}</TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.credentials)}
            </TabularHeader>
            <TabularText>
              {translate(x => x.faq.answers.credentials)}
            </TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.content)}
            </TabularHeader>
            <TabularText>{translate(x => x.faq.answers.content)}</TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.cancelled)}
            </TabularHeader>
            <TabularText>{translate(x => x.faq.answers.cancelled)}</TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.agency)}
            </TabularHeader>
            <TabularText>{translate(x => x.faq.answers.agency)}</TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.otherBookings)}
            </TabularHeader>
            <TabularText>
              {translate(x => x.faq.answers.otherBookings)}
            </TabularText>
          </Question>
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.cancel)}
            </TabularHeader>
            <TabularText>{translate(x => x.faq.answers.cancel)}</TabularText>
          </Question>{" "}
          <Question>
            <TabularHeader marginBottom="two">
              {translate(x => x.faq.questions.misspelled)}
            </TabularHeader>
            <TabularText>
              {translate(x => x.faq.answers.misspelled)}
            </TabularText>
          </Question>
        </List>
        <Contact />
      </CenterContent>
      <Footer />
    </>
  );
};

export default AboutPage;
