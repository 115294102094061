import React, { useContext } from "react";
import { useTrans } from "i18n";
import { ExpandableBox } from "@hurtigruten/shared-components";
import Excursion from "models/Excursion";
import { BookingOverViewContext } from "../BookingLoader";
import ExcursionInfo from "./ExcursionInfo";
import { FullWidth } from "../../../components/Grid";
import { ExpandableBoxContentContainer } from "../../../components/Grid";
import ViewMoreExcursions from "./ViewMoreExcursions";

const ExcursionList: React.FC = () => {
  const { translate } = useTrans();
  const { voyages, cancelled, bookMoreExcursionsLinks } = useContext(
    BookingOverViewContext
  );

  const excursions = voyages
    .map(voyage => [...voyage.excursions, ...(voyage.includedExcursions || [])])
    .flat();

  if (
    (!excursions || excursions.length < 1) &&
    (cancelled ||
      !bookMoreExcursionsLinks ||
      bookMoreExcursionsLinks.length < 1)
  ) {
    return null;
  }

  const excursionHeadings = excursions.map(excursion => {
    return { content: excursion.excursionName };
  });

  return (
    <FullWidth>
      <ExpandableBox
        id="excursions"
        heading={translate(x => x.excursions.yourExcursions)}
        summaryTags={excursionHeadings}
        defaultExpanded={false}
      >
        {excursions && excursions.length >= 1 && (
          <ExpandableBoxContentContainer>
            {excursions.map(
              (excursion: Excursion, index): React.ReactNode => (
                <ExcursionInfo
                  key={excursion.excursionName}
                  excursion={excursion}
                  id={index}
                />
              )
            )}
          </ExpandableBoxContentContainer>
        )}
        {!cancelled && <ViewMoreExcursions hideOnPrint={true} />}
      </ExpandableBox>
    </FullWidth>
  );
};

export default ExcursionList;
