import React, { ReactNode } from "react";
import ErrorMessage from "./ErrorMessage";
import { RouteComponentProps } from "@reach/router";
import useTrackPageView from "hooks/useTrackPageView";
import FullscreenBackgroundImage from "components/FullScreen/FullscreenBackgroundImage";
import CenteredWhiteBox from "components/FullScreen/CenteredWhiteBox";
import HeaderBar from "components/HeaderBar";
import { Footer } from "components/Footer";
import { useTrans, Translate } from "i18n";

interface Props extends RouteComponentProps {
  errorType: ErrorMessageType;
}

type ErrorMessageType = "CANCELLED" | "GENERALERROR" | "NOTSUPPORTED";

const errorMessageSelector = (
  error: ErrorMessageType,
  translate: Translate
): ReactNode => {
  switch (error) {
    case "CANCELLED":
      return (
        <ErrorMessage
          errorTranslate={errorTranslate =>
            translate(x => errorTranslate(x.error.bookingCancelled))
          }
        />
      );
    case "GENERALERROR":
      return (
        <ErrorMessage
          errorTranslate={errorTranslate =>
            translate(x => errorTranslate(x.error.general))
          }
        />
      );
    case "NOTSUPPORTED":
      return (
        <ErrorMessage
          errorTranslate={errorTranslate =>
            translate(x => errorTranslate(x.error.notsupported))
          }
        />
      );
  }
};

const ErrorPage: React.FC<Props> = ({ errorType }) => {
  useTrackPageView(`/error/${errorType.toLowerCase()}`, "ErrorPage");
  const { translate } = useTrans();

  return (
    <>
      <HeaderBar isLoggedIn={false} />
      <FullscreenBackgroundImage>
        <CenteredWhiteBox>
          {errorMessageSelector(errorType, translate)}
        </CenteredWhiteBox>
      </FullscreenBackgroundImage>
      <Footer />
    </>
  );
};

export default ErrorPage;
