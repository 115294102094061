import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

export interface TPlatinumBannerData {
  heading?: string;
  subHeading?: string;
  bulletpoints1?: BulletPoint[];
  bulletpoints2?: BulletPoint[];
  bulletpoints3?: BulletPoint[];
  url?: string;
  cta?: string;
}

export interface BulletPoint {
  value: string;
}

const getPlatinumBanner = async (
  language: string
): Promise<ApiResponse<TPlatinumBannerData>> => {
  const response = await get(`/api/platinumBannerContent/${language}`);

  return mapToApiResponse<TPlatinumBannerData>({
    response,
    propertyToCheck: "heading",
    fallBackErrorMessage: "Unexpected response type: Type did not match Banner"
  });
};

const usePlatinumBannerEpiContent = (
  language: string
): {
  data?: TPlatinumBannerData;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<TPlatinumBannerData, string>(getPlatinumBanner, language);
};

export default usePlatinumBannerEpiContent;
