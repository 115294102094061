import styled from "styled-components/macro";

const FullScreen = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 75px);
`;

export const FullScreenCenteredContent = styled(FullScreen)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FullScreen;
