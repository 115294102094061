import { useState } from "react";
import { ResponseStatus, LoadedTypeResponse, ErrorResponse } from "../models";
import { postLogin, LoginSuccess } from "./api";
import { LoginFormData } from "pages/login/LoginForm";
import { paths } from "App";
import { Translate } from "i18n";

const useLogin = (translate: Translate, language: string): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [redirect, setRedirect] = useState<string>();

  const postLoginForm = async (formData: LoginFormData): Promise<void> => {
    setIsLoading(true);
    const response = await postLogin(formData, translate);
    if (response.status === ResponseStatus.SUCCESS) {
      const loginSuccess = response as LoadedTypeResponse<LoginSuccess>;
      setRedirect(`/${language}/${loginSuccess.data}`);
      setErrorMessage(undefined);
    }

    if (response.status === ResponseStatus.ERROR) {
      const loginFailed = response as ErrorResponse;
      if (loginFailed.error.errorCode === 403) {
        setRedirect(`/${language}${paths.error.notsupported}`);
      } else {
        setErrorMessage(loginFailed.error.errorMessage);
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = (formData: LoginFormData): void => {
    postLoginForm(formData);
  };

  return {
    handleSubmit,
    isLoading,
    errorMessage,
    redirect
  };
};

export default useLogin;
