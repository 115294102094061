import Cabin from "models/Cabin";

// todo: check for classic voyage as well

const arcticSuperior = {
  name: "ARCTIC SUPERIOR",
  cabins: ["U2", "U2D", "P2", "P2D"]
};

const expeditionSuite = {
  name: "EXPEDITION SUITE",
  cabins: ["Q2", "Q4", "M2", "M3", "MG", "MG4"]
};

export const cabinSupportsPlatinumUpgrade = (c: Cabin) => {
  if (c?.cabinGrade) {
    let { cabinGrade: { code } = {}, title } = c;
    code = code?.toUpperCase() ?? "";
    title = title.toUpperCase() ?? "";
    // if MS Trollfjord return false
    if (arcticSuperior.cabins.includes(code) && title === arcticSuperior.name) {
      return true;
    }

    if (
      expeditionSuite.cabins.includes(code) &&
      title === expeditionSuite.name
    ) {
      return true;
    }
  }

  return false;
};
