import React, { useContext } from "react";
import { UrlContext } from "UrlLoader";
import {
  BodyText,
  Header2,
  SecondaryButton
} from "@hurtigruten/shared-components";

import { useTrans } from "i18n";

const Contact: React.FC = () => {
  const { translate } = useTrans();
  const { contactUrl } = useContext(UrlContext);
  return (
    <>
      <Header2 marginBottom="two">
        {translate(x => x.contact.stillGotQuestions)}
      </Header2>
      <BodyText>{translate(x => x.contact.pleaseContactUsPhrase)}</BodyText>
      <SecondaryButton as="a" href={contactUrl} marginTop="two" target="_blank">
        {translate(x => x.contact.contactUs)}
      </SecondaryButton>
    </>
  );
};

export default Contact;
