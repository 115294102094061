import { useState, useEffect } from "react";
import { screenSize } from "utilities/media";
import { useScreenWidth } from "hooks/useScreenWidth";

export const useLazyLoadedImage = (source: string, placeholder: string) => {
  const width = useScreenWidth();
  const [src, setSource] = useState(placeholder);

  useEffect(() => {
    if (width > screenSize.width.medium && src !== source) {
      const imageLoader = new Image();
      imageLoader.src = source;

      imageLoader.onload = () => {
        setSource(source);
      };
    }
  }, [width, src, source]);

  return src;
};
