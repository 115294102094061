import React, { useState } from "react";
import { LanguageResource } from "./models/resources";
import { Helmet } from "react-helmet";

import EN from "./resources/en";
import NO from "./resources/no";
import SV from "./resources/sv";
import DA from "./resources/da";
import DE from "./resources/de";
import FR from "./resources/fr";

/*
Type definitions
*/
interface Translations {
  en: LanguageResource;
  gb: LanguageResource;
  au: LanguageResource;
  us: LanguageResource;
  no: LanguageResource;
  da: LanguageResource;
  sv: LanguageResource;
  de: LanguageResource;
  fr: LanguageResource;
  ch: LanguageResource;
}

const translations: Translations = {
  en: EN,
  gb: EN,
  au: EN,
  us: EN,
  no: NO,
  da: DA,
  sv: SV,
  de: DE,
  fr: FR,
  ch: DE
};

const htmlLanguageCodes: Record<LanguageCode, string> = {
  en: "en",
  gb: "en",
  au: "en",
  us: "en",
  no: "nb",
  da: "da",
  sv: "sv",
  de: "de",
  fr: "fr",
  ch: "de"
};

export type LanguageCode = keyof Translations;

type TranslationFunction = (
  languageResource: LanguageResource,
  format: (x: string, y?: { [key: string]: string }) => string
) => string;

export type Translate = (func: TranslationFunction) => string;

/*
Helper functions
*/
const languageCodes = Object.keys(translations);
export const isLanguageCode = (code: string): code is LanguageCode =>
  languageCodes.includes(code);

const createStringFromTemplate = (
  template: string,
  variables?: { [key: string]: string }
) => {
  if (!variables || !template) {
    return template;
  }

  let returnValue = template;

  Object.keys(variables).forEach(key => {
    const regexp = new RegExp(`{{${key}}}`);

    returnValue = returnValue.replace(regexp, variables[key]);
  });

  return returnValue;
};

const getTranslate = (langCode: LanguageCode) => (temp: TranslationFunction) =>
  temp(translations[langCode], createStringFromTemplate);

/*
Provider component 
*/
interface State {
  langCode: LanguageCode;
  translate: Translate;
}

interface Context extends State {
  setLanguage: (langCode: LanguageCode) => void;
}

const initialState: State = {
  langCode: "en",
  translate: getTranslate("en")
};

export const LanguageContext = React.createContext(initialState as Context);
export const useTrans = () => React.useContext(LanguageContext);

const LanguageProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State>(initialState);

  const setLanguage = (langCode: LanguageCode) =>
    setState({
      langCode: langCode,
      translate: getTranslate(langCode)
    });

  return (
    <LanguageContext.Provider value={{ ...state, setLanguage }}>
      <Helmet
        htmlAttributes={{ lang: htmlLanguageCodes[state.langCode] }}
      ></Helmet>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
