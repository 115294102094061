import React, { useContext } from "react";
import { useTrans } from "i18n";

import { BookingOverViewContext } from "../BookingLoader";
import { FullWidth } from "components/Grid";
import { CabinsDetails } from "./CabinsDetails";
import { Voyage } from "models/BookingOverviewData";
import { ExpandableBox } from "@hurtigruten/shared-components";

const hasCabins = (voyageLegs: Voyage[]) => {
  let result = false;
  voyageLegs.forEach(leg => {
    const { cabins } = leg;
    if (cabins && cabins.length > 0) {
      result = true;
    }
  });
  return result;
};

const Cabins: React.FC = () => {
  const { translate } = useTrans();
  const { voyages } = useContext(BookingOverViewContext);
  if (!hasCabins(voyages)) {
    return null;
  }

  const cabinHeadings = voyages.reduce(
    (list: { content: string }[], voyage: Voyage) => {
      return [
        ...list,
        ...(voyage.cabins
          ? voyage.cabins
              .map(cabin => {
                return { content: cabin.title };
              })
              .filter(tag => tag.content)
          : [])
      ];
    },
    []
  );

  return (
    <FullWidth>
      <ExpandableBox
        id={"cabins"}
        heading={translate(x => x.cabins.header)}
        defaultExpanded={false}
        summaryTags={cabinHeadings}
      >
        <CabinsDetails />
      </ExpandableBox>
    </FullWidth>
  );
};

export default Cabins;
