import { ApiResponse, ResponseStatus, ErrorResponse } from "../models";
import { successfulObject, RestResponse } from ".";

function mapToApiResponse<T>({
  response,
  propertyToCheck,
  fallBackErrorMessage,
  mapFunction
}: {
  response: RestResponse;
  propertyToCheck: string;
  fallBackErrorMessage: string;
  mapFunction?: (responseJson: Record<string, unknown>) => T;
}): ApiResponse<T> {
  if (
    successfulObject(response) &&
    Object.prototype.hasOwnProperty.call(response.data, propertyToCheck)
  ) {
    return {
      status: response.status,
      data: mapFunction
        ? mapFunction(response.data)
        : ((response.data as unknown) as T)
    };
  }
  if (Object.prototype.hasOwnProperty.call(response, "error")) {
    const errorResponse = response as ErrorResponse;
    return {
      status: ResponseStatus.ERROR,
      error: {
        errorCode: errorResponse.error.errorCode,
        errorMessage: errorResponse.error.errorMessage
      }
    };
  }
  return {
    status: ResponseStatus.ERROR,
    error: {
      errorCode: response.status,
      errorMessage: fallBackErrorMessage
    }
  };
}

export default mapToApiResponse;
