import { Router, Redirect } from "@reach/router";
import React from "react";
import LanguageHandler from "./i18n/LanguageHandler";
import BookingPage from "./pages/booking";
import LoginPage from "./pages/login";
import ErrorPage from "./pages/error";
import AboutPage from "./pages/about";
import Consent from "components/Consent";
import UrlLoader from "UrlLoader";
import LanguageProvider from "i18n";
import { Helmet } from "react-helmet";

export const paths = {
  error: {
    notsupported: "/unsupported",
    expired: "/expired",
    default: "/oops"
  }
};

const homepageUrls = [
  "https://mybooking.hurtigruten.com",
  "https://mybooking.hurtigruten.com/fr",
  "https://mybooking.hurtigruten.com/de",
  "https://mybooking.hurtigruten.com/ch",
  "https://mybooking.hurtigruten.com/no",
  "https://mybooking.hurtigruten.com/sv",
  "https://mybooking.hurtigruten.com/da",
  "https://mybooking.hurtigruten.com/en",
  "https://mybooking.hurtigruten.com/au",
  "https://mybooking.hurtigruten.com/us"
];

const App: React.FC = () => (
  <LanguageProvider>
    {!homepageUrls.includes(window.location.href) && (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    )}
    <UrlLoader>
      <Consent />
      <Router>
        <Redirect from="/" to={"/en"} noThrow />
        <LanguageHandler path="/:lang">
          <LoginPage path="/" />
          <AboutPage path="/help" />
          <ErrorPage path={paths.error.notsupported} errorType="NOTSUPPORTED" />
          <ErrorPage path={paths.error.default} errorType="GENERALERROR" />
          <ErrorPage path={paths.error.expired} errorType="CANCELLED" />
          <BookingPage path="/:encryptedId" />
        </LanguageHandler>
      </Router>
    </UrlLoader>
  </LanguageProvider>
);

export default App;
