import React from "react";
import { useTrans } from "i18n";

import {
  BodyText,
  Header1,
  PrimaryButton,
  Subtitle
} from "@hurtigruten/shared-components";
import { Link } from "@reach/router";
import { Error } from "i18n/models/resources";

export type ErrorTranslate = (func: (errorStrings: Error) => string) => string;

interface ErrorMessageProps {
  errorTranslate: ErrorTranslate;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorTranslate }) => {
  const { langCode } = useTrans();

  return (
    <>
      <Header1>{errorTranslate(x => x.title)}</Header1>
      <Subtitle marginBottom="four">
        {errorTranslate(x => x.secondaryTitle)}
      </Subtitle>
      <BodyText marginBottom="two">
        {errorTranslate(x => x.description)}
      </BodyText>
      <BodyText marginBottom="two">{errorTranslate(x => x.helpText)}</BodyText>
      <PrimaryButton as={Link} to={`/${langCode}`} marginTop="five">
        {errorTranslate(x => x.button)}
      </PrimaryButton>
    </>
  );
};

export default ErrorMessage;
