import React, { useContext, useEffect, useState } from "react";
import { useTrans } from "i18n";
import usePlatinumBannerEpiContent from "api/platinum/usePlatinumBannerEpiContent";
import { postPlatinumUpgrade } from "api/platinum/postPlatinumUpgrade";
import usePlatinumStatus from "api/platinum/usePlatinumStatus";
import { StatusTypes, TPlatinumStatus } from "api/platinum/usePlatinumStatus";
import { BookingOverViewContext } from "../BookingLoader";
import styled from "styled-components/macro";
import { spacings } from "styling/spacing";
import {
  LinkText,
  colors,
  SecondaryButton,
  Header4,
  Icon,
  Header3,
  CircleLoader
} from "@hurtigruten/shared-components";
import { media } from "utilities/media";
import { getFixedPrice } from "utilities/fixedPrice";

const PlatinumBanner: React.FC = () => {
  const { translate, langCode } = useTrans();
  const { bookingId, voyages, currency, isPlatinum } = useContext(
    BookingOverViewContext
  );
  const { data: platinumBannerContent } = usePlatinumBannerEpiContent(langCode);
  const { data: platinumStatus } = usePlatinumStatus(bookingId, isPlatinum);
  const [isProcessingRequest, setProcessUpgradeRequest] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [hasRequestedPlatinum, setHasRequestedPlatinum] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const fixedPrice = getFixedPrice(voyages[0], currency);

  const PlatinumBannerContainer = styled.div`
    padding: ${spacings.four};
    background-color: ${colors.coastalNavyBlue.shade040} !important;
    border-radius: 8px;
    ${media.maxWidth.medium} {
      padding: ${spacings.two};
    }
  `;

  const CustomHeading = styled(Header3)`
    margin-bottom: ${spacings.four};
  `;

  const Price = styled(Header4)`
    margin-bottom: ${spacings.four};
    margin-bottom: 0;
  `;

  const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: ${spacings.one};
    align-items: center;
    ${media.maxWidth.medium} {
      flex-flow: column;
    }
  `;

  const IconContainer = styled(Icon)`
    min-width: 20px;
  `;

  const PlatinumBannerGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${spacings.four};
    padding-left: 12px;
    ${media.maxWidth.medium} {
      grid-template-columns: 1fr;
    }
  `;

  const PlatinumBannerStatus = styled(PlatinumBannerContainer)`
    text-align: center;
    display: flex;
    flex-flow: column;
    gap: 20px;
  `;

  const ListItem = styled.li`
    margin: ${spacings.one};
    list-style: disc;
  `;

  const PlatinumBannerFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: ${spacings.four};
    ${media.maxWidth.medium} {
      margin-top: ${spacings.five};
      flex-direction: column;
      gap: 30px;
    }
  `;

  const PlatinumBannerFooterContainerRight = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    ${media.maxWidth.medium} {
      flex-direction: column;
    }
  `;

  const ActionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${spacings.four};
    align-self: flex-end;
    ${media.maxWidth.medium} {
      gap: ${spacings.two};
      flex-direction: column;
    }
  `;

  const ActionFeedback = styled.div`
    margin-top: ${spacings.four};
  `;

  const LoaderCentered = styled.div`
    display: flex;
    margin: 2% auto;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  `;

  const handleUpgrade = () => {
    setProcessUpgradeRequest(true);
    postPlatinumUpgrade(bookingId)
      .then(() => {
        setProcessUpgradeRequest(false);
        setRequestSuccess(true);
      })
      .catch(() => {
        setProcessUpgradeRequest(false);
        setRequestError(true);
      });
  };

  useEffect(() => {
    if (platinumStatus) {
      setIsLoadingStatus(false);
      if (platinumStatus.status !== StatusTypes.BLANK) {
        setHasRequestedPlatinum(true);
      }
    }
  }, [platinumStatus, isPlatinum]);

  const Status = ({ text }: { text: string }) => (
    <StatusContainer>
      <IconContainer iconType="circleWithExclamationSmall" />
      <p>{text}</p>
    </StatusContainer>
  );

  const BannerWithStatus = ({
    platinumStatus
  }: {
    platinumStatus: TPlatinumStatus | undefined;
  }) => {
    if (!platinumStatus) return null;
    const { status } = platinumStatus;
    return (
      <PlatinumBannerStatus>
        {(() => {
          switch (status.toLowerCase()) {
            case StatusTypes.NEW:
              return (
                <Status text={translate(x => x.upgrade.upgradeInitiated)} />
              );
            case StatusTypes.INPROGRESS:
              return (
                <Status text={translate(x => x.upgrade.upgradeInProgress)} />
              );
            case StatusTypes.ACCEPTED:
              return (
                <Status text={translate(x => x.upgrade.upgradeApproved)} />
              );
            case StatusTypes.CLOSED_CANCELLED:
            case StatusTypes.CLOSED_BY_GUEST:
            case StatusTypes.CLOSED_BY_HRG:
            case StatusTypes.CLOSED_NO_RESPONSE:
              return (
                <Status text={translate(x => x.upgrade.upgradeDeclined)} />
              );
            default:
              return (
                <Status text={translate(x => x.upgrade.upgradeInitiated)} />
              );
          }
        })()}
        {platinumBannerContent?.url && (
          <LinkText href={platinumBannerContent.url} target="_blank">
            {translate(x => x.upgrade.readMore)}
          </LinkText>
        )}
      </PlatinumBannerStatus>
    );
  };

  const BannerWithSellingPoints = () => (
    <React.Fragment>
      {platinumBannerContent?.subHeading && (
        <CustomHeading>{platinumBannerContent.subHeading}</CustomHeading>
      )}
      <PlatinumBannerGrid>
        {platinumBannerContent?.bulletpoints1?.length && (
          <ul>
            {platinumBannerContent.bulletpoints1.map(b => (
              <ListItem key={b.value}>{b.value}</ListItem>
            ))}
          </ul>
        )}
        {platinumBannerContent?.bulletpoints2?.length && (
          <ul>
            {platinumBannerContent.bulletpoints2.map(b => (
              <ListItem key={b.value}>{b.value}</ListItem>
            ))}
          </ul>
        )}
        {platinumBannerContent?.bulletpoints3?.length && (
          <ul>
            {platinumBannerContent.bulletpoints3.map(b => (
              <ListItem key={b.value}>{b.value}</ListItem>
            ))}
          </ul>
        )}
      </PlatinumBannerGrid>
      <PlatinumBannerFooter>
        {platinumBannerContent?.url && (
          <LinkText href={platinumBannerContent.url} target="_blank">
            {translate(x => x.upgrade.readMore)}
          </LinkText>
        )}
        <PlatinumBannerFooterContainerRight>
          <ActionContainer>
            <Price>
              {`${fixedPrice} ${translate(x => x.labels.perPerson)}`}
            </Price>
            <SecondaryButton type="submit" onClick={handleUpgrade}>
              {isProcessingRequest
                ? translate(x => x.upgrade.loading)
                : translate(x => x.upgrade.button)}
            </SecondaryButton>
          </ActionContainer>
          {requestError && (
            <ActionFeedback>
              <p>{translate(x => x.upgrade.requestError)}</p>
            </ActionFeedback>
          )}
        </PlatinumBannerFooterContainerRight>
      </PlatinumBannerFooter>
    </React.Fragment>
  );

  const BannerWithLoader = () => (
    <LoaderCentered>
      <CircleLoader />
    </LoaderCentered>
  );

  if (isLoadingStatus) return <BannerWithLoader />;

  return (
    <PlatinumBannerContainer>
      {hasRequestedPlatinum || requestSuccess ? (
        <BannerWithStatus platinumStatus={platinumStatus} />
      ) : (
        <BannerWithSellingPoints />
      )}
    </PlatinumBannerContainer>
  );
};

export default PlatinumBanner;
