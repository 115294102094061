import React, { useState } from "react";
import { useTrans } from "i18n";
import styled from "styled-components/macro";
import {
  TextInput,
  PrimaryButton,
  CircleLoader,
  Label,
  HelperText
} from "@hurtigruten/shared-components";

import useLoginQueryParameters from "./useLoginQueryParameters";
import useForm from "./useForm";
import { Margin, spacings } from "styling/spacing";

import { isActive } from "utilities/featureToggles";

const ActionCentered = styled.div`
  display: flex;
  margin: 2% auto;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

const Form = styled.form`
  margin-bottom: ${spacings.four};
`;

export interface LoginFormData {
  [inputKey: string]: string;
}

interface LoginFormProps {
  action: (formData: LoginFormData) => void;
  hasError: boolean;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  action,
  hasError,
  isLoading
}) => {
  const { translate, langCode } = useTrans();
  const [bookingRefError, setBookingRefError] = useState(false);
  const formState = useForm(setBookingRefError);
  useLoginQueryParameters(formState.formData, formState.setFormData, action);

  const validationActive = isActive("bookingRefValidation", langCode);

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    action(formState.formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Margin marginBottom="two">
        <Label htmlFor="bookingRef">{`${translate(
          x => x.labels.bookingReferenceNumber
        )}:`}</Label>
        <TextInput
          id="bookingRef"
          name="bookingRef"
          defaultValue={formState.formData.bookingRef}
          onChange={formState.handleInputChange}
          required
          hasError={hasError || (bookingRefError && validationActive)}
        />
        {bookingRefError && validationActive && (
          <HelperText hasError>
            {translate(x => x.login.errorMessage.bookingRef)}
          </HelperText>
        )}
      </Margin>
      <Margin marginBottom="two">
        <Label htmlFor="email">{`${translate(
          x => x.labels.bookingEmail
        )}:`}</Label>
        <TextInput
          id="email"
          name="email"
          type="email"
          defaultValue={formState.formData.email}
          onChange={formState.handleInputChange}
          required
          hasError={hasError}
        />
      </Margin>
      <Margin marginBottom="two">
        <Label htmlFor="fullName">{`${translate(
          x => x.labels.fullName
        )}:`}</Label>
        <TextInput
          id="fullName"
          name="fullName"
          defaultValue={formState.formData.fullName}
          onChange={formState.handleInputChange}
          required
          hasError={hasError}
        />
      </Margin>
      {isLoading ? (
        <ActionCentered>
          <CircleLoader />
        </ActionCentered>
      ) : (
        <PrimaryButton marginTop="five" type="submit">
          {translate(x => x.login.button.toMyBooking)}
        </PrimaryButton>
      )}
    </Form>
  );
};

export default LoginForm;
