import React from "react";

const MyBookingTitle = () => (
  <svg
    width="100"
    height="12"
    viewBox="0 0 118 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.199951H3.5L6.1 7.99995C6.5 9.09995 6.7 9.79995 6.7 9.79995C6.7 9.79995 6.9 9.09995 7.2 7.89995L10 0.199951H13.4V13.2H11V2.69995C11 2.69995 10.8 3.69995 10.3 5.19995L7.6 13.2H5.7L3 5.19995C2.5 3.69995 2.2 2.69995 2.2 2.69995V13.2H0V0.199951Z"
      fill="currentColor"
    />
    <path
      d="M20.0001 7.69995L15.6001 0.199951H18.2001L21.2001 5.59995L24.3001 0.199951H26.7001L22.3001 7.69995V13.2H20.0001V7.69995Z"
      fill="currentColor"
    />
    <path
      d="M32.8999 13.2V0.199951H38.1999C40.8999 0.199951 42.4999 1.29995 42.4999 3.49995C42.4999 4.99995 41.4999 5.99995 40.1999 6.29995V6.39995C41.7999 6.49995 42.9999 7.69995 42.9999 9.39995C42.9999 12.1 40.9999 13.2 38.0999 13.2H32.8999ZM37.8999 5.59995C39.1999 5.59995 40.1999 5.09995 40.1999 3.69995C40.1999 2.49995 39.3999 1.89995 38.0999 1.89995H35.3999V5.59995H37.8999ZM37.9999 11.5C39.5999 11.5 40.5999 10.9 40.5999 9.29995C40.5999 7.89995 39.5999 7.29995 37.8999 7.29995H35.1999V11.5H37.9999Z"
      fill="currentColor"
    />
    <path
      d="M51.2001 0C54.8001 0 57.2001 2.3 57.2001 6.7C57.2001 11.1 54.7001 13.4 51.2001 13.4C47.5001 13.4 45.2001 11.1 45.2001 6.7C45.1001 2.3 47.7001 0 51.2001 0ZM54.7001 6.7C54.7001 3.4 53.3001 1.7 51.2001 1.7C49.1001 1.7 47.7001 3.3 47.7001 6.7C47.7001 10 49.0001 11.6 51.2001 11.6C53.3001 11.7 54.7001 10 54.7001 6.7Z"
      fill="currentColor"
    />
    <path
      d="M65.3998 0C68.9998 0 71.3998 2.3 71.3998 6.7C71.3998 11.1 68.8998 13.4 65.3998 13.4C61.6998 13.4 59.3998 11.1 59.3998 6.7C59.2998 2.3 61.8998 0 65.3998 0ZM68.8998 6.7C68.8998 3.4 67.4998 1.7 65.3998 1.7C63.2998 1.7 61.8998 3.3 61.8998 6.7C61.8998 10 63.1998 11.6 65.3998 11.6C67.4998 11.7 68.8998 10 68.8998 6.7Z"
      fill="currentColor"
    />
    <path
      d="M76.4 13.2H74V0.199951H76.4V6.29995L81.6 0.199951H84.3L79.5 5.59995L84.6 13.2H81.8L77.9 7.39995L76.4 9.09995V13.2Z"
      fill="currentColor"
    />
    <path
      d="M87.1001 0.199951H89.5001V13.2H87.1001V0.199951Z"
      fill="currentColor"
    />
    <path
      d="M93.1001 0.199951H95.8001L100.6 8.19995C101.2 9.19995 101.4 9.89995 101.4 9.89995H101.5V0.199951H103.7V13.2H101L96.2001 5.09995C95.6001 4.09995 95.3001 3.39995 95.3001 3.39995V13.2H93.1001V0.199951Z"
      fill="currentColor"
    />
    <path
      d="M108.9 6.79998C108.9 10.2 110.2 11.7 112.4 11.7C114.4 11.7 115.6 10.5 115.7 8.39997V8.19998H112.4V6.39998H117.9V13.2H116V11.4C115.4 12.6 114.1 13.5 112.1 13.5C108.6 13.5 106.4 11.1 106.4 6.89998C106.4 2.69998 108.9 0.0999756 112.5 0.0999756C115.5 0.0999756 117.4 1.49998 117.7 3.99998H115.3C115.1 2.69998 114 1.79998 112.5 1.79998C110.2 1.79998 108.9 3.59998 108.9 6.79998Z"
      fill="currentColor"
    />
  </svg>
);

export default MyBookingTitle;
