import React from "react";
import Cabin from "models/Cabin";
import Passenger from "models/Passenger";
import { Info, SeparatorLine } from "components/Grid";
import Facilities from "./Facilities";
import { useTrans } from "i18n";
import {
  Header3,
  Subtitle,
  TabularHeader,
  TabularText
} from "@hurtigruten/shared-components";

interface CabinElementProps {
  cabin: Cabin;
  passengers: Passenger[];
  voyageHeading?: string;
}

const CabinElement: React.FC<CabinElementProps> = ({
  cabin,
  passengers,
  voyageHeading
}) => {
  const { translate } = useTrans();
  const hasCabinGradeSize =
    cabin.cabinGrade &&
    cabin.cabinGrade.sizeFrom > 0 &&
    cabin.cabinGrade.sizeTo > 0;

  const passengerNames = cabin.passengers
    .map(passenger => {
      // TODO: Move this to backend
      let name = "";
      passengers.forEach(p => {
        if (p.passengerId === passenger.passengerId) {
          name = p.name;
        }
      });
      return name;
    })
    .join(", ");

  return (
    <>
      <Header3 as="h4" marginBottom={voyageHeading ? "one" : "two"}>
        {cabin.title}
      </Header3>
      {voyageHeading && <Subtitle marginBottom="two">{voyageHeading}</Subtitle>}
      <Info>
        {cabin.cabinGrade?.title && (
          <TabularText marginBottom="one">{cabin.cabinGrade.title}</TabularText>
        )}
        {cabin.cabinGrade?.code && (
          <TabularText marginBottom="one">{`${translate(
            x => x.cabins.cabinGrade
          )}: ${cabin.cabinGrade.code}`}</TabularText>
        )}
        <TabularText as="h4" marginBottom="one">
          {`${translate(x => x.labels.cabinNumber)}: ${cabin.cabinNumber ||
            translate(x => x.cabins.notYetAvailable)}`}
        </TabularText>
        <TabularText marginBottom="one">{passengerNames}</TabularText>
      </Info>
      {cabin.cabinGrade && hasCabinGradeSize && (
        <>
          <SeparatorLine />
          <Info>
            <TabularHeader marginBottom="one">
              {translate(x => x.labels.facilities)}
            </TabularHeader>
            <Facilities cabinGrade={cabin.cabinGrade} />
          </Info>
        </>
      )}
    </>
  );
};

export default CabinElement;
