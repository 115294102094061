import { post } from "api/utils";
import { ApiResponse, ResponseStatus } from "api/models";
import { Consents } from "components/Consent";
import { setCookie, eraseCookie } from "utilities/cookies";

export const postConsentValues = async (
  url: string,
  data: Consents
): Promise<ApiResponse<null>> => {
  eraseCookie("HRG_Consent_General");
  eraseCookie("HRG_Consent_Marketing");

  setCookie("HRG_Consent_General", data.general.toString());
  setCookie("HRG_Consent_Marketing", data.marketing.toString());

  //GTM handles consents. So we trigger an event after consents have been updated
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "cookieConsent",
      nonInteraction: true
    });
  }

  const consentResponse = await post(
    `${url}rest/consent`,
    JSON.stringify(data)
  );

  if (consentResponse.status === ResponseStatus.SUCCESS) {
    return {
      status: consentResponse.status,
      data: null
    };
  }

  return {
    status: ResponseStatus.ERROR,
    error: {
      errorCode: consentResponse.status,
      errorMessage: ""
    }
  };
};
