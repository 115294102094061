import React, { useContext } from "react";
import styled, { useTheme } from "styled-components/macro";

import HrgSymbol from "icons/iconComponents/header-hrg-symbol";
import {
  MyBookingTitle,
  ExpeditionLogo,
  CoastalLogo
} from "icons/iconComponents";
import { UrlContext } from "UrlLoader";
import { media } from "utilities/media";

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
`;

interface LogoLinkProps {
  hiddenFor?: "mobile" | "desktop";
}

const LogoLink = styled.a`
  display: ${(props: LogoLinkProps) =>
    props.hiddenFor === "desktop" ? "none" : "block"};

  ${media.maxWidth.low} {
    display: ${(props: LogoLinkProps) =>
      props.hiddenFor === "mobile" ? "none" : "block"};
  }
`;

const MyBookingLogoStyling = styled.div`
  align-items: center;
  margin: 0px 0 2px 10px;
`;

interface LogoProps {
  isLoggedIn: boolean;
}

const Logo: React.FC<LogoProps> = () => {
  const { frontPageUrl } = useContext(UrlContext);
  const { brand } = useTheme();
  return (
    <LogoContainer>
      <LogoLink
        href={frontPageUrl}
        hiddenFor="desktop"
        aria-label="Hurtigruten home page"
      >
        <HrgSymbol />
      </LogoLink>
      <LogoLink
        href={frontPageUrl}
        hiddenFor="mobile"
        aria-label="Hurtigruten home page"
      >
        {brand === "expedition" ? <ExpeditionLogo /> : <CoastalLogo />}
      </LogoLink>
      <MyBookingLogoStyling>
        <MyBookingTitle />
      </MyBookingLogoStyling>
    </LogoContainer>
  );
};

export default Logo;
