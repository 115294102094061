import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

export interface ContactUrls {
  contactUrl: string;
  faqUrl: string;
  frontPageUrl: string;
  excursionUrl: string;
  termsAndConditionsUrl: string;
  cookiesUrl: string;
  privacyUrl: string;
}

const getContactUrls = async (
  language: string
): Promise<ApiResponse<ContactUrls>> => {
  const response = await get(`/api/url/${language}`);

  return mapToApiResponse<ContactUrls>({
    response,
    propertyToCheck: "contactUrl",
    fallBackErrorMessage:
      "Unexpected response type: Type did not match ContactUrls"
  });
};

const useContactUrls = (
  language: string
): {
  data?: ContactUrls;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<ContactUrls, string>(getContactUrls, language);
};

export default useContactUrls;
