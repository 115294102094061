import { Translate } from "i18n";

const errorMessage = (translate: Translate, statusCode: number): string => {
  switch (statusCode) {
    case 401:
      return translate(x => x.login.errorMessage.unauthorized);
    case 404:
      return translate(x => x.login.errorMessage.unauthorized);
    default:
      return translate(x => x.login.errorMessage.default);
  }
};

export default errorMessage;
