import React, { useContext } from "react";
import styled from "styled-components/macro";
import { useTrans } from "i18n";
import {
  AlertBox,
  BodyText,
  Header3,
  PrimaryButton
} from "@hurtigruten/shared-components";
import HorizontalTile from "components/HorizontalTile";
import { BookingOverViewContext } from "../BookingLoader";
import { UrlContext } from "UrlLoader";
import { isAlongNorwegianCoast } from "utilities/voyage";
import hideOnPrint, { PrintProps } from "styling/print";
import { calculateDaysUntilDeparture } from "utilities/time";
import { spacings } from "styling/spacing";

interface ViewMoreExcursionsProps extends PrintProps {}

const AlertBoxWithMargin = styled(AlertBox)`
  margin: ${spacings.eight} ${spacings.two} 0;
`;

const Centered = styled.div<ViewMoreExcursionsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${hideOnPrint}
`;

const CenteredText = styled(BodyText)`
  text-align: center;
`;

const ViewMoreExcursions: React.FC<ViewMoreExcursionsProps> = ({
  hideOnPrint
}) => {
  const { translate } = useTrans();
  const { departureDate, bookMoreExcursionsLinks, voyages } = useContext(
    BookingOverViewContext
  );

  const { excursionUrl } = useContext(UrlContext);

  const daysUntilDeparture = calculateDaysUntilDeparture(departureDate);

  let bookMoreExcursionsLink =
    bookMoreExcursionsLinks &&
    bookMoreExcursionsLinks.length > 0 &&
    bookMoreExcursionsLinks[0];

  if (!bookMoreExcursionsLink && isAlongNorwegianCoast(voyages)) {
    bookMoreExcursionsLink = excursionUrl;
  }

  if (!bookMoreExcursionsLink || daysUntilDeparture < 1) {
    return null;
  }

  return (
    <HorizontalTile>
      <Centered hideOnPrint={hideOnPrint}>
        <Header3 marginBottom="two">
          {translate(x => x.excursions.customize)}
        </Header3>
        <CenteredText marginBottom="three">
          {translate(x => x.excursions.loveToHelp)}
        </CenteredText>
        <PrimaryButton
          as="a"
          href={bookMoreExcursionsLink}
          target="_blank"
          rel="noreferrer"
        >
          {translate(x => x.excursions.links.viewMore)}
        </PrimaryButton>
      </Centered>
      {daysUntilDeparture > 60 && (
        <AlertBoxWithMargin type="info">
          {translate(x => x.excursions.information.moreExcursions)}
        </AlertBoxWithMargin>
      )}
    </HorizontalTile>
  );
};

export default ViewMoreExcursions;
