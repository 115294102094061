import { Brand } from "@hurtigruten/shared-components";
import { Voyage } from "models/BookingOverviewData";
import { VoyageType } from "models/EpiVoyageContent";
import { getDateDifferenceInDays } from "./time";

export const getVoyageNames = (voyages: Voyage[]) => {
  const voyageNames = voyages.reduce((result: string[], voyage: Voyage) => {
    if (voyage.editorialContent?.heading) {
      result.push(voyage.editorialContent.heading);
    } else if (voyage.packageDescription) {
      result.push(voyage.packageDescription);
    }

    return result;
  }, []);

  return voyageNames.join(", ");
};

export const getVoyageId = (voyages: Voyage[]) => {
  const packageCodes = voyages.reduce((result: string[], voyage: Voyage) => {
    if (voyage.packageCode) {
      result.push(voyage.packageCode);
    }
    return result;
  }, []);

  if (packageCodes.length > 0) {
    return packageCodes.join(", ");
  }

  const ports: string[] = [];

  voyages.forEach(voyage => {
    if (voyage.fromPort && voyage.toPort) {
      ports.push(`${voyage.fromPort.code}-${voyage.toPort.code}`);
    }
  });
  return ports.join(", ");
};

const getEditorialVoyageType = (voyages: Voyage[]) => {
  const editorialVoyageTypes = voyages.reduce(
    (result: VoyageType[], voyage: Voyage) => {
      if (voyage.editorialContent?.type) {
        result.push(voyage.editorialContent.type);
      }
      return result;
    },
    []
  );
  // Tracking voyage type of first voyage leg
  return editorialVoyageTypes[0] || null;
};

const getEditorialVoyageBrand = (voyages: Voyage[]) => {
  const editorialVoyageBrands = voyages.reduce(
    (result: Brand[], voyage: Voyage) => {
      if (voyage.editorialContent?.brand) {
        result.push(voyage.editorialContent.brand);
      }
      return result;
    },
    []
  );

  if (
    editorialVoyageBrands.length > 1 &&
    !editorialVoyageBrands.every(brand => brand === editorialVoyageBrands[0])
  ) {
    return "group";
  }

  return editorialVoyageBrands[0] || null;
};

const getCoastalOrPortToPortVoyageType = (voyages: Voyage[]): VoyageType => {
  const durationInDays = voyages.map(voyage => {
    const duration = getDateDifferenceInDays(
      voyage.departureDateTime,
      voyage.arrivalDateTime
    );
    return duration;
  });

  let voyageType: VoyageType = "Port to port";
  durationInDays.forEach(duration => {
    // Coastal voyages defined as being longer than 5 cruise nights
    if (duration > 5) {
      voyageType = "Coastal";
    }
  });

  return voyageType;
};

export const isAlongNorwegianCoast = (voyages: Voyage[]): boolean => {
  // toPortName field is only set for voyages along norwegian coast
  return voyages.reduce((result: boolean, voyage: Voyage) => {
    if (voyage.toPort.address && voyage.fromPort.address) {
      result = true;
    }
    return result;
  }, false);
};

export const getVoyageType = (voyages: Voyage[]): VoyageType => {
  const voyageType = getEditorialVoyageType(voyages);

  if (voyageType) {
    return voyageType;
  }

  if (!isAlongNorwegianCoast(voyages)) {
    return "Expedition";
  }

  return getCoastalOrPortToPortVoyageType(voyages);
};

export const getVoyageBrand = (voyages: Voyage[]): Brand => {
  const brand = getEditorialVoyageBrand(voyages);

  if (brand) {
    return brand;
  }

  if (!isAlongNorwegianCoast(voyages)) {
    return "expedition";
  }

  return "coastal";
};
