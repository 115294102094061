import { Translate } from "i18n";

const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const localizedJoin = (
  texts: string[],
  translate: Translate,
  keepCasing = false
) => {
  if (texts.length === 0) {
    return "";
  }

  let text = texts[0];

  if (texts.length > 1) {
    const last = texts[texts.length - 1];
    text = `${texts.slice(0, texts.length - 1).join(", ")} ${translate(
      x => x.words.and
    )} ${last}`;
  }

  if (keepCasing) {
    return text;
  }

  return capitalizeFirstLetter(text.toLowerCase());
};
