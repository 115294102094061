import React, { useContext } from "react";
import LinkCard from "components/Card/LinkCard";
import { BookingOverViewContext } from "../BookingLoader";
import { useTrans } from "i18n";

const WelcomeOnBoard: React.FC = () => {
  const { voyages } = useContext(BookingOverViewContext);
  const { translate } = useTrans();

  const welcomeOnBoardList = voyages
    .map(voyage => voyage.welcomeOnBoard)
    .filter(
      welcomeOnBoard =>
        welcomeOnBoard.url && welcomeOnBoard.title && welcomeOnBoard.subTitle
    );

  if (
    welcomeOnBoardList.length < 1 ||
    !welcomeOnBoardList[0].title ||
    !welcomeOnBoardList[0].subTitle ||
    !welcomeOnBoardList[0].url
  ) {
    return null;
  }

  return (
    <LinkCard
      title={welcomeOnBoardList[0].title.replace(/_/g, " ")}
      text={welcomeOnBoardList[0].subTitle.replace(/_/g, " ")}
      url={welcomeOnBoardList[0].url}
      ctaText={translate(x => x.words.readMore)}
    />
  );
};

export default WelcomeOnBoard;
