import { ApiResponse, ResponseStatus, ErrorResponse } from "../models";
import { post, successfulObject } from "../utils";
import { LoginFormData } from "pages/login/LoginForm";
import errorMessage from "./errorMessage";
import { Translate } from "i18n";

export interface LoginSuccess {
  url: string;
}

export const postLogin = async (
  loginFormData: LoginFormData,
  translate: Translate
): Promise<ApiResponse<LoginSuccess>> => {
  const response = await post("/api/Auth/", JSON.stringify(loginFormData));

  if (
    successfulObject(response) &&
    Object.prototype.hasOwnProperty.call(response.data, "url")
  ) {
    return {
      status: response.status,
      data: response.data.url as LoginSuccess
    };
  }
  if (Object.prototype.hasOwnProperty.call(response, "error")) {
    const errorResponse = response as ErrorResponse;
    return {
      status: ResponseStatus.ERROR,
      error: {
        errorCode: errorResponse.error.errorCode,
        errorMessage: errorMessage(translate, errorResponse.error.errorCode)
      }
    };
  }
  return {
    status: ResponseStatus.ERROR,
    error: {
      errorCode: response.status,
      errorMessage: translate(x => x.login.errorMessage.default)
    }
  };
};
