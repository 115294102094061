import styled from "styled-components/macro";
import { media } from "utilities/media";
import { spacingBottom, spacings } from "styling/spacing";

export const Info = styled.div`
  flex: 1;
  padding-right: 15px;
  margin-bottom: ${spacings.four};

  & > *:last-child {
    margin-bottom: ${spacings.three};
  }
  ${media.maxWidth.medium} {
    margin-bottom: ${spacings.three};
  }
`;

export const InfoGroup = styled.div`
  ${spacingBottom}

  &:last-child {
    margin-bottom: 0;
  }
`;
