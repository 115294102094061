import React from "react";
import { Info } from "components/Grid";
import { useTrans } from "i18n";
import {
  Icon,
  TabularHeader,
  TabularText
} from "@hurtigruten/shared-components";
import styled from "styled-components/macro";
import { spacings } from "styling/spacing";

const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 5px;
  list-style: none;
`;

const ListElement = styled.li`
  display: flex;
  margin-bottom: ${spacings.one};
`;

interface BookedServicesProps {
  bookedServices: string[];
}

const BookedServices: React.FC<BookedServicesProps> = ({ bookedServices }) => {
  const { translate } = useTrans();

  if (!bookedServices || bookedServices.length < 1) {
    return null;
  }

  return (
    <Info>
      <TabularHeader marginBottom="two">
        {translate(x => x.labels.bookedServices)}
      </TabularHeader>
      <List>
        {bookedServices.map(text => (
          <ListElement key={text}>
            <Icon iconType="checkmark" />
            <TabularText marginLeft="one">{text}</TabularText>
          </ListElement>
        ))}
      </List>
    </Info>
  );
};

export default BookedServices;
