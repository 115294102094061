import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useTrans, isLanguageCode } from "i18n";

interface LanaguageHandlerProps extends RouteComponentProps {
  lang?: string;
}

const LanguageHandler: React.FC<LanaguageHandlerProps> = ({
  children,
  location,
  lang = "en"
}) => {
  const { langCode, setLanguage } = useTrans();

  const isNotCorrectLanguage = langCode !== lang;

  if (isNotCorrectLanguage) {
    if (isLanguageCode(lang)) {
      // Change language if the new language is supported
      setLanguage(lang);
    } else {
      // Redirect to english version of the current page
      const redirectPath = location?.pathname?.replace(lang, "en");

      if (redirectPath) {
        return <Redirect to={redirectPath} noThrow />;
      } else {
        return <Redirect to={"/en"} noThrow />;
      }
    }
  }

  return <>{children}</>;
};

export default LanguageHandler;
