import React, { useContext } from "react";
import { AlertBanner } from "@hurtigruten/shared-components";
import { BookingOverViewContext } from "./BookingLoader";

const IsFakeBookingAlert: React.FC = () => {
  const { isFakeBooking } = useContext(BookingOverViewContext);

  if (!isFakeBooking) {
    return null;
  }

  return (
    <AlertBanner
      type="info"
      onClose={() => {
        return;
      }}
    >
      This is a test booking
    </AlertBanner>
  );
};

export default IsFakeBookingAlert;
