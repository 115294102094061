import { useState, useEffect } from "react";
import {
  ResponseStatus,
  LoadedTypeResponse,
  ErrorResponse,
  Error,
  ApiResponse
} from "../models";

function useFetchData<T, U>(
  fetchDataFunction: (a: U) => Promise<ApiResponse<T>>,
  fetchDataArgument?: U,
  shouldFetchData = true
): {
  data?: T;
  isLoading: boolean;
  error?: Error;
} {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect((): (() => void) => {
    let didCancel = false;

    const fetchData = async (): Promise<void> => {
      if (!fetchDataArgument || !shouldFetchData) {
        return;
      }
      const response = await fetchDataFunction(fetchDataArgument);

      if (!didCancel) {
        if (response.status === ResponseStatus.SUCCESS) {
          const responseSuccess = response as LoadedTypeResponse<T>;
          setData(responseSuccess.data);
          setError(undefined);
        }

        if (response.status === ResponseStatus.ERROR) {
          const responseFailed = response as ErrorResponse;
          setError(responseFailed.error);
        }
        setIsLoading(false);
      }
    };

    fetchData();
    return (): void => {
      didCancel = true;
    };
  }, [fetchDataFunction, fetchDataArgument, shouldFetchData]);

  return { data, isLoading, error };
}

export default useFetchData;
