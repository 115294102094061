import React from "react";

const hurtigrutenLogoWithText = () => (
  <svg
    width="1"
    height="37"
    viewBox="0 0 1 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 1V36" stroke="currentColor" strokeLinecap="square" />
  </svg>
);

export default hurtigrutenLogoWithText;
