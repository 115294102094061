import React, { useRef, useState } from "react";
import {
  SecondaryButton,
  TextInput,
  LinkText,
  TabularText
} from "@hurtigruten/shared-components/";
import styled from "styled-components";
import { spacings } from "styling/spacing";
import { useTrans } from "i18n";
import Passenger from "models/Passenger";

interface GuestAccessLinkProps {
  passenger: Passenger;
}

const Container = styled.div`
  padding: ${spacings.four};
  margin-top: ${spacings.two};
  background: #e6e6e6;
  border-radius: 8px;
  max-width: 300px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const Input = styled(TextInput)`
  padding: 8px;
  font-size: 14px;
  margin-bottom: ${spacings.one};

  &:hover {
    cursor: text;
  }
`;

const GuestAccessLink: React.FC<GuestAccessLinkProps> = ({ passenger }) => {
  const { translate, langCode } = useTrans();
  const inputEl = useRef<HTMLInputElement>(null);

  const [showAccessLink, setShowAccessLink] = useState(false);

  const onCopyButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputEl.current != null) {
      inputEl.current.disabled = false;
      inputEl.current.select();
      document.execCommand("copy");
      inputEl.current.disabled = true;
    }
  };

  const url = window.location.protocol + "//" + window.location.host;
  const encryptedLink = url + "/" + langCode + "/" + passenger.encryptedLink;

  return (
    <>
      <LinkText
        as="button"
        onClick={() => {
          setShowAccessLink(!showAccessLink);
        }}
      >
        {!showAccessLink
          ? translate(x => x.accessLink.showLink)
          : "Hide access link"}
      </LinkText>
      {showAccessLink && (
        <Container>
          <Row>
            <TabularText marginBottom="two">
              {translate((x, format) =>
                format(x.accessLink.description, { name: passenger.name })
              )}
            </TabularText>
          </Row>
          <Input ref={inputEl} disabled={true} defaultValue={encryptedLink} />
          <SecondaryButton size="condensed" onClick={onCopyButtonClicked}>
            {translate(x => x.accessLink.copy)}
          </SecondaryButton>
        </Container>
      )}
    </>
  );
};

export default GuestAccessLink;
