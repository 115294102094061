import { LanguageResource } from "i18n/models/resources";

const german: LanguageResource = {
  cancelled: {
    title: "Buchung storniert",
    text: "Leider wurde Ihre Buchung am {{date}} storniert"
  },
  error: {
    notsupported: {
      title: "Es tut uns leid!",
      secondaryTitle: "Ihre Buchung scheint nicht verfügbar zu sein.",
      description:
        "Wenn Sie über Ihr Reisebüro gebucht haben, steht Ihnen My Booking leider noch nicht zur Verfügung.",
      helpText:
        "Wenn dies nicht der Fall ist, kontaktieren Sie uns bitte, um weitere Hilfe zu erhalten.",
      button: "Anmelden"
    },
    general: {
      title: "Es tut uns leid!",
      secondaryTitle: "Das hat nicht funktioniert.",
      description: "Das hat nicht funktioniert.",
      helpText:
        "Wenn dieser Fehler weiterhin auftritt, funktioniert My Booking möglicherweise nicht ordnungsgemäß. Bitte kontaktieren Sie uns, um weitere Hilfe zu erhalten.",
      button: "Anmelden"
    },
    bookingCancelled: {
      title: "Es tut uns leid!",
      secondaryTitle: "Diese Buchungsnummer wurde anscheinend geändert.",
      description:
        "Wenn Sie eine neue Buchungsnummer haben, verwenden Sie bitte diese, um sich anzumelden und Ihre aktualisierten Buchungsinformationen anzusehen.",
      helpText:
        "Wenn dies nicht der Fall ist, kontaktieren Sie uns bitte, um weitere Hilfe zu erhalten.",
      button: "Anmelden"
    }
  },
  login: {
    applicationName: "My Booking",
    description:
      "Sehen Sie sich Ihre Buchung an und bereiten Sie sich auf Ihr Abenteuer vor",
    formExplanation:
      "Verwenden Sie Ihre Buchungsdetails, um sich einen Überblick über Ihre Reise zu verschaffen.",
    button: {
      toMyBooking: "Zu My Booking",
      help: "Haben Sie Fragen?"
    },
    errorMessage: {
      unauthorized:
        "Entschuldigung, es sieht so aus, als ob die Buchungsnummer, die Buchungs-E-Mail-Adresse und/oder der vollständige Name falsch sind. Stellen Sie sicher, dass Sie die E-Mail-Adresse und den vollständigen Namen der Person eingeben, die diese Reise gebucht hat.",
      systemDown:
        "Entschuldigung, unsere Systeme scheinen ausgefallen zu sein. Bitte versuchen Sie es später noch einmal.",
      default:
        "Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an unser Expertenteam.",
      bookingRef: "Dette feltet skal kun inneholde tall."
    }
  },
  faq: {
    title: "Wie können wir Ihnen weiterhelfen?",
    description:
      "Im Folgenden finden Sie Antworten auf die Fragen, die uns am häufigsten gestellt werden.",
    backLink: "Zurück zur Anmeldung",
    questions: {
      about: "Was ist My Booking?",
      credentials:
        "Wo finde ich die zum Anmelden erforderlichen Informationen?",
      content: "Was kann ich in My Booking herausfinden?",
      cancelled: "Kann ich auf eine stornierte Buchung zugreifen?",
      agency:
        "Kann ich auf eine über ein Reisebüro getätigte Buchung zugreifen?",
      otherBookings: "Kann ich in My Booking meine anderen Buchungen einsehen?",
      cancel: "Ich möchte meine Reise stornieren. Was muss ich tun?",
      misspelled: "Mein Name ist falsch geschrieben. Was muss ich tun?"
    },
    answers: {
      about:
        "Wir sind bemüht, Ihnen den bestmöglichen Service zu bieten, von der Buchung bis zur Rückkehr von einer unserer Schiffsreisen. My Booking ist ein Webservice, über den Sie wichtige Informationen zu Ihrer Buchung herausfinden können.",
      credentials:
        "Verwenden Sie die Buchungsnummer (wie auf der Rechnung angegeben), die E-Mail-Adresse und den vollständigen Namen der Person, die gebucht hat (auch als Haupt-Reisender bezeichnet).",
      content:
        "Verschaffen Sie sich einen schnellen Überblick über alle Details zu Ihrer Reise. Informieren Sie sich über Ihre Abreise- und Ankunftsdaten sowie über gebuchte Ausflüge und Transportmittel während Ihrer Reise. Überprüfen Sie Angaben zu Gästen, Kabinen und Zahlungsinformationen. Erfahren Sie mehr über Ihre Reiseroute und lernen Sie das Schiff bereits vor Ihrem Abenteuer kennen.",
      cancelled:
        "Nein, auch wenn Sie die Anmeldeinformationen einer stornierten Buchung eingeben, können Sie nicht darauf zugreifen.",
      agency:
        "Nein, Buchungen über Reisebüros werden derzeit nicht unterstützt.",
      otherBookings:
        "Mit My Booking können Sie auf Informationen zu einer einzelnen Buchung zugreifen.",
      cancel:
        "Wenn Sie Ihre Buchung stornieren möchten, senden Sie uns bitte eine E-Mail und wir kümmern uns darum.",
      misspelled:
        "Wenn Ihr Name falsch geschrieben ist, setzen Sie sich bitte umgehend mit uns in Verbindung."
    }
  },
  contact: {
    contactUs: "Kontakt",
    stillGotQuestions: "Haben Sie noch Fragen?",
    pleaseContactUsPhrase:
      "Zögern Sie nicht, uns zu kontaktieren. Wir helfen Ihnen gerne weiter."
  },
  aboutVoyage: {
    title: "Sie benötigen Unterstützung bei der Buchung Ihrer Reise?",
    description: "Wir helfen Ihnen gerne weiter",
    mailto: "Senden Sie uns einfach eine E-Mail"
  },
  feedback: {
    title:
      "Möchten Sie uns bei der Optimierung des Bereichs My Booking unterstützen?",
    placeholder:
      "Vielen Dank für Ihre Mitwirkung bei der Optimierung des Bereichs My Booking! Was sollten wir Ihrer Ansicht nach ändern oder verbessern?",
    disclaimer:
      "Bitte beachten Sie, dass das Feedback nicht beantwortet wird. Wenn Sie Fragen oder Anfragen zu einer bestimmten Reise haben, wenden Sie sich bitte an den Kundenservice.",
    buttonText: "Feedback senden"
  },
  thankyou: {
    title: "Danke!",
    description:
      "Ihr Feedback wurde erfolgreich übermittelt. Wir werden Ihr Feedback bei zukünftigen Verbesserungen von My Booking berücksichtigen.",
    button: "Weiteres Feedback geben"
  },
  questionsTile: {
    title: "Haben Sie Fragen?",
    description:
      "Sehen Sie bitte zuerst in unserem Bereich mit häufig gestellten Fragen nach, bevor Sie sich mit uns in Verbindung setzen.",
    linkText: "Häufig gestellte Fragen ansehen"
  },
  bookingOverview: {
    links: {
      learnMoreAboutYourVoyage: "Erfahren Sie mehr über Ihre Reise",
      managePayment: "Verwalten Sie Ihre Zahlungen",
      manageQuotePayment: "Bezahlen und buchen"
    },
    detailedInformation:
      "Info: Ihre Tickets und genauere Informationen zu Ihrer Reiseroute sowie zu Ihren Flügen erhalten Sie 2–4 Wochen vor Reiseantritt per E-Mail."
  },
  paymentStatus: {
    title: "Übersicht",
    paymentStatus: "Zahlungsstatus",
    partlyPaid: "Teilweise bezahlt",
    fullyPaid: "Vollständig bezahlt",
    noPaymentReceived: "Keine Zahlung erhalten",
    due: "Fällig am",
    depositDue: "Anzahlung fällig am",
    pendingPayment: "Ausstehende Zahlung"
  },
  cabins: {
    header: "Meine Kabinen",
    notYetAvailable: "Noch nicht verfügbar",
    facilities: {
      window: {
        pothole: "Bullauge",
        window: "Fenster",
        windowLimitedView: "Fenster mit eingeschränkter Sicht",
        noInformation: ""
      },
      bed: {
        doubleBed: "Doppelbett",
        twinBed: "Einzelbett",
        oneBedOneCouch: "1 Bett/1 Schlafsofa",
        bunkBeds: "Etagenbett",
        beds: "Betten",
        mixed: "Gemischt",
        noInformation: ""
      },
      bathroom: "Bad",
      balcony: "Balkon",
      sofa: "Sofa",
      tv: "TV",
      dinnerTable: "Esstisch"
    },
    cabinGrade: "Kabinenkategorie"
  },
  excursions: {
    yourExcursions: "Meine Ausflüge",
    othersExcursions: "Excursions booked by others in your group",
    links: {
      moreInfo: "Weitere Informationen",
      viewMore: "Weitere Ausflüge anfordern"
    },
    customize: "Personalisieren Sie Ihre Reise!",
    loveToHelp:
      "Wir möchten Ihnen dabei helfen, Ihre Reise noch besser zu machen.",
    summary: "{{date}} für {{count}} Person",
    summary_plural: "{{date}} für {{count}} Personen",
    information: {
      noExcursions: "Sie haben noch keine Ausflüge im Voraus gebucht.",
      moreExcursions:
        "Einige Ausflüge im Zusammenhang mit dieser Reise können möglicherweise erst 6 bis 9 Monate vor Reiseantritt gebucht werden."
    }
  },
  logout: {
    logout: "Abmelden"
  },
  consents: {
    button: {
      accept: "Ich habe verstanden",
      cookieLinkText: "Mehr über Cookies lesen"
    }
  },
  meals: {
    bookedMeals: "Gebuchte Mahlzeiten"
  },
  words: {
    minute: "Minute",
    minute_plural: "Minuten",
    day: "Tag",
    day_plural: "Tage",
    cabin: "Kabine",
    cabin_plural: "Kabinen",
    excursion: "Ausflug",
    excursion_plural: "Ausflüge",
    hour: "Stunde",
    hour_plural: "Stunden",
    person: "Person",
    person_plural: "Personen",
    car: "Auto",
    car_plural: "Autos",
    motorcycle: "Motorrad",
    motorcycle_plural: "Motorräder",
    flight: "Flug",
    hotel: "Hotel",
    rail: "Bahn",
    transfer: "Transfer",
    breakfast: "Frühstück",
    lunch: "Mittagsessen",
    dinner: "Abendessen",
    and: "und",
    map: "Karte",
    yes: "Ja",
    no: "Nein",
    add: "Add",
    edit: "Edit",
    readMore: "Mehr lesen",
    arrival: "Anreise",
    departure: "Abreise",
    stop: "Stopp",
    stop_plural: "Stopps"
  },
  labels: {
    facilities: "Ausstattung",
    category: "Kategorie",
    guests: "Gäste",
    leadPassenger: "Hauptpassagier",
    membershipLevel: "Mitgliedschaftsstufe",
    date: "Datum",
    voyage: "Reise",
    ship: "Schiff",
    included: "Inklusive",
    bookedServices: "Leistungen",
    travelPeriod: "Reisezeitraum",
    sailingPeriod: "Reisezeit",
    duration: "Dauer",
    bookingReferenceNumber: "Buchungsnummer",
    quoteReferenceNumber: "Angebot",
    quoteValidUntil: "Dieses Angebot ist gültig bis",
    quoteDisclaimer:
      "Wenn bis zu diesem Zeitpunkt keine Zahlung erfolgt, wird Ihr Angebot automatisch storniert. Bitte lesen Sie die Buchungsdetails sorgfältig durch und kontaktieren Sie uns, wenn Sie Details ändern möchten. Wenn Sie Ihr Angebot in eine Buchung umwandeln möchten, muss eine Zahlung erfolgen.",
    bookingEmail: "E-Mail-Adresse der Buchung",
    fullName: "Vollständiger Name",
    cabinNumber: "Kabinennummer",
    vehicle: "Fahrzeug",
    vehicle_plural: "Fahrzeuge",
    fromTo: "Von — nach",
    passengerInfo: "Passagierangaben",
    travellingWith: "Reisen mit",
    exploring: "Erkunden",
    total: "Gesamtsumme",
    perPerson: "pro Person"
  },
  alternativeTexts: {
    excursionImage: "Bild zum Ausflug"
  },
  warning: {
    departureTime:
      "Bitte beachten Sie, dass Ihre Reise nach Mitternacht beginnt."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "Edit email",
    close: "Close",
    updateButtonText: "Update Email",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Show link",
    hideLink: "Hide access link",
    copy: "Copy",
    close: "close",
    description: "Med denne lenken kan {{name}} logge seg inn"
  },
  whosTraveling: {
    title: "Wer reist?"
  },
  upcomingVoyage: {
    voyageDateDeteails: `Die Reise beginnt am {{startDate}} und endet am {{endDate}}`
  },
  footer: {
    termsAndConditions: "Allgemeine Reisebedingungen",
    statementOfPrivacy: "Datenschutzrichtline",
    cookies: "Cookie-Richtlinie",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "Erfahren Sie mehr über unseren Platinum-Tarif",
    button: "Platinum anfordern",
    loading: "Loading",
    requestSuccess:
      "Ihre Anfrage wurde gesendet. Wir werden uns mit weiteren Informationen bei Ihnen melden.",
    requestError:
      "Anscheinend ist etwas schief gelaufen. Bitte versuchen Sie es erneut. Oder kontaktieren Sie uns telefonisch oder per E-Mail.",
    upgradeInitiated:
      "Vielen Dank für das Upgrade auf Platinum. Wir werden uns bald mit weiteren Einzelheiten bei Ihnen melden.",
    upgradeInProgress:
      "Wir bereiten gerade vor, Ihnen eine E-Mail zu Ihrem Platinum-Upgrade zu senden.",
    upgradeDeclined:
      "Wir haben Ihre Bestätigung nicht erhalten, daher konnten wir Sie nicht auf Platinum upgraden. Bitte kontaktieren Sie uns, wenn Sie sich für ein Upgrade entscheiden.",
    upgradeApproved:
      "Herzlichen Glückwunsch – Sie wurden erfolgreich auf Platinum hochgestuft!"
  }
};

export default german;
