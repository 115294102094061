import BookingOverviewData, {
  SailingPlanDetail
} from "models/BookingOverviewData";
import Voyage from "models/BookingOverviewData";
import Excursion from "models/Excursion";

export function createDateAsUTC(date: string) {
  if (!date) {
    return date;
  }
  return new Date(date.substring(0, 19) + ".000Z");
}

const mapBookingOverviewData = (responseJson: any): BookingOverviewData => {
  return {
    ...responseJson,
    departureDate: createDateAsUTC(responseJson.departureDate),
    endDate: createDateAsUTC(responseJson.endDate),
    dateCreated: createDateAsUTC(responseJson.dateCreated),
    dateCancelled: createDateAsUTC(responseJson.dateCancelled),
    isQuotation: responseJson.status === "Quotation",
    autoCancelAllowedOnDate: createDateAsUTC(
      responseJson.autoCancelAllowedOnDate
    ),
    voyages: responseJson.voyages.map(
      (voyage: any): Voyage => ({
        ...voyage,
        departureDateTime: createDateAsUTC(voyage.departureDateTime),
        arrivalDateTime: createDateAsUTC(voyage.arrivalDateTime),
        excursions: voyage.excursions.map(
          (excursion: any): Excursion => ({
            ...excursion,
            startDateTime: createDateAsUTC(excursion.startDateTime),
            endDateTime: createDateAsUTC(excursion.endDateTime)
          })
        ),
        includedExcursions: voyage.includedExcursions?.map(
          (excursion: any): Excursion => ({
            ...excursion,
            startDateTime: createDateAsUTC(excursion.startDateTime),
            endDateTime: createDateAsUTC(excursion.endDateTime)
          })
        ),
        sailingPlan: voyage?.sailingPlan?.map(
          (sailingPlanDetail: any): SailingPlanDetail => ({
            ...sailingPlanDetail,
            departureDateTime: createDateAsUTC(
              sailingPlanDetail.departureDateTime
            ),
            arrivalDateTime: createDateAsUTC(sailingPlanDetail.arrivalDateTime)
          })
        )
      })
    )
  };
};

export default mapBookingOverviewData;
