import React from "react";
import styled from "styled-components/macro";
import { Icon } from "icons/iconComponents/Icon";
import { spacings } from "styling/spacing";

interface FeatureProps {
  icon: string;
  conditionalValue: boolean | string;
}

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  margin-bottom: ${spacings.one};
`;

const FeatureItem: React.FC<FeatureProps> = ({
  icon,
  conditionalValue,
  children
}) => {
  return (
    <>
      {conditionalValue && (
        <FeatureWrapper>
          <Icon url={icon} size={"16px"} />
          {children}
        </FeatureWrapper>
      )}
    </>
  );
};

export default FeatureItem;
