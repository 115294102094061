import React, { useContext } from "react";
import styled from "styled-components/macro";
import { LinkText, TagText } from "@hurtigruten/shared-components";
import { useTrans, Translate } from "i18n";
import { Payment } from "models/Payment";
import WarningIcon from "icons/rawIcons/c-warning.svg";
import CheckIcon from "icons/rawIcons/c-check.svg";
import { Icon } from "icons/iconComponents/Icon";
import { BookingOverViewContext, PaymentInfoContext } from "../BookingLoader";
import { Margin } from "../../../styling/spacing";

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextWithIcon = styled.div<{ background: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.background};
  padding: 10px;
  border-radius: 8px;
`;

const paymentColor = (paymentInfo: Payment) => {
  if (paymentInfo.totalPaymentsReceived > 0) {
    return paymentInfo.balance > 0 ? "#FEE291" : "#C0EA91";
  } else {
    return "#ED8D8A";
  }
};

const paymentStatus = (translate: Translate, paymentInfo: Payment): string => {
  if (paymentInfo.totalPaymentsReceived > 0) {
    return paymentInfo.balance > 0
      ? translate(x => x.paymentStatus.partlyPaid)
      : translate(x => x.paymentStatus.fullyPaid);
  } else {
    return translate(x => x.paymentStatus.noPaymentReceived);
  }
};

const paymentIcon = (paymentInfo: Payment): string => {
  return paymentInfo.balance > 0 ? WarningIcon : CheckIcon;
};

const PaymentStatus: React.FC = () => {
  const { translate } = useTrans();
  const paymentInfo = useContext(PaymentInfoContext);
  const { isQuotation } = useContext(BookingOverViewContext);

  if (!paymentInfo) {
    return null;
  }

  const { payment, paymentUrl } = paymentInfo;

  return (
    <Margin as={SpaceBetween}>
      {isQuotation ? (
        <>
          <LinkText href={paymentUrl} target="_blank">
            {translate(x => x.bookingOverview.links.manageQuotePayment)}
          </LinkText>
          <TextWithIcon background={paymentColor(payment)}>
            <TagText as="h4" marginRight="one">
              {translate(x => x.paymentStatus.pendingPayment)}
            </TagText>
            <Icon url={paymentIcon(payment)} />
          </TextWithIcon>
        </>
      ) : (
        <>
          <LinkText href={paymentUrl} target="_blank">
            {translate(x => x.bookingOverview.links.managePayment)}
          </LinkText>
          <TextWithIcon background={paymentColor(payment)}>
            <TagText as="h4" marginRight="one">
              {paymentStatus(translate, payment)}
            </TagText>
            <Icon url={paymentIcon(payment)} />
          </TextWithIcon>
        </>
      )}
    </Margin>
  );
};

export default PaymentStatus;
