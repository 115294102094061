export interface Payment {
  balance: number; //Remaining amount of payment
  balanceString: string;
  totalPaymentsReceived: number;
  totalPaymentsReceivedString: string;
  totalRefunded: number;
  totalRefundedString: string;
  payLater: boolean;
  dueDate: Date;
}

interface Deposit {
  amount: number; // Remaining amount of deposit
  amountString: string;
  dueDate: Date;
}

export interface PaymentInfo {
  payment: Payment;
  deposit: Deposit;
  paymentUrl: string;
  totalPrice: number;
  totalPriceString: string;
}

const paymentDefaultValue: Payment = {
  balance: 0,
  balanceString: "",
  totalPaymentsReceived: 0,
  totalPaymentsReceivedString: "",
  totalRefunded: 0,
  totalRefundedString: "",
  payLater: false,
  dueDate: new Date()
};

const depositDefaultValue: Deposit = {
  amount: 0,
  amountString: "",
  dueDate: new Date()
};

export const paymentInfoDefaultValue: PaymentInfo = {
  deposit: depositDefaultValue,
  payment: paymentDefaultValue,
  paymentUrl: "",
  totalPrice: 0,
  totalPriceString: ""
};
