import React from "react";

import {
  LinkText,
  TabularHeader,
  Header3
} from "@hurtigruten/shared-components";
import styled from "styled-components/macro";
import { Info } from "components/Grid";
import { useTrans } from "i18n";
import { Voyage } from "models/BookingOverviewData";
import FromTo from "./FromTo";
import { getDuration } from "utilities/time";

const ItineraryLink = styled(LinkText)`
  display: flex;
  text-decoration: none;

  span:first-child {
    width: 110px;
    text-decoration: underline;
  }

  span:nth-child(2) {
    color: black;
    border-bottom: 1px solid transparent;

    &:hover {
      color: black;
      border-bottom: 1px solid black;
    }
  }
`;

interface LegElementProps {
  voyage: Voyage;
  heading?: string;
  url?: string;
}

const LegElement: React.FC<LegElementProps> = ({ voyage, heading, url }) => {
  const { translate } = useTrans();
  const {
    fromPort,
    toPort,
    shipName,
    shipUrl,
    departureDateTime,
    arrivalDateTime,
    sailingPlan,
    itinerary
  } = voyage;

  const durationString = getDuration({
    useHours: true,
    startDateTime: departureDateTime,
    endDateTime: arrivalDateTime,
    translate
  });

  return (
    <>
      {heading && (
        <Info>
          <Header3 marginBottom="two" as="h4">
            {heading}
          </Header3>
          {itinerary && itinerary.length > 0 && (
            <Info>
              {itinerary.map(itineraryDay => (
                <ItineraryLink
                  href={itineraryDay.url}
                  key={itineraryDay.url}
                  marginBottom="one"
                >
                  <span>{itineraryDay.dayNumberString}</span>
                  <span>{itineraryDay.location}</span>
                </ItineraryLink>
              ))}
            </Info>
          )}
          {url && (
            <LinkText href={url} target="_blank">
              {translate(x => x.bookingOverview.links.learnMoreAboutYourVoyage)}
            </LinkText>
          )}
        </Info>
      )}
      <FromTo
        fromPort={fromPort}
        toPort={toPort}
        departure={departureDateTime}
        arrival={arrivalDateTime}
        durationString={durationString}
        sailingPlan={sailingPlan}
      />
      <Info>
        <TabularHeader marginBottom="one">
          {translate(x => x.labels.ship)}
        </TabularHeader>
        <LinkText href={shipUrl} target="_blank">
          {shipName}
        </LinkText>
      </Info>
    </>
  );
};

export default LegElement;
