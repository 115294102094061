import React, { useContext } from "react";
import styled from "styled-components";
import { spacings } from "styling/spacing";
import { getVoyageNames } from "utilities/voyage";
import { BookingOverViewContext, PaymentInfoContext } from "../BookingLoader";
import { useTrans } from "i18n";
import { localizedJoin } from "utilities/string";
import { dateToString } from "utilities/time";
import { BodyText, Header2, Subtitle } from "@hurtigruten/shared-components";
import { addDays, addHours, differenceInDays } from "date-fns";

const Container = styled.div`
  padding: 0 ${spacings.two};
  margin-bottom: ${spacings.two};
  width: 100%;
`;

const QuoteDisclaimer = styled.div`
  padding: 25px 0;
`;

const UpcomingVoyage: React.FC = () => {
  const { translate, langCode } = useTrans();
  const paymentInfo = useContext(PaymentInfoContext);
  const {
    voyages,
    endDate,
    departureDate,
    depositDueDate,
    bookingId,
    isQuotation,
    autoCancelAllowedOnDate,
    isPlatinum,
    dateCreated
  } = useContext(BookingOverViewContext);

  const getQuoteExpirationDate = () => {
    const created = new Date(dateCreated);
    let expirationDate = null as Date | null;
    if (differenceInDays(created, departureDate) < 7) {
      expirationDate = addHours(created, 2);
    } else if (depositDueDate) {
      expirationDate = addDays(depositDueDate, 2);
    } else {
      expirationDate = addDays(created, 7);
    }
    return dateToString(expirationDate, langCode);
  };
  const quoteAutoCancelDate = autoCancelAllowedOnDate
    ? dateToString(autoCancelAllowedOnDate, langCode)
    : getQuoteExpirationDate();

  let voyageName = getVoyageNames(voyages);

  if (!voyageName) {
    const voyagesFromTo = voyages.map(voyage => {
      if (voyage.fromPort.name && voyage.toPort.name) {
        return voyage.fromPort.name + " - " + voyage.toPort.name;
      }
      return "";
    });

    voyageName = localizedJoin(voyagesFromTo, translate, true);
  }
  return (
    <Container>
      <Header2 marginBottom="two">{voyageName}</Header2>
      <Subtitle marginBottom="eight">
        {translate((x, format) =>
          format(x.upcomingVoyage.voyageDateDeteails, {
            startDate: dateToString(departureDate, langCode, false, true, true),
            endDate: dateToString(endDate, langCode, false, true, true)
          })
        )}
      </Subtitle>
      {isQuotation ? (
        <div>
          <BodyText>
            {translate(x => x.labels.quoteReferenceNumber) + ": " + bookingId}
          </BodyText>
          <QuoteDisclaimer>
            <BodyText>
              {translate(x => x.labels.quoteValidUntil) +
                ` ${quoteAutoCancelDate}. ` +
                translate(x => x.labels.quoteDisclaimer)}
            </BodyText>
          </QuoteDisclaimer>
        </div>
      ) : (
        <BodyText>
          {translate(x => x.labels.bookingReferenceNumber) + ": " + bookingId}
        </BodyText>
      )}
    </Container>
  );
};

export default UpcomingVoyage;
