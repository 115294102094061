import React from "react";

const Hamburger = () => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
    <rect y="0" width="19" height="2" fill="currentColor"></rect>
    <rect y="14" width="19" height="2" fill="currentColor"></rect>
    <rect y="7" width="19" height="2" fill="currentColor"></rect>
  </svg>
);

export default Hamburger;
