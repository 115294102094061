import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

export enum StatusTypes {
  NEW = "new",
  INPROGRESS = "in progress",
  ACCEPTED = "closed accepted",
  BLANK = "",
  CLOSED_CANCELLED = "closed cancelled",
  CLOSED_BY_GUEST = "closed declined by guest",
  CLOSED_BY_HRG = "closed declined by hurtigruten",
  CLOSED_NO_RESPONSE = "closed no response from guest"
}

export type TPlatinumStatus = {
  records?: TRecord[];
  errorCode: string;
  message: string;
  status: StatusTypes;
};

export type TRecord = {
  platinum_Fare_Status__c: string | null;
};

const mapPlatinumStatusData = (responseJson: any): TPlatinumStatus => {
  return {
    ...responseJson,
    status:
      responseJson?.records[0]?.platinum_Fare_Status__c ?? StatusTypes.BLANK
  };
};

const getPlatinumStatus = async (
  bookingId: string
): Promise<ApiResponse<TPlatinumStatus>> => {
  const response = await get(`/api/platinumStatus/${bookingId}`);

  return mapToApiResponse<TPlatinumStatus>({
    response,
    propertyToCheck: "records",
    fallBackErrorMessage: "Unexpected response type: Type did not match Banner",
    mapFunction: mapPlatinumStatusData
  });
};

const usePlatinumStatus = (
  bookingId: string,
  isPlatinum: boolean
): {
  data?: TPlatinumStatus;
  isLoading: boolean;
  error?: Error;
} => {
  if (isPlatinum) {
    // the backend Booking lookup can give the platinum status if platinum addons are present
    return {
      isLoading: false,
      data: {
        status: StatusTypes.ACCEPTED,
        message: "",
        errorCode: ""
      }
    };
  }
  return useFetchData<TPlatinumStatus, string>(getPlatinumStatus, bookingId);
};

export default usePlatinumStatus;
