import { useEffect, useContext } from "react";

import { BookingOverViewContext } from "./BookingLoader";
import { useTrans } from "i18n";
import { getLangCodeFromMarketAndCurrency } from "utilities/market";
import { navigate } from "@reach/router";

const useBookingMarketLanguage = () => {
  const { langCode, setLanguage } = useTrans();
  const { market, currency } = useContext(BookingOverViewContext);

  useEffect(() => {
    const bookingLanguageCode = getLangCodeFromMarketAndCurrency(
      market,
      currency
    );

    if (bookingLanguageCode && bookingLanguageCode !== langCode) {
      const redirectPath = window.location?.pathname?.replace(
        langCode,
        bookingLanguageCode
      );
      navigate(redirectPath, {
        replace: true
      });
    }
  }, [langCode, setLanguage, market, currency]);
};

export default useBookingMarketLanguage;
