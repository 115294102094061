import React, { useState } from "react";
import { useTrans } from "i18n";
import { LinkText } from "@hurtigruten/shared-components";

import useConsentTexts from "api/consent/useConsentTexts";
import useConsentValues from "api/consent/useConsentValues";

import {
  Overlay,
  Modal,
  Content,
  Row,
  PrimaryButtonWithMargin
} from "./styles";
import { postConsentValues } from "api/consent/postConsentValues";
import useConsentUrl from "api/consent/useConsentUrl";
import useLocalConsentValues from "api/consent/useLocalConsentValues";
import MoreInfo from "./MoreInfo";
import { Text } from "styling/text";

export interface Consents {
  general: boolean;
  marketing: boolean;
}

const Consent: React.FC = () => {
  const { translate, langCode } = useTrans();
  const [hideModal, setHideModal] = useState(false); // Used to hide modal after accepting consents
  const [showMore, setShowMore] = useState(false); // Show / hide detalied information
  const { data: urlState } = useConsentUrl();
  const { data: globalConsents } = useConsentValues(urlState?.url); // Check consents on book keeping domain
  const { data: texts } = useConsentTexts(
    langCode,
    Boolean(globalConsents && !globalConsents.general) // Fetch consent texts if general consent not accepted
  );
  useLocalConsentValues(globalConsents); // Update local consents

  // Next consent values
  const [consentValues, setConsentValues] = useState<Consents>({
    general: true,
    marketing: true
  });

  if (hideModal || !texts || !urlState?.url) {
    return null;
  }

  return (
    <Overlay>
      <Modal>
        <Content>
          {!showMore && <Text marginBottom="four">{texts.intro}</Text>}
          {showMore && (
            <MoreInfo
              texts={texts}
              consentValues={consentValues}
              setConsentValues={setConsentValues}
            />
          )}
          <Row>
            <PrimaryButtonWithMargin
              onClick={async () => {
                setHideModal(true);
                await postConsentValues(urlState.url, consentValues);
              }}
            >
              {translate(x => x.consents.button.accept)}
            </PrimaryButtonWithMargin>
            <LinkText as="button" onClick={() => setShowMore(!showMore)}>
              {translate(x => x.words.readMore)}
            </LinkText>
          </Row>
        </Content>
      </Modal>
    </Overlay>
  );
};

export default Consent;
