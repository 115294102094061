import styled, { css } from "styled-components/macro";

interface Spacing {
  none: string;
  half: string;
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
  six: string;
  seven: string;
  eight: string;
  nine: string;
  ten: string;
  eleven: string;
}
export const spacings: Spacing = {
  none: "0px",
  half: "3px",
  one: "6px",
  two: "12px",
  three: "18px",
  four: "24px",
  five: "30px",
  six: "36px",
  seven: "42px",
  eight: "48px",
  nine: "54px",
  ten: "60px",
  eleven: "66px"
};

export type AvailableSpacings = keyof Spacing;

export interface MarginProps {
  marginBottom?: AvailableSpacings;
  marginLeft?: AvailableSpacings;
  marginRight?: AvailableSpacings;
  marginTop?: AvailableSpacings;
}

export const spacingBottom = css`
  margin-bottom: ${({ marginBottom = "none" }: MarginProps) =>
    spacings[marginBottom]};
`;

export const spacingLeft = css`
  margin-left: ${({ marginLeft = "none" }: MarginProps) =>
    spacings[marginLeft]};
`;

export const spacingRight = css`
  margin-right: ${({ marginRight = "none" }: MarginProps) =>
    spacings[marginRight]};
`;

export const spacingTop = css`
  margin-top: ${({ marginTop = "none" }: MarginProps) => spacings[marginTop]};
`;

export const Margin = styled.div`
  ${spacingBottom}
  ${spacingLeft}
  ${spacingRight}
  ${spacingTop}
`;
