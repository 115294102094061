import React, { useContext } from "react";
import styled from "styled-components";
import {
  Header3,
  TabularHeader,
  TabularText
} from "@hurtigruten/shared-components";
import { Box, SeparatorLine } from "components/Grid";
import { Translate, useTrans } from "i18n";
import { CabinPassenger } from "models/Cabin";
import { ExcursionPassenger } from "models/Excursion";
import { Margin } from "../../../styling/spacing";
import { BookingOverViewContext } from "../BookingLoader";

const getNumberOfPassengersString = (
  translate: Translate,
  passengers: CabinPassenger[] | ExcursionPassenger[]
) => {
  if (passengers.length > 1) {
    return `${passengers.length} ${translate(x => x.words.person_plural)}`;
  }

  return `${passengers.length} ${translate(x => x.words.person)}`;
};

const InfoAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NoPaddingBox = styled(Box)`
  padding: 0;
`;

const VoyagePriceInformation: React.FC = () => {
  const { translate } = useTrans();
  const { voyages } = useContext(BookingOverViewContext);

  return (
    <>
      {voyages.map(voyage => {
        const {
          editorialContent,
          packageDescription,
          cabins,
          excursions,
          packageCode
        } = voyage;

        const heading = editorialContent?.heading || packageDescription;

        return (
          <React.Fragment key={packageCode}>
            <Header3 marginBottom="four">{heading}</Header3>
            <NoPaddingBox>
              {cabins.map(cabin => (
                <React.Fragment key={cabin.cabinNumber}>
                  <TabularText marginBottom="two">
                    {`${cabin.title}, ${getNumberOfPassengersString(
                      translate,
                      cabin.passengers
                    )}`}
                  </TabularText>
                  {cabin.passengers.map(passenger => (
                    <InfoAndPrice key={passenger.passengerId}>
                      <TabularText>{passenger.name}</TabularText>
                      <TabularText>{passenger.price}</TabularText>
                    </InfoAndPrice>
                  ))}
                  <Margin as={InfoAndPrice} marginTop="two">
                    <TabularHeader>
                      {translate(x => x.labels.total)}
                    </TabularHeader>
                    <TabularHeader>{cabin.totalPrice}</TabularHeader>
                  </Margin>
                  <SeparatorLine />
                </React.Fragment>
              ))}
              {excursions.map(excursion => (
                <React.Fragment key={excursion.url}>
                  <TabularText marginBottom="two">
                    {`${excursion.excursionName}, ${getNumberOfPassengersString(
                      translate,
                      excursion.passengers
                    )}`}
                  </TabularText>
                  {excursion.passengers.map(passenger => (
                    <InfoAndPrice key={passenger.passengerId}>
                      <TabularText>{passenger.name}</TabularText>
                      <TabularText>{passenger.price}</TabularText>
                    </InfoAndPrice>
                  ))}
                  <Margin as={InfoAndPrice} marginTop="two">
                    <TabularHeader>
                      {translate(x => x.labels.total)}
                    </TabularHeader>
                    <TabularHeader>{excursion.totalPrice}</TabularHeader>
                  </Margin>
                  <SeparatorLine />
                </React.Fragment>
              ))}
            </NoPaddingBox>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default VoyagePriceInformation;
