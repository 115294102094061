export const screenSize = {
  width: {
    low: 475,
    medium: 680
  }
};

const maxWidth = (width: number) => `@media screen and (max-width: ${width}px)`;
const minWidth = (width: number) =>
  `@media screen and (min-width: ${width + 1}px)`;

export const media = {
  maxWidth: {
    low: maxWidth(screenSize.width.low),
    medium: maxWidth(screenSize.width.medium)
  },
  minWidth: {
    low: minWidth(screenSize.width.low),
    medium: minWidth(screenSize.width.medium)
  }
};
