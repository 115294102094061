import { ErrorResponse, SimpleResponse, ResponseStatus } from "../models";

interface LoadedObjectResponse extends SimpleResponse {
  data: Record<string, unknown>;
}

interface LoadedStringResponse extends SimpleResponse {
  data: string;
}

type LoadedResponse = LoadedObjectResponse | LoadedStringResponse;

export type RestResponse = LoadedResponse | ErrorResponse;

async function checkForErrorAndHandleResponse(
  response: Response
): Promise<RestResponse> {
  const text = await response.text();
  let json;
  try {
    json = await JSON.parse(text);
  } catch (e) {
    json = { errorMessage: e };
  }
  if (response.status === 200 || response.status === 204) {
    return {
      status: ResponseStatus.SUCCESS,
      data: json || text
    };
  }

  return {
    status: ResponseStatus.ERROR,
    error: {
      errorCode: response.status,
      errorMessage: json ? json.errorMessage : "Noe gikk galt"
    }
  };
}

export const get = async (url: string): Promise<RestResponse> => {
  const response = await fetch(url, { credentials: "include" });
  return checkForErrorAndHandleResponse(response);
};

export const post = async (
  url: string,
  dataObject: string
): Promise<RestResponse> => {
  const response = await fetch(url, {
    method: "POST",
    body: dataObject,
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
  return checkForErrorAndHandleResponse(response);
};

export const successfulObject = (
  response: RestResponse
): response is LoadedObjectResponse => {
  if (
    response.status === ResponseStatus.SUCCESS &&
    Object.prototype.hasOwnProperty.call(response, "data")
  ) {
    const successResponse = response as LoadedResponse;
    return typeof successResponse.data === "object";
  }
  return false;
};
