import React from "react";
import { Redirect } from "@reach/router";
import { useTrans } from "i18n";
import {
  Brand,
  BrandProvider,
  CircleLoader
} from "@hurtigruten/shared-components";

import useBookingOverview from "api/bookingoverview/useBookingOverview";
import usePayment from "api/payment/usePayment";
import { paths } from "App";
import useTrackLogin from "hooks/useTrackLogin";
import BookingOverviewData, {
  bookingOverviewDataDefaultValue
} from "models/BookingOverviewData";
import { paymentInfoDefaultValue, PaymentInfo } from "models/Payment";

import HeaderBar from "components/HeaderBar";
import { FullScreenCenteredContent } from "components/FullScreen/FullScreen";
import { getVoyageBrand } from "utilities/voyage";

interface Props {
  encryptedId?: string;
}

export const BookingOverViewContext = React.createContext<BookingOverviewData>(
  bookingOverviewDataDefaultValue
);
export const PaymentInfoContext = React.createContext<PaymentInfo | undefined>(
  paymentInfoDefaultValue
);

// FC
const BookingLoader: React.FC<Props> = ({ encryptedId = "", children }) => {
  const bookingOverviewState = useBookingOverview(encryptedId);
  const paymentState = usePayment(encryptedId);
  const { langCode } = useTrans();

  useTrackLogin({
    market: bookingOverviewState.data?.market,
    currency: bookingOverviewState.data?.currency,
    voyages: bookingOverviewState.data?.voyages,
    isFakeBooking: bookingOverviewState.data?.isFakeBooking,
    passengerAmount: bookingOverviewState.data?.passengerAmount,
    bookingDate: bookingOverviewState.data?.dateCreated,
    departureDate: bookingOverviewState.data?.departureDate
  });

  const error = bookingOverviewState.error;

  if (error && error.errorCode === 403) {
    return <Redirect to={`/${langCode}${paths.error.notsupported}`} noThrow />;
  } else if (error && error.errorCode === 410) {
    return <Redirect to={`/${langCode}${paths.error.expired}`} noThrow />;
  } else if (error?.errorMessage) {
    return <Redirect to={`/${langCode}${paths.error.default}`} noThrow />;
  }

  if (bookingOverviewState.isLoading || !bookingOverviewState.data) {
    return (
      <React.Fragment>
        <HeaderBar isLoggedIn={false} />
        <FullScreenCenteredContent as="main">
          <CircleLoader />
        </FullScreenCenteredContent>
      </React.Fragment>
    );
  }

  const voyageBrand: Brand = getVoyageBrand(bookingOverviewState.data.voyages);

  return (
    <BookingOverViewContext.Provider value={bookingOverviewState.data}>
      <PaymentInfoContext.Provider value={paymentState.data}>
        <BrandProvider fixedBrand={voyageBrand}>{children}</BrandProvider>
      </PaymentInfoContext.Provider>
    </BookingOverViewContext.Provider>
  );
};

export default BookingLoader;
