export default interface Passenger {
  passengerId: string;
  name: string;
  customerId: string;
  email: string;
  isLead: boolean;
  hasBerth: boolean;
  encryptedLink: string;
  ambassadorLevel: number | null;
}

export const passengerDefaultValue: Passenger = {
  passengerId: "",
  name: "",
  customerId: "",
  email: "",
  isLead: false,
  hasBerth: false,
  encryptedLink: "",
  ambassadorLevel: null
};
