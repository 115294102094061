import React, { useContext } from "react";
import styled from "styled-components";
import { PaymentInfoContext, BookingOverViewContext } from "../BookingLoader";
import { ExpandableBoxContentContainer, FullWidth } from "components/Grid";
import PaymentItem from "./PaymentItem";
import VoyagePriceInformation from "./VoyagePriceInformation";
import PaymentStatus from "./PaymentStatus";
import { getDateDifferenceInDays } from "utilities/time";
import { ExpandableBox } from "@hurtigruten/shared-components";
import { Translate, useTrans } from "i18n";
import { Payment } from "models/Payment";
import { isActive } from "utilities/featureToggles";

const paymentStatusTag = (translate: Translate, paymentInfo: Payment) => {
  if (paymentInfo.totalPaymentsReceived > 0) {
    return paymentInfo.balance > 0
      ? {
          content: translate(x => x.paymentStatus.partlyPaid),
          background: "#FEE291"
        }
      : {
          content: translate(x => x.paymentStatus.fullyPaid),
          background: "#C0EA91"
        };
  } else {
    return {
      content: translate(x => x.paymentStatus.noPaymentReceived),
      background: "#ED8D8A"
    };
  }
};

const WhiteBox = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 8px;
`;

const KeyInformation: React.FC = () => {
  const paymentInfo = useContext(PaymentInfoContext);
  const { dateCreated } = useContext(BookingOverViewContext);
  const { translate, langCode } = useTrans();

  if (!paymentInfo) {
    return null;
  }

  if (
    paymentInfo.payment.totalPaymentsReceived === 0 &&
    getDateDifferenceInDays(dateCreated, new Date()) === 0
  ) {
    return null;
  }

  const tags = [paymentStatusTag(translate, paymentInfo.payment)];

  //  Hide payment information until we get the chance to get all price info from PG endpoint.
  //  The current implementation may result in a case where the total price is lower than the sum
  //  of the price for all parts of the travel. This is probabably because of offers. We should investigate that before showing this.
  if (isActive("showPaymentInfo", langCode)) {
    return (
      <FullWidth>
        <ExpandableBox
          id={"keyInformation"}
          heading={translate(x => x.paymentStatus.title)}
          summaryTags={tags}
          defaultExpanded={false}
        >
          <ExpandableBoxContentContainer>
            <VoyagePriceInformation />
            <PaymentItem />
            <PaymentStatus />
          </ExpandableBoxContentContainer>
        </ExpandableBox>
      </FullWidth>
    );
  }

  return (
    <WhiteBox as={FullWidth}>
      <PaymentStatus />
    </WhiteBox>
  );
};

export default KeyInformation;
