import { Market, Currency } from "models/BookingOverviewData";
import { LanguageCode } from "i18n";

export const getMarket = (market: Market, currency: Currency): string => {
  if (market === "NO") {
    switch (currency) {
      case "SEK":
        return "se";
      case "DKK":
        return "dk";
      default:
        return "no";
    }
  }

  if (market === "DE") {
    return "de";
  }

  if (market === "FR") {
    return "fr";
  }

  if (market === "UK") {
    switch (currency) {
      case "GBP":
        return "uk";
      case "AUD":
        return "au";
      case "CHF":
        return "ch";
      default:
        return "global";
    }
  }

  if (market === "US") {
    return "us";
  }

  return "";
};

export const getLangCodeFromMarketAndCurrency = (
  market?: Market,
  currency?: Currency
): LanguageCode | undefined => {
  if (!market || !currency) {
    return undefined;
  }

  if (market === "NO") {
    switch (currency) {
      case "SEK":
        return "sv";
      case "DKK":
        return "da";
      default:
        return "no";
    }
  }

  if (market === "DE") {
    return "de";
  }

  if (market === "FR") {
    return "fr";
  }

  if (market === "UK") {
    switch (currency) {
      case "GBP":
        return "gb";
      case "AUD":
        return "au";
      case "CHF":
        return "ch";
      default:
        return "en";
    }
  }

  if (market === "US") {
    return "us";
  }

  return "en";
};
