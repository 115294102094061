import React from "react";
import styled, { useTheme } from "styled-components/macro";

import Logo from "./Logo";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

import Hamburger from "icons/iconComponents/hamburger";
import Cross from "icons/iconComponents/cross";
import VerticalLine from "icons/iconComponents/vertical-line";
import { media } from "utilities/media";

const HeaderWrapper = styled.header<{ dark?: boolean }>`
  background-color: ${props => (props.dark ? "#000" : "#fff")};
  color: ${props => (props.dark ? "#fff" : "#000")};

  & > * {
    color: ${props => (props.dark ? "#fff" : "#000")};
  }
`;

const HeaderContent = styled.div`
  padding: 12px 20px;
  margin: auto;
  max-width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Atlas Grotesk;
  height: 74px;
`;

const VerticalLineContainer = styled.div`
  margin: 0px 25px;
  ${media.maxWidth.low} {
    display: none;
  }
`;

const MobileMenuButton = styled.button<{ white: boolean }>`
  outline: none;
  border: none;
  background-color: transparent;
  display: none;
  ${media.maxWidth.low} {
    display: block;
  }

  rect {
    fill: ${props => (props.white ? "#fff" : "#000")};
  }

  path {
    stroke: ${props => (props.white ? "#fff" : "#000")};
  }
`;

export const GreyHorizontalLine = styled.hr`
  background-color: #e5e3e3;
  margin: 0;
  height: 1px;
  border: none;
`;

interface HeaderProps {
  isLoggedIn: boolean;
}

const HeaderBar: React.FC<HeaderProps> = ({ isLoggedIn }) => {
  const [isOpen, toggleIsOpen] = React.useState(false);
  const { brand } = useTheme();
  return (
    <>
      <HeaderWrapper dark={brand === "expedition"}>
        <HeaderContent>
          <Logo isLoggedIn={isLoggedIn} />
          {isLoggedIn && (
            <>
              <VerticalLineContainer>
                <VerticalLine />
              </VerticalLineContainer>
              <DesktopMenu />
              <MobileMenuButton
                aria-label={isOpen ? "Close menu" : "Open menu"}
                onClick={() => toggleIsOpen(!isOpen)}
                white={brand === "expedition"}
              >
                {!isOpen && <Hamburger />}
                {isOpen && <Cross />}
              </MobileMenuButton>
            </>
          )}
        </HeaderContent>
      </HeaderWrapper>
      {isOpen && isLoggedIn && <MobileMenu />}
    </>
  );
};

export default HeaderBar;
