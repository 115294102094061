import { LanguageResource } from "i18n/models/resources";

const french: LanguageResource = {
  cancelled: {
    title: "Réservation annulée",
    text: "Malheureusement, votre réservation a été annulée le {{date}}"
  },
  error: {
    notsupported: {
      title: "Désolé !",
      secondaryTitle:
        "Il semblerait que votre réservation ne soit pas disponible.",
      description:
        "Si vous avez réservé votre voyage auprès d’un agent de voyages, Ma Réservation n’est malheureusement pas encore disponible pour vous.",
      helpText:
        "Si ce n’est pas le cas, veuillez nous contacter pour obtenir de l’aide.",
      button: "S’identifier"
    },
    general: {
      title: "Désolé !",
      secondaryTitle: "Cela n’a pas fonctionné.",
      description:
        "Il se peut qu’il y ait un problème avec le lien que vous utilisez. Veuillez vous identifier à nouveau.",
      helpText:
        "Si vous continuez d’avoir cette erreur, il se peut que Ma Réservation ne fonctionne pas correctement. Veuillez nous contacter pour obtenir de l’aide.",
      button: "S’identifier"
    },
    bookingCancelled: {
      title: "Désolé !",
      secondaryTitle:
        "Il semblerait que ce numéro de réservation ait été modifié.",
      description:
        "Si vous avez un nouveau numéro de réservation, utilisez-le pour vous identifier et voir vos informations de réservation actualisées.",
      helpText:
        "Si ce n’est pas le cas, veuillez nous contacter pour obtenir de l’aide.",
      button: "S’identifier"
    }
  },
  login: {
    applicationName: "Ma Réservation",
    description:
      "Consultez votre réservation et préparez-vous pour votre aventure",
    formExplanation:
      "Utilisez vos détails de réservation pour avoir un aperçu de votre voyage.",
    button: {
      toMyBooking: "Aller à Ma Réservation",
      help: "Avez-vous des questions?"
    },
    errorMessage: {
      unauthorized:
        "Désolé, le numéro de réservation, l’e-mail de réservation et/ou le prénom et le nom sont incorrects. Assurez-vous que l’e-mail et le prénom et le nom sont bien ceux de la personne qui a réservé le voyage.",
      systemDown:
        "Désolé, nos systèmes semblent être en panne. Veuillez réessayer ultérieurement.",
      default:
        "Une erreur est survenue.  Veuillez réessayer ultérieurement ou contacter le service client.",
      bookingRef: "Dette feltet skal kun inneholde tall."
    }
  },
  faq: {
    title: "En quoi pouvons-nous vous aider ?",
    description:
      "Vous trouverez la réponse aux questions les plus fréquentes ci-dessous.",
    backLink: "Retour à l’écran de connexion",
    questions: {
      about: "Qu’est-ce que l’outil Ma Réservation ?",
      credentials: "Où trouver les informations pour me connecter ?",
      content: "Quelles informations contient l’outil Ma Réservation ?",
      cancelled: "Ai-je accès aux réservations annulées ?",
      agency: "Ai-je accès aux réservations annulées ?",
      otherBookings:
        "Ai-je accès à mes autres réservations dans l’espace Ma Réservation ?",
      cancel: "Je souhaiterais annuler mon voyage, comment faire ?",
      misspelled: "Mon nom contient des erreurs, que dois-je faire ?"
    },
    answers: {
      about:
        "Nous nous engageons à vous proposer le meilleur service depuis votre réservation jusqu’à votre retour de l’une de nos croisières. Ma Réservation est un service en ligne sur lequel vous trouverez des informations importantes concernant votre réservation.",
      credentials:
        "Indiquez le numéro de référence de réservation (se trouvant sur la facture), l’adresse e-mail ainsi que le prénom et le nom de la personne ayant effectué la réservation (que l’on désigne également comme passager principal).",
      content:
        "Consultez le récapitulatif de votre voyage. Vous y retrouverez vos dates de départ et d’arrivée, les excursions réservées et les transferts de votre croisière. Vérifiez les informations concernant les passagers, la cabine et le paiement. Découvrez-en plus sur votre itinéraire et sur le navire avant votre départ.",
      cancelled:
        "Non, vous n’avez pas accès aux réservations annulées si vous en saisissez les détails.",
      agency:
        "Non, les réservations effectuées auprès d’agences de voyages ne sont pas accessibles pour l’instant.",
      otherBookings:
        "Ma Réservation est une solution permettant d’accéder aux informations d’une réservation unique.",
      cancel:
        "Si vous souhaitez annuler votre réservation, merci de nous envoyer un e-mail afin que nous puissions traiter votre demande.",
      misspelled:
        "Si votre nom contient des erreurs, merci de nous contacter sans attendre."
    }
  },
  contact: {
    contactUs: "Nous contacter",
    stillGotQuestions: "Vous avez encore des questions ?",
    pleaseContactUsPhrase:
      "N’hésitez pas à nous contacter, nous nous ferons un plaisir de vous aider."
  },
  aboutVoyage: {
    title: "Besoin d’assistance pour votre voyage?",
    description: "Nous serons ravis de vous aider",
    mailto: "Envoyez-nous un e-mail "
  },
  feedback: {
    title: "Voulez-vous que nous améliorions Ma Réservation?",
    placeholder:
      "Merci de nous aider à perfectionner Ma Réservation ! Quels changements ou améliorations seraient souhaitables à votre avis ?",
    disclaimer:
      "Veuillez noter qu'il ne sera pas répondu aux commentaires. Si vous avez des questions ou des demandes de renseignements concernant un voyage spécifique, veuillez contacter le service client.",
    buttonText: "Envoyer un commentaire"
  },
  thankyou: {
    title: "Merci !",
    description:
      "Votre commentaire a bien été envoyé. Nous en tiendrons compte pour améliorer l’outil Ma Réservation.",
    button: "Ajouter des commentaires"
  },
  questionsTile: {
    title: "Vous avez des questions?",
    description:
      "Avant de nous contacter, consultez notre foire aux questions (FAQ).",
    linkText: "Consulter la FAQ"
  },
  bookingOverview: {
    links: {
      learnMoreAboutYourVoyage: "En savoir plus sur votre voyage",
      managePayment: "Gérer vos paiements",
      manageQuotePayment: "Payer et réserver"
    },
    detailedInformation:
      "Info : Vos billets et les informations spécifiques relatives à votre itinéraire et vos vols vous seront envoyés par e-mail deux à quatre semaines avant le début de votre voyage."
  },
  paymentStatus: {
    title: "Récapitulatif et paiement",
    paymentStatus: "Statut de paiement",
    partlyPaid: "Partiellement payé",
    fullyPaid: "Entièrement payé",
    noPaymentReceived: "Aucun paiement reçu",
    due: "Dû",
    depositDue: "Acompte payable le",
    pendingPayment: "Paiement en attente"
  },
  cabins: {
    header: "Mes cabines",
    notYetAvailable: "Pas encore disponible",
    facilities: {
      window: {
        pothole: "Hublot",
        window: "Fenêtre",
        windowLimitedView: "Fenêtre avec vue partielle",
        noInformation: ""
      },
      bed: {
        doubleBed: "Lit double",
        twinBed: "Lits jumeaux",
        oneBedOneCouch: "1 lit/1 canapé",
        bunkBeds: "Lits superposés",
        beds: "Lits",
        mixed: "Mélangés",
        noInformation: ""
      },
      bathroom: "Salle de bains",
      balcony: "Balcon",
      sofa: "Canapé",
      tv: "Télévision",
      dinnerTable: "Table à manger"
    },
    cabinGrade: "Catégorie de cabine"
  },
  excursions: {
    yourExcursions: "Mes excursions",
    othersExcursions: "Excursions booked by others in your group",
    links: {
      moreInfo: "Plus d’infos",
      viewMore: "Demander plus d’excursions"
    },
    customize: "Personnalisez votre aventure!",
    loveToHelp:
      "Nous aimerions vous aider à tirer le meilleur de votre voyage.",
    summary: "Le {{date}} pour {{count}} personne",
    summary_plural: "Le {{date}} pour {{count}} personnes",
    information: {
      noExcursions: "Vous n’avez pré-réservé aucune excursion pour l’instant.",
      moreExcursions:
        "Certaines excursions en lien avec ce voyage ne seront peut-être ouvertes à la réservation que 6 à 9 mois avant le début du voyage."
    }
  },
  logout: {
    logout: "Se déconnecter"
  },
  consents: {
    button: {
      accept: "J’accepte",
      cookieLinkText: "Plus d'information sur les cookies"
    }
  },
  meals: {
    bookedMeals: "Repas réservés"
  },
  words: {
    minute: "minute",
    minute_plural: "minutes",
    day: "jour",
    day_plural: "jours",
    cabin: "cabine",
    cabin_plural: "cabines",
    excursion: "excursion",
    excursion_plural: "excursions",
    hour: "heure",
    hour_plural: "heures",
    person: "personne",
    person_plural: "personnes",
    car: "voiture",
    car_plural: "voitures",
    motorcycle: "motocyclette",
    motorcycle_plural: "motocyclettes",
    flight: "Vol",
    hotel: "Hôtel",
    rail: "Train",
    transfer: "Transfert",
    breakfast: "petit déjeuner",
    lunch: "déjeuner",
    dinner: "dîner",
    and: "et",
    map: "Carte",
    yes: "Oui",
    no: "Non",
    add: "Add",
    edit: "Edit",
    readMore: "En savoir plus",
    arrival: "Arrivée",
    departure: "Départ",
    stop: "escale",
    stop_plural: "escales"
  },
  labels: {
    facilities: "Equipements",
    category: "Catégorie",
    guests: "Passagers",
    leadPassenger: "Passager principal",
    membershipLevel: "Niveau d'adhésion",
    date: "Date",
    voyage: "Voyage",
    ship: "Navire",
    included: "Inclus",
    bookedServices: "Services compris",
    travelPeriod: "Période de voyage",
    sailingPeriod: "Période de navigation",
    duration: "Durée",
    bookingReferenceNumber: "Numéro de réservation",
    quoteReferenceNumber: "Devis",
    quoteValidUntil: "Ce devis est valable jusqu'au",
    quoteDisclaimer:
      "Si aucun paiement n'est effectué avant cette date, votre devis sera automatiquement annulé. Veuillez lire attentivement les détails de la réservation et nous contacter si vous souhaitez modifier l'un des détails. Si vous souhaitez convertir votre devis en réservation, un paiement doit être effectué.",
    bookingEmail: "E-mail de réservation",
    fullName: "Prénom & Nom",
    cabinNumber: "Numéro de cabine",
    vehicle: "Véhicule",
    vehicle_plural: "Véhicules",
    fromTo: "Départ — arrivée",
    passengerInfo: "Informations sur les passagers",
    travellingWith: "Voyager avec",
    exploring: "Explorer",
    total: "Total",
    perPerson: "Par personne"
  },
  alternativeTexts: {
    excursionImage: "Photo de l’excursion"
  },
  warning: {
    departureTime:
      "Veuillez noter que le départ de votre voyage s’effectue après minuit."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "Edit email",
    close: "Close",
    updateButtonText: "Update Email",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Show link",
    hideLink: "Hide access link",
    copy: "Copy",
    close: "close",
    description: "Med denne lenken kan {{name}} logge seg inn"
  },
  whosTraveling: {
    title: "Qui voyage?"
  },
  upcomingVoyage: {
    voyageDateDeteails:
      "Le voyage commence le {{startDate}} et se termine le {{endDate}}"
  },
  footer: {
    termsAndConditions: "Conditions de vente",
    statementOfPrivacy: "Politique de confidentialité",
    cookies: "Cookie-Richtlinie",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "En savoir plus sur la gamme Platinum",
    button: "Demander Platinum",
    loading: "Loading",
    requestSuccess:
      "Votre demande a été envoyée. Nous vous contacterons pour vous donner plus de détails.",
    requestError:
      "On dirait que quelque chose s'est mal passé. Veuillez réessayer. Ou contactez-nous par téléphone ou par e-mail.",
    upgradeInitiated:
      "Merci d'avoir effectué la mise à niveau vers Platinum. Nous vous communiquerons bientôt plus de détails.",
    upgradeInProgress:
      "Nous nous préparons à vous envoyer un e-mail concernant votre mise à niveau Platinum.",
    upgradeDeclined:
      "Nous n'avons pas reçu votre confirmation, nous n'avons donc pas pu vous mettre à niveau vers Platinum. Veuillez nous contacter si vous décidez de mettre à niveau.",
    upgradeApproved:
      "Félicitations ! Vous avez été surclassé avec succès au niveau Platine !"
  }
};

export default french;
