import { LanguageResource } from "i18n/models/resources";

const danish: LanguageResource = {
  cancelled: {
    title: "Booking cancelled",
    text: "Unfortunately, your booking was cancelled {{date}}"
  },
  error: {
    notsupported: {
      title: "Beklager!",
      secondaryTitle: "Det ser ud til, at din booking ikke er tilgængelig.",
      description:
        "Hvis du har bestilt din rejse gennem et rejsebureau, er My Booking desværre ikke tilgængelig for dig endnu.",
      helpText:
        "Hvis dette ikke er tilfældet, så kontakt os for at få yderligere hjælp.",
      button: "Log ind"
    },
    general: {
      title: "Beklager!",
      secondaryTitle: "Det virkede ikke.",
      description:
        "Der er muligvis noget galt med det link, du bruger. Log ind igen.",
      helpText:
        "Hvis du fortsat ser denne fejl, fungerer My Booking muligvis ikke korrekt. Kontakt os for at få yderligere hjælp.",
      button: "Log ind"
    },
    bookingCancelled: {
      title: "Beklager!",
      secondaryTitle:
        "Det ser ud til, at dette bookingnummer er blevet ændret.",
      description:
        "Hvis du har et nyt bookingnummer, skal du bruge dette til at logge ind og se dine opdaterede bookingoplysninger.",
      helpText:
        "Hvis dette ikke er tilfældet, så kontakt os for at få yderligere hjælp.",
      button: "Log ind"
    }
  },
  login: {
    applicationName: "My Booking",
    description: "Se din booking og forbered dig på dit eventyr",
    formExplanation:
      "Brug dine bookingoplysninger for at få et overblik over din rejse.",
    button: { toMyBooking: "Gå til My Booking", help: "Har du spørgsmål?" },
    errorMessage: {
      unauthorized:
        "Beklager, det ser ud som om bookingnummeret, booking-e-mailen og/eller det fulde navn er forkert. Sørg for, at e-mailen og det fulde navn er den person, der bestilte rejsen.",
      systemDown:
        "Beklager, vores systemer ser ud til at være nede. Prøv igen senere.",
      default: "Noget gik galt. Prøv igen senere eller kontakt kundeservice.",
      bookingRef: "Dette feltet skal kun inneholde tall."
    }
  },
  faq: {
    title: "Hvad kan vi hjælpe dig med?",
    description: "Herunder finder du svar på de spørgsmål, vi oftest får.",
    backLink: "Tilbage til log-ind",
    questions: {
      about: "Hvad er My Booking?",
      credentials: "Hvor finder jeg oplysningerne til at logge på?",
      content: "Hvad finder jeg i My Booking?",
      cancelled: "Kan jeg få adgang til et aflyst reservation?",
      agency:
        "Kan jeg få adgang til en reservation, der er foretaget via et rejsebureau?",
      otherBookings: "Kan jeg se mine andre reservationer i My Booking?",
      cancel: "Jeg vil gerne annullere min rejse, hvad bør jeg gøre?",
      misspelled: "Min navn er stavet forkert, hvad bør jeg gøre?"
    },
    answers: {
      about:
        "Vi forpligter os til at yde den bedste service til dig, hele vejen fra reservationen til at du kommer godt hjem fra en af vores krydstogter. My Booking er en web-service, hvor du kan finde vigtig information om din reservation.",
      credentials:
        "Brug reservationsnummeret (som står på fakturaen), e-mailadressen og det fulde navn på den person, som foretog reservationen (også kaldet den ledende passagerer).",
      content:
        "Få et hurtigt overblik over dine rejseoplysninger. Se dine afgangs- og ankomstdatoer, reserverede udflugter og enhver transport ifm. din rejse. Gennemse information om gæster, kahyt og betaling. Læs mere om din rejseplan, og lær skibet at kende inden dit kommende eventyr.",
      cancelled:
        "Nej, hvis du indtaster oplysningerne på en aflyst reservation, så vil du ikke have adgang til den.",
      agency:
        "Nej, ingen reservationer via rejsebureauer er understøttet på nuværende tidspunkt.",
      otherBookings:
        "My Booking er en løsning, hvor du kan tilgå information om en enkelt reservation.",
      cancel:
        "Hvis du vil aflyse din reservation, bør du sende en e-mail til os, så klarer vi det for dig.",
      misspelled: "Kontakt os omgående, hvis dit navn er stavet forkert."
    }
  },
  contact: {
    contactUs: "Kontakt os",
    stillGotQuestions: "Har du stadig spørgsmål?",
    pleaseContactUsPhrase:
      "Kontakt os omgående, vi vil hellere end gerne hjælpe dig."
  },
  aboutVoyage: {
    title: "Få hjælp med din rejse",
    description: "Vi hjælper dig hellere end gerne",
    mailto: "Send os en e-mail"
  },
  feedback: {
    title: "Vil du hjælpe os med at gøre My Booking bedre?",
    placeholder:
      "Tak, fordi du hjælper os med at gøre My Booking bedre! Hvad ser du gerne ændret eller forbedret?",
    disclaimer:
      "Bemærk, at feedbacken ikke bliver besvaret. Hvis du har spørgsmål eller forespørgsler vedrørende en bestemt rejse, bedes du kontakte kundeservice.",
    buttonText: "Send feedback"
  },
  thankyou: {
    title: "Tak!",
    description:
      "Din feedback er blevet sendt. Vi vil have det i tankerne ifm. med fremtidige forbedringer af My Booking.",
    button: "Giv mere feedback"
  },
  questionsTile: {
    title: "Har du spørgsmål?",
    description:
      "Hvorfor ikke se vores hyppigt stillede spørgsmål (FAQ), før du kontakter os?",
    linkText: "Læs FAQ"
  },
  bookingOverview: {
    links: {
      learnMoreAboutYourVoyage: "Lær mere om din rejse",
      managePayment: "Administrer dine betalinger",
      manageQuotePayment: "Betal og bestil"
    },
    detailedInformation:
      "Oplysninger: Dine billetter og specifikke oplysninger om din rejseplan og flyafgange bliver sendt via e-mail 2-4 uger før din rejse starter."
  },
  paymentStatus: {
    title: "Opsummering og betaling",
    paymentStatus: "Betalingsstatus",
    partlyPaid: "Delvist betalt",
    fullyPaid: "Fuldt betalt",
    noPaymentReceived: "Ingen betaling modtaget",
    due: "Forfalder",
    depositDue: "Depositum forfalder",
    pendingPayment: "Afventer betaling"
  },
  cabins: {
    header: "Mine kahytter",
    notYetAvailable: "Ikke tilgængelig endnu",
    facilities: {
      window: {
        pothole: "Koøje",
        window: "Vindue",
        windowLimitedView: "Vindue med begrænset udsigt",
        noInformation: ""
      },
      bed: {
        doubleBed: "Dobbeltseng",
        twinBed: "To enkeltsenge",
        oneBedOneCouch: "1 seng / 1 sofa",
        bunkBeds: "Køjesenge",
        beds: "Senge",
        mixed: "Blandet",
        noInformation: ""
      },
      bathroom: "Badeværelse",
      balcony: "Balkon",
      sofa: "Sofa",
      tv: "Fjernsyn",
      dinnerTable: "Spisebord"
    },
    cabinGrade: "Kahyt klasse"
  },
  excursions: {
    yourExcursions: "Mine udflugter",
    othersExcursions: "Excursions booked by others in your group",
    links: {
      moreInfo: "Mere information",
      viewMore: "Anmod om flere udflugter"
    },
    customize: "Tilpas din rejse!",
    loveToHelp:
      "Vi vil gerne hjælpe dig med at få mest muligt ud af din rejse.",
    summary: "{{date}} for {{count}} person",
    summary_plural: "{{date}} for {{count}} personer",
    information: {
      noExcursions: "Du har ikke forudbestilt nogen udflugter endnu.",
      moreExcursions:
        "Nogle udflugter i forbindelse med denne rejse er muligvis ikke klar til at booke før 6-9 måneder før rejsen starter."
    }
  },
  logout: {
    logout: "Log af"
  },
  consents: {
    button: {
      accept: "Jeg accepterer",
      cookieLinkText: "Mere information om cookies"
    }
  },
  meals: {
    bookedMeals: "Bestilte måltider"
  },
  words: {
    minute: "minut",
    minute_plural: "minutter",
    hour: "time",
    hour_plural: "timer",
    day: "dag",
    day_plural: "dage",
    cabin: "kahyt",
    cabin_plural: "kahytter",
    excursion: "udflugt",
    excursion_plural: "udflugter",
    person: "person",
    person_plural: "personer",
    car: "bil",
    car_plural: "biler",
    motorcycle: "motorcykel",
    motorcycle_plural: "motorcykler",
    flight: "Flyafgang",
    hotel: "Hotel",
    rail: "Tog",
    transfer: "Transport",
    breakfast: "morgenmad",
    lunch: "frokost",
    dinner: "aftensmad",
    and: "og",
    map: "Kort",
    yes: "Ja",
    no: "Nej",
    add: "Add",
    edit: "Edit",
    readMore: "Læs mere",
    arrival: "Ankomst",
    departure: "Afgang",
    stop: "stop",
    stop_plural: "stop"
  },
  labels: {
    facilities: "Facilititer",
    category: "Kategori",
    guests: "Gæster",
    leadPassenger: "Kontaktpersonen",
    membershipLevel: "Medlemskabsniveau",
    date: "Dato",
    voyage: "Rejse",
    ship: "Skib",
    included: "Inkluderet",
    bookedServices: "Bestilte tjenester",
    travelPeriod: "Rejseperiode",
    sailingPeriod: "Sejlperiode",
    duration: "Varighed",
    bookingReferenceNumber: "Tilbud",
    quoteValidUntil: "Dette tilbud gælder indtil",
    quoteDisclaimer:
      "Hvis der ikke betales inden dette tidspunkt, vil dit tilbud automatisk blive annulleret. Læs venligst ordreoplysningerne omhyggeligt og kontakt os, hvis du ønsker at ændre nogle af detaljerne. Hvis du ønsker at konvertere dit tilbud til en ordre, skal du betale.",
    quoteReferenceNumber: "Quote-referance",
    bookingEmail: "E-mail",
    fullName: "Fulde navn",
    cabinNumber: "Kahytnummer",
    vehicle: "Køretøj",
    vehicle_plural: "Køretøj",
    fromTo: "Fra — til",
    passengerInfo: "Passagereroplysninger",
    travellingWith: "Når du rejser med",
    exploring: "Rejse",
    total: "Totalsum",
    perPerson: "Per person"
  },
  alternativeTexts: {
    excursionImage: "Billede fra udflugt"
  },
  warning: {
    departureTime: "Bemærk venligst, at skibet afgår efter midnat."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "Edit email",
    close: "Close",
    updateButtonText: "Update Email",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Show link",
    hideLink: "Hide access link",
    copy: "Copy",
    close: "close",
    description: "Med denne lenken kan {{name}} logge seg inn"
  },
  whosTraveling: {
    title: "Hvem rejser?"
  },
  upcomingVoyage: {
    voyageDateDeteails: `Rejse begynder den {{startDate}}, slutter den {{endDate}}`
  },
  footer: {
    termsAndConditions: "Vilkår og betingelser",
    statementOfPrivacy: "Privatlivspolitik",
    cookies: "Cookies",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "Læs mere om platinbilletten",
    button: "Anmod om platin",
    loading: "Loading",
    requestSuccess:
      "Anmodningen blev sendt. Du vil blive kontaktet med yderligere information",
    requestError: "Noget gik galt, prøv igen eller kontakt os via telefon",
    upgradeInitiated:
      "Tak, fordi du opgraderer til Platinum. Vi kontakter dig snart med flere detaljer.",
    upgradeInProgress:
      "Vi forbereder os på at sende dig en e-mail om din Platinum-opgradering.",
    upgradeDeclined:
      "Vi modtog ikke din bekræftelse, så vi har ikke været i stand til at opgradere dig til Platinum. Kontakt os venligst, hvis du beslutter dig for at opgradere.",
    upgradeApproved: "Tillykke – du er blevet opgraderet til Platinum!"
  }
};
export default danish;
