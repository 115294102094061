import React, { useContext } from "react";
import { useTrans } from "i18n";
import { FullWidth } from "components/Grid";
import VoyageDetails from "./VoyageDetails";
import { ExpandableBox } from "@hurtigruten/shared-components";
import { BookingOverViewContext } from "../BookingLoader";

const VoyageBox: React.FC = () => {
  const { translate } = useTrans();
  const { voyages } = useContext(BookingOverViewContext);

  const voyageHeadings = voyages
    .map(
      voyage => voyage?.editorialContent?.heading || voyage?.packageDescription
    )
    .filter(heading => heading) as string[];

  const headingsAsTags = voyageHeadings.map(heading => {
    return { content: heading };
  });

  return (
    <FullWidth>
      <ExpandableBox
        id={"voyage"}
        heading={translate(x => x.labels.voyage)}
        defaultExpanded={false}
        summaryTags={headingsAsTags}
      >
        <VoyageDetails />
      </ExpandableBox>
    </FullWidth>
  );
};

export default VoyageBox;
