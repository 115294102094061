import styled from "styled-components/macro";
import { media } from "utilities/media";

const CenteredWhiteBox = styled.div`
  max-width: 500px;
  width: 100%;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  padding: 70px;
  background: white;
  border-radius: 8px;

  ${media.maxWidth.medium} {
    margin: 0 auto;
    padding: 20px;
    box-shadow: none;
  }
`;

export default CenteredWhiteBox;
