import React from "react";

import { Voyage, Market, Currency } from "models/BookingOverviewData";
import { getVoyageId, getVoyageNames, getVoyageType } from "utilities/voyage";
import { getMarket } from "utilities/market";
import { getDateDifferenceInDays } from "utilities/time";
import { VoyageType } from "models/EpiVoyageContent";

interface TrackPageView {
  market?: Market;
  currency?: Currency;
  voyages?: Voyage[];
  isFakeBooking?: boolean;
  passengerAmount?: number;
  bookingDate?: Date;
  departureDate?: Date;
}

const trackLogin = (
  voyageType: VoyageType,
  voyageId: string,
  voyageName: string,
  voyageMarket: string,
  passengerAmount: number,
  daysUntilDeparture: number,
  daysSinceBooking: number
) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "Login",
      voyageType,
      voyageId,
      voyageName,
      voyageMarket,
      passengerAmount,
      daysUntilDeparture,
      daysSinceBooking
    });
  }
};

const useTrackLogin = ({
  market,
  currency,
  voyages,
  isFakeBooking,
  passengerAmount,
  bookingDate,
  departureDate
}: TrackPageView) => {
  React.useEffect(() => {
    if (isFakeBooking) {
      // Do not track fake bookings
      return;
    }

    if (
      !voyages ||
      voyages.length < 1 ||
      !currency ||
      !market ||
      !passengerAmount ||
      !bookingDate ||
      !departureDate
    ) {
      return;
    }

    const voyageMarket = getMarket(market, currency);
    const voyageIds = getVoyageId(voyages);
    const voyageNames = getVoyageNames(voyages);
    const voyageType = getVoyageType(voyages);
    const daysSinceBooking = getDateDifferenceInDays(bookingDate, new Date());
    const daysUntilDeparture = getDateDifferenceInDays(
      new Date(),
      departureDate
    );

    trackLogin(
      voyageType,
      voyageIds,
      voyageNames,
      voyageMarket,
      passengerAmount,
      daysUntilDeparture,
      daysSinceBooking
    );
  }, [
    market,
    currency,
    voyages,
    isFakeBooking,
    passengerAmount,
    bookingDate,
    departureDate
  ]);
};
export default useTrackLogin;
