import { Voyage } from "models/BookingOverviewData";

const currentSeasonPrices = (
  currency: string,
  isRoundTrip: boolean
): number => {
  switch (currency) {
    case "EUR":
      return isRoundTrip ? 3599 : 2999;
    case "GBP":
      return isRoundTrip ? 2968 : 2473;
    case "USD":
      return isRoundTrip ? 4799 : 3999;
    case "NOK":
      return isRoundTrip ? 28792 : 23992;
    case "SEK":
      return isRoundTrip ? 31640 : 26365;
    case "DKK":
      return isRoundTrip ? 25036 : 20863;
    case "AUD":
      return isRoundTrip ? 5399 : 4999;
    case "CHF":
      return isRoundTrip ? 4139 : 3449;
    case "CAD":
      return isRoundTrip ? 4636 : 3863;
    default:
      return 0;
  }
};

const nextSeasonPrices = (currency: string, isRoundTrip: boolean): number => {
  switch (currency) {
    case "EUR":
      return isRoundTrip ? 3599 : 2999;
    case "GBP":
      return isRoundTrip ? 3023 : 2519;
    case "USD":
      return isRoundTrip ? 4236 : 3530;
    case "NOK":
      return isRoundTrip ? 35990 : 29990;
    case "SEK":
      return isRoundTrip ? 39549 : 32956;
    case "DKK":
      return isRoundTrip ? 26993 : 22493;
    case "AUD":
      return isRoundTrip ? 5578 : 4648;
    case "CHF":
      return isRoundTrip ? 3887 : 3239;
    case "CAD":
      return isRoundTrip ? 5219 : 4349;
    default:
      return 0;
  }
};

const prices = (
  isNextSeason: boolean,
  currency: string,
  isRoundTrip: boolean
): number => {
  return isNextSeason
    ? nextSeasonPrices(currency, isRoundTrip)
    : currentSeasonPrices(currency, isRoundTrip);
};

const formatNumber = (n: number, currency: string): string => {
  if (n === 0) return "";
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    currencyDisplay: "code"
  }).format(n);
};

export const getFixedPrice = (voyage: Voyage, currency: string) => {
  if (!voyage) return null;

  const {
    fromPort: { code: fromCode },
    toPort: { code: toCode },
    departureDateTime
  } = voyage;
  const nextSeasonStart = new Date("2024-04-01");
  const isNextSeason = departureDateTime.getTime() > nextSeasonStart.getTime();
  const isRoundTrip = fromCode === "BGO" && toCode === ("BGO" || "TRD");
  const price = prices(isNextSeason, currency, isRoundTrip);
  const formattedPrice = formatNumber(price, currency);
  return `+ ${formattedPrice}`;
};
