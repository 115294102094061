import { useState } from "react";
import { LoginFormData } from "./LoginForm";

const useForm = (
  setBookingRefError: React.Dispatch<React.SetStateAction<boolean>>
): any => {
  const [formData, setFormData] = useState<LoginFormData>({
    bookingRef: "",
    email: "",
    fullName: ""
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.persist();

    if (event.target.name === "bookingRef") {
      const re = /\d+/;
      setBookingRefError(!re.test(event.target.value));
    }

    setFormData(
      (formData): LoginFormData => ({
        ...formData,
        [event.target.name]: event.target.value
      })
    );
  };
  return {
    handleInputChange,
    setFormData,
    formData
  };
};

export default useForm;
