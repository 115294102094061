import * as React from "react";
import { Redirect } from "@reach/router";

import { paths } from "../../App";
import { post } from "api/utils";
import { LanguageContext } from "i18n";

class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  { hasError: boolean }
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    const exceptionRequest = {
      message: error.message,
      url: window.location.href,
      info: info.componentStack
    };
    post("/api/Tracking", JSON.stringify(exceptionRequest));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LanguageContext.Consumer>
          {value => (
            <Redirect to={`/${value.langCode}${paths.error.default}`} noThrow />
          )}
        </LanguageContext.Consumer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
