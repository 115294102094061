import React from "react";
import { useParams } from "@reach/router";
import { isLanguageCode } from "i18n";
import { useTheme } from "styled-components";
const useTrackPageView = (virtualPageUrl: string, pageType: string) => {
  const { lang } = useParams();
  const { brand } = useTheme();

  React.useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "VirtualPageview",
        virtualPageUrl,
        pageType,
        market: isLanguageCode(lang) ? lang : "en",
        pageBrand: brand.charAt(0).toUpperCase() + brand.slice(1)
      });
    }
  }, [lang, virtualPageUrl, pageType, brand]);
};
export default useTrackPageView;
