import Cabin from "./Cabin";
import Excursion from "./Excursion";
import Vehicle from "./Vehicle";
import AddOn from "./AddOn";
import Passenger, { passengerDefaultValue } from "./Passenger";
import EpiVoyageContent from "./EpiVoyageContent";
import { MealDetail } from "./Meal";
import { Port } from "./Port";

export interface SailingPlanDetail {
  sailingId: string;
  shipCode: string;
  departureDateTime: Date;
  arrivalDateTime: Date;
  departurePort: string;
  arrivalPort: string;
  direction: string;
  departurePortName: string;
  arrivalPortName: string;
}

export interface ItineraryDay {
  url: string;
  dayNumberString: string;
  location: string;
}

export interface WelcomeOnBoard {
  url?: string;
  title?: string;
  subTitle?: string;
}

export const bookingOverviewDataDefaultValue: BookingOverviewData = {
  isFakeBooking: false,
  bookingId: "",
  market: "UK",
  departureDate: new Date(),
  endDate: new Date(),
  currency: "GBP",
  durationInDays: 0,
  passengerAmount: 0,
  cancelled: false,
  dateCreated: new Date(),
  dateCancelled: new Date(),
  flightIncluded: false,
  railIncluded: false,
  transferIncluded: false,
  hotelIncluded: false,
  flightLegs: [],
  voyages: [],
  passengers: [],
  loggedInPassenger: passengerDefaultValue,
  bookMoreExcursionsLinks: [],
  isPlatinum: false,
  isQuotation: false,
  status: "",
  autoCancelAllowedOnDate: null
};

export interface Voyage {
  toPort: Port;
  fromPort: Port;
  shipName: string;
  shipUrl: string;
  packageCode?: string;
  packageDescription?: string;
  editorialContent?: EpiVoyageContent;
  departureDateTime: Date;
  arrivalDateTime: Date;
  cabins: Cabin[];
  vehicles?: Vehicle[];
  excursions: Excursion[];
  includedExcursions?: Excursion[];
  addOns?: AddOn[];
  includedAddOns?: AddOn[];
  meals?: MealDetail[];
  sailingPlan?: SailingPlanDetail[];
  welcomeOnBoard: WelcomeOnBoard;
  itinerary?: ItineraryDay[];
}

export type Market = "UK" | "NO" | "DE" | "FR" | "US";
export type Currency =
  | "GBP"
  | "EUR"
  | "NOK"
  | "SEK"
  | "DKK"
  | "USD"
  | "AUD"
  | "CHF";

export default interface BookingOverviewData {
  isFakeBooking: boolean;
  bookingId: string;
  market: Market;
  departureDate: Date;
  depositDueDate?: Date;
  endDate: Date;
  currency: Currency;
  durationInDays: number;
  passengerAmount: number;
  cancelled: boolean;
  dateCreated: Date;
  dateCancelled: Date;
  flightIncluded: boolean;
  hotelIncluded: boolean;
  railIncluded: boolean;
  transferIncluded: boolean;
  flightLegs: string[];
  voyages: Voyage[];
  passengers: Passenger[];
  loggedInPassenger: Passenger;
  bookMoreExcursionsLinks: string[];
  isPlatinum: boolean;
  status: string;
  isQuotation: boolean;
  autoCancelAllowedOnDate: Date | null;
}
