import React, { useContext, useEffect, useState } from "react";
import { useTrans } from "i18n";
import { ExpandableBox } from "@hurtigruten/shared-components";
import { FullWidth } from "components/Grid";
import PlatinumBanner from "./PlatinumBanner";
import usePlatinumBanner from "api/platinum/usePlatinumBannerEpiContent";
import { BookingOverViewContext } from "../BookingLoader";
import { cabinSupportsPlatinumUpgrade } from "utilities/platinumCabins";
import { routeSupportsPlatinumUpgrade } from "utilities/platinumRoutes";
import { getDateDifferenceInDays } from "utilities/time";

const Upgrade: React.FC = () => {
  const { langCode } = useTrans();
  const bannerState = usePlatinumBanner(langCode);
  const { voyages } = useContext(BookingOverViewContext);
  const {
    shipName,
    cabins,
    fromPort: { code: fromCode },
    toPort: { code: toCode },
    departureDateTime
  } = voyages[0];
  const [supportsPlatinum, setSupportsPlatinum] = useState(false);

  useEffect(() => {
    const isSupportedCabin =
      cabins?.some(c => cabinSupportsPlatinumUpgrade(c)) ?? false;
    const isSupportedRoute = routeSupportsPlatinumUpgrade(fromCode, toCode);
    const isIconic = shipName?.toUpperCase() === "MS TROLLFJORD";
    const isSupportedShip = shipName?.toUpperCase() !== "MS VESTERÅLEN";
    const isSupportedTimeFrame =
      getDateDifferenceInDays(new Date(), departureDateTime) > 30;
    setSupportsPlatinum(
      isSupportedCabin &&
        isSupportedRoute &&
        isSupportedTimeFrame &&
        !isIconic &&
        isSupportedShip
    );
  }, [voyages]);

  if (!supportsPlatinum) return null;

  return (
    <FullWidth>
      <ExpandableBox
        id={"upgrade"}
        heading={bannerState.data?.heading ?? ""}
        defaultExpanded={true}
        summaryTags={[]}
      >
        <PlatinumBanner />
      </ExpandableBox>
    </FullWidth>
  );
};

export default Upgrade;
