import React, { useContext } from "react";
import { BookingOverViewContext } from "../BookingLoader";
import { useTrans } from "i18n";

import { getCscMail } from "utilities/language";
import LinkCard from "components/Card/LinkCard";

const AboutVoyage: React.FC = () => {
  const { translate, langCode } = useTrans();
  const { bookingId } = useContext(BookingOverViewContext);

  return (
    <LinkCard
      title={translate(x => x.aboutVoyage.title)}
      text={translate(x => x.aboutVoyage.description)}
      url={`mailto:${getCscMail(langCode)}?subject=My Booking [${bookingId}]`}
      ctaText={translate(x => x.aboutVoyage.mailto)}
    />
  );
};

export default AboutVoyage;
