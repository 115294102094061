import React, { useContext } from "react";
import styled from "styled-components/macro";
import { useTrans } from "i18n";
import { Link } from "@reach/router";

import { UrlContext } from "UrlLoader";
import { media } from "utilities/media";
import { GreyHorizontalLine } from ".";
import { LinkTextColored } from "styling/text";

const MenuWrapper = styled.div`
  display: none;
  ${media.maxWidth.low} {
    display: block;
  }
`;
const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px;
`;

const MobileMenu: React.FC = () => {
  const { contactUrl } = useContext(UrlContext);
  const { translate, langCode } = useTrans();
  const path = "/" + langCode;

  return (
    <MenuWrapper>
      <MenuItem>
        <LinkTextColored href={contactUrl} target="_blank">
          {translate(x => x.contact.contactUs)}
        </LinkTextColored>
      </MenuItem>
      <GreyHorizontalLine />
      <MenuItem>
        <LinkTextColored as={Link} to={path}>
          {translate(x => x.logout.logout)}
        </LinkTextColored>
      </MenuItem>
      <GreyHorizontalLine />
    </MenuWrapper>
  );
};

export default MobileMenu;
