import { useEffect, useState } from "react";
import * as queryString from "query-string";
import { LoginFormData } from "./LoginForm";

const useLoginQueryParameters = (
  formData: LoginFormData, // Defined in useForm.ts
  setFormData: React.Dispatch<React.SetStateAction<LoginFormData>>, // Defined in useForm.ts
  action: (formData: LoginFormData) => void
): void => {
  const [triggerLogin, setTriggerLogin] = useState(false);

  // Read query-parameters on mount
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.bookingRef || queryParams.email || queryParams.fullName) {
      setFormData({
        bookingRef: Array.isArray(queryParams.bookingRef)
          ? queryParams.bookingRef[0]
          : queryParams.bookingRef,
        email: Array.isArray(queryParams.email)
          ? queryParams.email[0]
          : queryParams.email,
        fullName: Array.isArray(queryParams.fullName)
          ? queryParams.fullName[0]
          : queryParams.fullName
      });

      if (queryParams.bookingRef && queryParams.email && queryParams.fullName) {
        setTriggerLogin(true);
      }
    }
  }, [setFormData]);

  // Trigger login
  useEffect(() => {
    if (
      triggerLogin &&
      formData.bookingRef &&
      formData.email &&
      formData.fullName
    ) {
      action(formData);
      setTriggerLogin(false);
    }
  }, [formData, triggerLogin, action]);
};

export default useLoginQueryParameters;
