import React, { useContext } from "react";
import { useTrans } from "i18n";
import styled from "styled-components/macro";

import Passenger from "models/Passenger";
import { BookingOverViewContext } from "../BookingLoader";
import { AlertBox } from "@hurtigruten/shared-components";
import { spacings } from "styling/spacing";
import Guest from "./Guest";

import { calculateDaysUntilDeparture } from "utilities/time";
import { ExpandableBoxContentContainer } from "components/Grid";

const AlertBoxWithMargin = styled(AlertBox)`
  margin-bottom: ${spacings.four};
`;

const GuestContainer = styled(ExpandableBoxContentContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const GuestsDetails: React.FC = () => {
  const { translate } = useTrans();
  const { passengers, departureDate, voyages, loggedInPassenger } = useContext(
    BookingOverViewContext
  );

  const voyageHasBegun = Boolean(
    calculateDaysUntilDeparture(departureDate) < 1
  );
  const showInfoBox =
    voyages.filter(voyage => voyage.editorialContent != null).length > 0 &&
    !voyageHasBegun;

  return (
    <>
      <GuestContainer>
        <Guest passenger={loggedInPassenger} voyages={voyages} />
        {passengers.length > 1 &&
          passengers
            .filter(
              passenger => passenger.customerId !== loggedInPassenger.customerId
            )
            .map((passenger: Passenger) => (
              <Guest
                key={passenger.customerId}
                passenger={passenger}
                voyages={voyages}
                loggedInPassengerIsLead={loggedInPassenger.isLead}
              />
            ))}
      </GuestContainer>
      {showInfoBox && (
        <AlertBoxWithMargin type="info">
          {translate(x => x.bookingOverview.detailedInformation)}
        </AlertBoxWithMargin>
      )}
    </>
  );
};

export default GuestsDetails;
