import styled from "styled-components/macro";
import { media } from "utilities/media";
import { spacings } from "styling/spacing";

export const Columns = styled.div`
  display: flex;

  ${media.maxWidth.low} {
    flex-direction: column;

    & > *:first-child {
      margin-bottom: ${spacings.four};
    }
  }
`;
