import styled from "styled-components/macro";
import { media } from "utilities/media";
import { spacings } from "styling/spacing";

interface BoxProps {
  hiddenFor?: "desktop" | "mobile";
  faded?: boolean;
  backgroundColor?: string;
  color?: string;
}

export const Box = styled.div`
  display: ${(props: BoxProps) =>
    props.hiddenFor === "desktop" ? "none" : "block"};
  width: 100%;
  min-width: 300px;
  padding: ${spacings.four} ${spacings.four} ${spacings.one} ${spacings.four};
  margin-bottom: ${spacings.four};
  opacity: ${(props: BoxProps) => (props.faded ? "0" : "1")};
  transition: opacity 0.2s ease-in-out;

  background-color: ${(props: BoxProps) => props.backgroundColor || "white"};
  color: ${(props: BoxProps) => props.color || "black"};

  border-radius: 8px;

  ${media.maxWidth.medium} {
    min-width: unset;
    padding: ${spacings.three};
    margin-bottom: ${spacings.two};
    display: ${(props: BoxProps) =>
      props.hiddenFor === "mobile" ? "none" : "block"};
  }
`;
