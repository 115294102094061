import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

export interface ConsentTexts {
  intro: string;
  consentGeneralName: string;
  consentGeneralDescription: string;
  consentMarketingName: string;
  consentMarketingDescription: string;
  url: string;
}

const getConsentTexts = async (
  language: string
): Promise<ApiResponse<ConsentTexts>> => {
  const response = await get(`/api/cookieconsent/texts/${language}`);

  return mapToApiResponse<ConsentTexts>({
    response,
    propertyToCheck: "intro",
    fallBackErrorMessage:
      "Unexpected response type: Type did not match ConsentTexts"
  });
};

const useConsentTexts = (
  language: string,
  shouldFetchTexts: boolean
): {
  data?: ConsentTexts;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<ConsentTexts, string>(
    getConsentTexts,
    language,
    shouldFetchTexts
  );
};

export default useConsentTexts;
