import React from "react";
import { useTrans } from "i18n";

import { Info } from "components/Grid";
import Vehicle from "models/Vehicle";
import { TabularHeader, TabularText } from "@hurtigruten/shared-components";

interface VehiclesElementProps {
  vehicles: Vehicle[];
  showLabel?: boolean;
}

const VehiclesElement: React.FC<VehiclesElementProps> = ({
  vehicles,
  showLabel = true
}) => {
  const { translate } = useTrans();

  const numberOfMotorcycles = vehicles.filter(
    vehicle => vehicle.type === "MOTORCYCLE"
  ).length;
  const numberOfCars = vehicles.filter(vehicle => vehicle.type === "CAR")
    .length;

  const vehicleStrings: string[] = [];

  if (numberOfCars > 0) {
    vehicleStrings.push(
      `${numberOfCars} ${translate(x =>
        numberOfCars > 1 ? x.words.car_plural : x.words.car
      )}`
    );
  }

  if (numberOfMotorcycles > 0) {
    vehicleStrings.push(
      `${numberOfMotorcycles} ${translate(x =>
        numberOfMotorcycles > 1 ? x.words.motorcycle_plural : x.words.motorcycle
      )}`
    );
  }

  const vehicleString = vehicleStrings.join(", "); // Add comma between strings

  return (
    <Info>
      {showLabel && (
        <TabularHeader marginBottom="one">
          {translate(x => x.labels.vehicle)}
        </TabularHeader>
      )}
      <TabularText as="h4">{vehicleString}</TabularText>
    </Info>
  );
};

export default VehiclesElement;
