import React from "react";

const HrgSymbol = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 70 70"
  >
    <circle cx="35" cy="35" r="35" fill="#d40000" />
    <g fill="#fff">
      <path d="M42.4 16.2H52v37.6h-9.6z" fillRule="evenodd" />
      <path d="M18.4 54.2L42 30.6H27.6V16.2h-9.2z" />
    </g>
  </svg>
);

export default HrgSymbol;
