import { getPayment } from "./api";
import { PaymentInfo } from "models/Payment";
import { Error } from "../models";
import useFetchData from "../utils/useFetchData";

const usePayment = (
  bookingid: string
): {
  data?: PaymentInfo;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<PaymentInfo, string>(getPayment, bookingid);
};

export default usePayment;
