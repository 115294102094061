import React from "react";

const CoastalLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180.2" height="70">
    <circle cx="34.6" cy="35.1" r="11.6" fill="#d40000" />
    <g fill="#fff">
      <path d="M37 28.9h3.1v12.5H37z" fillRule="evenodd" />
      <path d="M29.1 41.6l7.8-7.9h-4.7v-4.8h-3.1z" />
    </g>
    <path d="M150.8 41.7h8.8v-2.5h-6.1v-3h5.2v-2.5h-5.2V31h5.9v-2.5h-8.6v13.2h0zm-8.1 0h2.7V31h3.6v-2.5h-9.9V31h3.6v10.7h0zm-10.4.2h.5c2.8 0 4.7-1.3 4.7-4v-9.4h-2.7v9c0 1.2-.5 1.9-2.1 1.9h-.5c-1.5 0-2-.6-2-1.9v-9h-2.7V38c.1 2.6 2 3.9 4.8 3.9zm-13.7-7.6V31h2.6c1.2 0 1.7.5 1.7 1.5v.4c0 1-.6 1.5-1.6 1.5l-2.7-.1h0zm-2.7 7.4h2.7v-5.1h2.5l1.8 5.1h3.1l-2.4-5.6c1.2-.6 2.1-1.7 2.1-3.3v-.3c0-2.5-1.7-3.9-4.3-3.9H116l-.1 13.1h0zm-8.6.2h.5c1.4 0 2.6-.7 3.1-2h.2l.4 1.8h1.9v-7.4h-5.1v2.2h2.6v1c0 1.2-.7 2-2 2h-.7c-1.3 0-2-.7-2-2.1v-4.8c0-1.2.6-1.9 2.1-1.9h.5c1.3 0 1.9.7 1.9 1.8v.2h2.7v-.2c0-2.6-1.6-4.2-4.5-4.2h-.6c-2.8 0-4.8 1.4-4.8 4.3v5.2c0 2.7 1.7 4.1 3.8 4.1zm-8.8-.2h2.7V28.5h-2.7v13.2zm-8.1 0h2.7V31h3.6v-2.5h-9.9V31h3.6c0 0 0 10.7 0 10.7zm-11.5-7.4V31h2.6c1.2 0 1.7.5 1.7 1.5v.4c0 1-.6 1.5-1.6 1.5l-2.7-.1h0zm-2.7 7.4h2.7v-5.1h2.5l1.8 5.1h3.1l-2.4-5.6c1.2-.6 2.1-1.7 2.1-3.3v-.3c0-2.5-1.7-3.9-4.3-3.9h-5.4l-.1 13.1h0zm-7.7.2h.5c2.8 0 4.7-1.3 4.7-4v-9.4H71v9c0 1.2-.5 1.9-2.1 1.9h-.5c-1.5 0-2-.6-2-1.9v-9h-2.7V38c.1 2.6 2 3.9 4.8 3.9zm-17.4-.2h2.7v-5.5h4.8v5.5h2.7V28.5h-2.7v5.1h-4.8v-5.1h-2.7v13.2zm110.6 0h2.7v-3.5c0-2.5 0-4.3-.2-6.8h.2c.5 1.4.9 2.6 2 5.2l2 5.1h3.6V28.5h-2.7v3.2c0 2.5.1 4.2.3 7h-.2c-.6-1.7-1.2-3.3-2.2-5.9l-1.7-4.3h-3.8v13.2h0z" />
  </svg>
);

export default CoastalLogo;
