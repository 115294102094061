export const getCscMail = (language: string) => {
  switch (language) {
    case "gb":
      return "uk.sales@hurtigruten.com";
    case "au":
      return "au.info@hurtigruten.com";
    case "fr":
      return "contact@hurtigruten.fr";
    case "de":
      return "ce.info@hurtigruten.com";
    case "no":
    case "da":
    case "sv":
      return "booking@hurtigruten.com";
    case "us":
      return "usbooking@hurtigruten.com";
    case "en":
    default:
      return "book@hurtigruten.com";
  }
};
