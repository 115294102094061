import { LanguageCode } from "i18n";

type FeatureValue = LanguageCode[] | boolean;

interface FeatureValues {
  bookingRefValidation: FeatureValue;
  accessLink: FeatureValue;
  membershipLevel: FeatureValue;
  showPaymentInfo: FeatureValue;
  showPlatinumBanner: FeatureValue;
}

const featureValues: FeatureValues = {
  bookingRefValidation: ["en", "gb", "au", "us", "no"],
  accessLink: ["en", "gb", "au", "us", "no"],
  membershipLevel: ["no"],
  showPaymentInfo: [],
  showPlatinumBanner: [
    "en",
    "gb",
    "au",
    "us",
    "no",
    "sv",
    "fr",
    "da",
    "de",
    "ch"
  ]
};

type AvailableFeatures = keyof FeatureValues;

export const isActive = (
  feature: AvailableFeatures,
  language: LanguageCode
): boolean => {
  const activeFor = featureValues[feature];

  if (Array.isArray(activeFor)) {
    return activeFor.includes(language);
  }

  return activeFor;
};
