import styled from "styled-components/macro";

import { PrimaryButton, LinkText } from "@hurtigruten/shared-components";
import { spacings } from "styling/spacing";

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  z-index: 10;
`;

export const Modal = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${spacings.five};
`;

export const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PrimaryButtonWithMargin = styled(PrimaryButton)`
  margin-right: 20px;
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;

  li {
    margin-bottom: ${spacings.two};
  }
`;

export const Link = styled(LinkText)`
  margin-bottom: ${spacings.four}
  display: block;
`;
