import styled from "styled-components/macro";

interface IconProps {
  url: string;
  size?: string;
}

export const Icon = styled.div<IconProps>`
  display: inline-block;
  background: url(${(props): string => props.url}) no-repeat;
  background-position-x: center;
  width: ${(props): string => (props.size ? props.size : "24px")};
  height: ${(props): string => (props.size ? props.size : "24px")};
  vertical-align: text-top;
`;
