import React from "react";
import { Box } from "components/Grid";
import { BodyText, Header3 } from "@hurtigruten/shared-components";
import styled from "styled-components";
import { spacings } from "styling/spacing";
import { media } from "utilities/media";

const Container = styled.div`
  width: 100%;
  padding: 0 ${spacings.nine};

  ${media.maxWidth.medium} {
    padding: 0 ${spacings.two};
  }
`;

interface ColorProps {
  color?: string;
}

const AlertBox = styled(Box)`
  background-color: ${({ color }: ColorProps) => color || "#FFF4D1"};
  padding-bottom: ${spacings.four};
`;

interface AlertCardProps {
  title: string;
  description: string;
  color?: string;
}

const AlertCard: React.FC<AlertCardProps> = ({
  title,
  description,
  color,
  children
}) => {
  return (
    <Container>
      <AlertBox color={color}>
        <Header3 marginBottom="two">{title}</Header3>
        <BodyText marginBottom="two">{description}</BodyText>
        {children}
      </AlertBox>
    </Container>
  );
};

export default AlertCard;
