import React, { useContext } from "react";
import styled from "styled-components/macro";
import { TabularHeader, TabularText } from "@hurtigruten/shared-components";
import { useTrans } from "i18n";
import { PaymentInfoContext } from "../BookingLoader";
import { dateToString } from "utilities/time";
import { Margin } from "styling/spacing";

const InfoAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentItem: React.FC = () => {
  const { translate, langCode } = useTrans();
  const paymentInfo = useContext(PaymentInfoContext);

  if (!paymentInfo) {
    return null;
  }

  const { payment, deposit, totalPriceString } = paymentInfo;

  const showDepositDueDate =
    deposit.amount > 0 && deposit.amount > payment.totalPaymentsReceived;
  const showFinalDueDate = !showDepositDueDate && payment.balance > 0;

  return (
    <Margin marginBottom="eight">
      <InfoAndPrice>
        <TabularHeader>{translate(x => x.labels.total)}</TabularHeader>
        <TabularHeader>{totalPriceString}</TabularHeader>
      </InfoAndPrice>

      {showDepositDueDate && (
        <InfoAndPrice>
          <TabularText marginBottom="two">
            {`${translate(x => x.paymentStatus.depositDue)}: ${dateToString(
              deposit.dueDate,
              langCode,
              false,
              true
            )}`}
          </TabularText>
          <TabularHeader>{deposit.amountString}</TabularHeader>
        </InfoAndPrice>
      )}

      {showFinalDueDate && (
        <InfoAndPrice>
          <TabularText marginBottom="two">
            {`${translate(x => x.paymentStatus.due)}: ${dateToString(
              payment.dueDate,
              langCode,
              false,
              true
            )}`}
          </TabularText>
          <TabularHeader>{payment.balanceString}</TabularHeader>
        </InfoAndPrice>
      )}
    </Margin>
  );
};

export default PaymentItem;
