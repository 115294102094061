import styled from "styled-components/macro";
import { spacings } from "styling/spacing";

const HorizontalTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  padding: ${spacings.five} ${spacings.four};
`;

export default HorizontalTile;
