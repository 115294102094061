import React, { useContext } from "react";
import styled from "styled-components/macro";
import { useTrans } from "i18n";
import { Link } from "@reach/router";
import { LinkTextColored } from "styling/text";
import { UrlContext } from "UrlLoader";
import { media } from "utilities/media";

const MenuWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  ${media.maxWidth.low} {
    display: none;
  }
`;

const DesktopMenu: React.FC = () => {
  const { contactUrl } = useContext(UrlContext);
  const { translate, langCode } = useTrans();
  const path = "/" + langCode;

  return (
    <MenuWrapper>
      <LinkTextColored
        href={contactUrl}
        target="_blank"
        aria-label="Contact us"
      >
        {translate(x => x.contact.contactUs)}
      </LinkTextColored>
      <LinkTextColored as={Link} to={path} aria-label="Log out from My Booking">
        {translate(x => x.logout.logout)}
      </LinkTextColored>
    </MenuWrapper>
  );
};

export default DesktopMenu;
