import { ApiResponse } from "../models";
import { PaymentInfo } from "models/Payment";
import { get } from "../utils";
import mapToApiResponse from "../utils/mapToApiResponse";
import mapPaymentData from "./mapping";

export const getPayment = async (
  bookingId: string
): Promise<ApiResponse<PaymentInfo>> => {
  const response = await get(`/api/Booking/${bookingId}/Payment`);

  return mapToApiResponse<PaymentInfo>({
    response,
    propertyToCheck: "payment",
    fallBackErrorMessage: "Unexpected response type: Did not match PaymentInfo",
    mapFunction: mapPaymentData
  });
};
