import React from "react";

import { InfoGroup } from "components/Grid";
import { MealStrings } from "./helper";
import { TabularText } from "@hurtigruten/shared-components";

interface BookedMealProps {
  meal: MealStrings;
  showVoyageDescription?: boolean;
}

const BookedMeal: React.FC<BookedMealProps> = ({
  meal,
  showVoyageDescription = true
}) => {
  return (
    <InfoGroup marginBottom="one">
      {showVoyageDescription && <TabularText>{meal.voyage + ":"}</TabularText>}
      <TabularText>{meal.description}</TabularText>
    </InfoGroup>
  );
};

export default BookedMeal;
