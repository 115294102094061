import React, { useContext } from "react";
import { BookingOverViewContext } from "../BookingLoader";
import { SeparatorLine, ExpandableBoxContentContainer } from "components/Grid";
import CabinElement from "./CabinElement";

export const CabinsDetails: React.FC = () => {
  const { voyages, passengers } = useContext(BookingOverViewContext);

  return (
    <>
      {voyages.map((voyage, index) => {
        const { cabins } = voyage;

        if (!cabins || cabins.length < 1) {
          return null;
        }

        const voyageHeading =
          voyages.length > 1
            ? voyage?.editorialContent?.heading || voyage.packageDescription
            : undefined;

        return (
          <ExpandableBoxContentContainer key={voyage.fromPort.code}>
            {cabins.map(cabin => (
              <CabinElement
                key={cabin.cabinNumber}
                cabin={cabin}
                voyageHeading={voyageHeading}
                passengers={passengers}
              />
            ))}
            {index !== voyages.length - 1 && <SeparatorLine />}
          </ExpandableBoxContentContainer>
        );
      })}
    </>
  );
};
