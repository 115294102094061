import React from "react";
import styled, { useTheme } from "styled-components";
import { spacings, Margin } from "styling/spacing";
import {
  Header3,
  BodyText,
  SecondaryButton
} from "@hurtigruten/shared-components";
import { Box } from "../Grid";
interface LinkCardProps {
  title: string;
  text: string;
  url: string;
  ctaText: string;
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-decoration: none;
  flex: 1 1 auto;
  padding: ${spacings.five};
`;

const ExpeditionButton = styled(SecondaryButton)`
  align-self: flex-start;
`;

const CoastalButton = styled(SecondaryButton)`
  align-self: flex-start;
  border-color: white;
  background-color: transparent;
  color: white;

  :hover,
  :focus {
    border-color: white;
    background-color: white;
    color: #174c8b;
  }
`;

const LinkCard: React.FC<LinkCardProps> = ({ title, text, url, ctaText }) => {
  const { brand } = useTheme();

  const color = brand === "expedition" ? "black" : "#174C8B";

  return (
    <Container as="a" href={url} backgroundColor={color} color="white">
      <Margin marginBottom="four">
        <Header3 marginBottom="two">{title}</Header3>
        <BodyText>{text}</BodyText>
      </Margin>
      {brand === "expedition" ? (
        <ExpeditionButton inverted>{ctaText}</ExpeditionButton>
      ) : (
        <CoastalButton>{ctaText}</CoastalButton>
      )}
    </Container>
  );
};

export default LinkCard;
