import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

export interface ConsentValues {
  general: boolean;
  marketing: boolean;
}

const getConsentValues = async (
  url: string
): Promise<ApiResponse<ConsentValues>> => {
  const consentResponse = await get(`${url}rest/consent`);

  return mapToApiResponse<ConsentValues>({
    response: consentResponse,
    propertyToCheck: "general",
    fallBackErrorMessage:
      "Unexpected response type: Type did not match ConsentValues"
  });
};

const useConsentValues = (
  url?: string
): {
  data?: ConsentValues;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<ConsentValues, string>(getConsentValues, url);
};

export default useConsentValues;
