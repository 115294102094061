import { getBooking } from "./api";
import BookingOverviewData from "models/BookingOverviewData";
import { Error } from "../models";

import useFetchData from "../utils/useFetchData";

const useBookingOverview = (
  bookingid: string
): {
  data?: BookingOverviewData;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<BookingOverviewData, string>(getBooking, bookingid);
};

export default useBookingOverview;
