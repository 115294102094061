import React, { useContext } from "react";
import { useTrans } from "i18n";
import styled from "styled-components/macro";

import HrgSymbolTitleWhite from "icons/iconComponents/hrg-symbol-title-white-font";
import { UrlContext } from "UrlLoader";
import hideOnPrint, { PrintProps } from "styling/print";
import { LinkText } from "@hurtigruten/shared-components";

interface FooterProps extends PrintProps {}

const FooterContainer = styled.div`
  ${hideOnPrint}
  background-color: #232120;
`;

const Columns = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  max-width: 1240px;
  margin: 0 auto;
  padding: 30px 0;
`;

const Column = styled.div`
  min-width: 320px;
  padding: 20px;
`;

const GreyLinkText = styled(LinkText)`
  color: #e4e0e0;
  padding-bottom: 6px;
  display: block;
`;

export const Footer: React.FC<FooterProps> = ({ hideOnPrint }) => {
  const { translate } = useTrans();
  const {
    frontPageUrl,
    contactUrl,
    cookiesUrl,
    privacyUrl,
    termsAndConditionsUrl,
    faqUrl
  } = useContext(UrlContext);

  return (
    <FooterContainer hideOnPrint={hideOnPrint}>
      <Columns>
        <Column>
          <a href={frontPageUrl} aria-label="Hurtigruten home page">
            <HrgSymbolTitleWhite />
          </a>
        </Column>
        <Column>
          <GreyLinkText href={contactUrl} aria-label="Contact us">
            {translate(x => x.contact.contactUs)}
          </GreyLinkText>
          <GreyLinkText href={faqUrl} aria-label="Frequently asked questions">
            {translate(x => x.footer.faq)}
          </GreyLinkText>
        </Column>
        <Column>
          <GreyLinkText
            href={termsAndConditionsUrl}
            aria-label="Terms and conditions"
          >
            {translate(x => x.footer.termsAndConditions)}
          </GreyLinkText>
          <GreyLinkText href={cookiesUrl} aria-label="Cookies">
            {translate(x => x.footer.cookies)}
          </GreyLinkText>
          <GreyLinkText href={privacyUrl} aria-label="Statement of privacy">
            {translate(x => x.footer.statementOfPrivacy)}
          </GreyLinkText>
        </Column>
      </Columns>
    </FooterContainer>
  );
};
