import React from "react";
import { Port } from "models/Port";
import { Margin, spacings, AvailableSpacings } from "styling/spacing";

import PortInfo from "./PortInfo";
import styled from "styled-components";
import {
  colors,
  TabularHeader,
  TabularText
} from "@hurtigruten/shared-components";

export const TextWithLine = styled(TabularHeader)`
  position: relative;

  &:before {
    content: "";
    border-bottom: 2px solid ${colors.foggyBlue.shade3};
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: -${({ left = "three" }: { left?: AvailableSpacings }) => spacings[left]};
    width: ${spacings.two};
  }
`;

interface EndPortProps {
  time: string;
  port: Port;
}

const EndPort: React.FC<EndPortProps> = ({ time, port }) => {
  if (!port.name) {
    return null;
  }

  return (
    <Margin marginBottom="three">
      <TextWithLine marginBottom="one">{port.name}</TextWithLine>
      <TabularText>{time}</TabularText>
      <PortInfo port={port} />
    </Margin>
  );
};

export default EndPort;
