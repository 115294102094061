import React from "react";
import styled from "styled-components";
import { useTrans } from "i18n";
import Excursion from "models/Excursion";
import ExcursionDetails from "./ExcursionDetails";
import { media } from "utilities/media";
import { spacings } from "../../../styling/spacing";

const addImageQuery = (url: string, width: string, height: string): string => {
  return (
    url.split("?")[0] + `?width=${width}&height=${height}&transform=downfill`
  );
};

export const ReversedColumns = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacings.six};

  & > *:first-child {
    margin-right: ${spacings.four};
  }

  ${media.maxWidth.medium} {
    flex-direction: column-reverse;

    & > *:first-child {
      margin: 0;
    }

    & > *:last-child {
      margin-bottom: ${spacings.four};
    }
  }
`;

const ExcursionInfoBlock = styled.div`
  flex: 1 0 auto;
  max-width: calc(50% - ${spacings.two});

  ${media.maxWidth.medium} {
    max-width: 100%;
    width: 100%;
  }
`;

interface ExcursionProps {
  excursion: Excursion;
  id: string | number;
}

const ExcursionInfo: React.FC<ExcursionProps> = ({ excursion, id }) => {
  const { translate } = useTrans();

  return (
    <React.Fragment key={id}>
      <ReversedColumns>
        <ExcursionInfoBlock>
          <ExcursionDetails excursion={excursion} />
        </ExcursionInfoBlock>
        <ExcursionInfoBlock>
          {excursion.imageUrl && (
            <img
              style={{ width: "100%" }}
              src={addImageQuery(excursion.imageUrl, "450", "280")}
              alt={translate(x => x.alternativeTexts.excursionImage)}
            />
          )}
        </ExcursionInfoBlock>
      </ReversedColumns>
    </React.Fragment>
  );
};

export default ExcursionInfo;
