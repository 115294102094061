import React, { useContext } from "react";
import { useTrans } from "i18n";
import { UrlContext } from "UrlLoader";
import LinkCard from "components/Card/LinkCard";

const Questions: React.FC = () => {
  const { translate } = useTrans();
  const { faqUrl } = useContext(UrlContext);

  return (
    <LinkCard
      title={translate(x => x.questionsTile.title)}
      text={translate(x => x.questionsTile.description)}
      url={faqUrl}
      ctaText={translate(x => x.questionsTile.linkText)}
    />
  );
};

export default Questions;
