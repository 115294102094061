import React from "react";

import { Text } from "styling/text";
import { useTrans } from "i18n";
import { LinkText } from "@hurtigruten/shared-components";
import { Port } from "models/Port";

interface PortInfoProps {
  port: Port;
}

const PortInfo = ({ port }: PortInfoProps) => {
  const { translate } = useTrans();

  if (!port.address) {
    return null;
  }

  const addressLines = port.address.split("\n");

  return (
    <>
      {addressLines.map((addressLine, index) => (
        <Text color="rgba(0, 0, 0, 0.86)" size="small" key={addressLine}>
          <span>{addressLine} </span>
          {addressLines.length - 1 === index && port.mapLink && (
            <span>
              (
              <LinkText href={port.mapLink}>
                {translate(x => x.words.map)}
              </LinkText>
              )
            </span>
          )}
        </Text>
      ))}
    </>
  );
};

export default PortInfo;
