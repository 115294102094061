import React, { useContext } from "react";
import { useTrans } from "i18n";
import { BookingOverViewContext } from "./BookingLoader";
import { dateToString } from "utilities/time";
import AlertCard from "components/Card/AlertCard";
import { colors } from "@hurtigruten/shared-components";

const Cancelled: React.FC = () => {
  const { langCode, translate } = useTrans();
  const { dateCancelled } = useContext(BookingOverViewContext);

  return (
    <AlertCard
      color={colors.warmRed.shade1}
      title={translate(x => x.cancelled.title)}
      description={translate((x, format) =>
        format(x.cancelled.text, {
          date: dateToString(dateCancelled, langCode, true, true)
        })
      )}
    ></AlertCard>
  );
};

export default Cancelled;
