import React, { useContext } from "react";
import { LinkText } from "@hurtigruten/shared-components";
import { useTrans } from "i18n";
import useAlert from "api/alert/useAlert";
import { BookingOverViewContext } from "./BookingLoader";
import { getDateDifferenceInDays } from "utilities/time";
import AlertCard from "components/Card/AlertCard";

const Alert: React.FC = () => {
  const { translate, langCode } = useTrans();
  const alertState = useAlert(langCode);
  const { departureDate, cancelled } = useContext(BookingOverViewContext);

  if (!alertState.data) {
    return null;
  }

  const daysUntilDeparture = getDateDifferenceInDays(new Date(), departureDate);
  const showXDaysBeforeDeparture = parseInt(
    alertState.data.showXDaysBeforeDeparture
  );

  // Show alert if cancelled or close enough to departure
  if (
    (daysUntilDeparture === 0 ||
      daysUntilDeparture > showXDaysBeforeDeparture) &&
    !cancelled
  ) {
    return null;
  }

  return (
    <AlertCard
      title={alertState.data.title}
      description={alertState.data.description}
    >
      <LinkText href={alertState.data.url}>
        {translate(x => x.words.readMore)}
      </LinkText>
    </AlertCard>
  );
};

export default Alert;
