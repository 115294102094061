import React from "react";
import { RouteComponentProps, Redirect, Link } from "@reach/router";
import styled from "styled-components/macro";
import {
  AlertBox,
  BodyText,
  Header1,
  LinkText
} from "@hurtigruten/shared-components";

import LoginForm from "./LoginForm";
import useLogin from "api/login/useLogin";
import CenteredWhiteBox from "../../components/FullScreen/CenteredWhiteBox";
import useTrackPageView from "hooks/useTrackPageView";
import HeaderBar from "components/HeaderBar";
import FullscreenBackgroundImage from "components/FullScreen/FullscreenBackgroundImage";
import { spacings } from "styling/spacing";
import { Footer } from "components/Footer";
import { useTrans } from "i18n";

const ErrorHolder = styled.div`
  margin-bottom: ${spacings.four};
`;

const LoginPage: React.FC<RouteComponentProps> = () => {
  useTrackPageView("/", "LoginPage");
  const { translate, langCode } = useTrans();
  const loginState = useLogin(translate, langCode);

  if (loginState.redirect) {
    return <Redirect to={loginState.redirect} noThrow />;
  }

  const hasError = Boolean(loginState.errorMessage);

  return (
    <>
      <HeaderBar isLoggedIn={false} />
      <FullscreenBackgroundImage>
        <CenteredWhiteBox as="main">
          <Header1 marginBottom="two">
            {translate((x, format) => format(x.login.applicationName))}
          </Header1>
          <BodyText marginBottom="two">
            {translate(x => x.login.formExplanation)}
          </BodyText>
          <LoginForm
            action={loginState.handleSubmit}
            hasError={Boolean(loginState.errorMessage)}
            isLoading={loginState.isLoading}
          />
          {hasError && (
            <ErrorHolder>
              <AlertBox type="error">{loginState.errorMessage}</AlertBox>
            </ErrorHolder>
          )}
          <LinkText as={Link} to={`/${langCode}/help`}>
            {translate(x => x.login.button.help)}
          </LinkText>
        </CenteredWhiteBox>
      </FullscreenBackgroundImage>
      <Footer />
    </>
  );
};

export default LoginPage;
