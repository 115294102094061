import React, { useContext } from "react";
import Excursion from "models/Excursion";
import { useTrans } from "i18n";
import { BookingOverViewContext } from "../BookingLoader";
import {
  Header3,
  SecondaryButton,
  TabularText
} from "@hurtigruten/shared-components";
import { mapExcursionPassengersToName } from "utilities/mappers";
import { SeparatorLine } from "components/Grid";
import { dateToString } from "utilities/time";

interface ExcursionProps {
  excursion: Excursion;
}

const ExcursionDetails: React.FC<ExcursionProps> = ({ excursion }) => {
  const { translate, langCode } = useTrans();
  const { passengers } = useContext(BookingOverViewContext);
  const { passengers: passengersWithoutInformation, duration, url } = excursion;

  return (
    <>
      <Header3 marginBottom="four">{excursion.excursionName}</Header3>
      <TabularText>
        {translate((labels, format) =>
          format(
            excursion.passengers.length > 1
              ? labels.excursions.summary_plural
              : labels.excursions.summary,
            {
              count: excursion.passengers.length.toString(),
              date: dateToString(
                excursion.startDateTime,
                langCode,
                false,
                false
              )
            }
          )
        )}
      </TabularText>
      <SeparatorLine />
      <TabularText>{duration}</TabularText>
      <SeparatorLine />
      {mapExcursionPassengersToName(
        passengersWithoutInformation,
        passengers
      ).map(name => (
        <TabularText key={name}>{name}</TabularText>
      ))}
      <SecondaryButton
        as="a"
        href={url}
        size="condensed"
        target="_blank"
        marginTop="two"
      >
        {translate(x => x.excursions.links.moreInfo)}
      </SecondaryButton>
    </>
  );
};

export default ExcursionDetails;
