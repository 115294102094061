import useFetchData from "api/utils/useFetchData";
import { get } from "api/utils";
import { ApiResponse, Error } from "api/models";
import mapToApiResponse from "api/utils/mapToApiResponse";

interface ConsentValuesUrl {
  url: string;
}

const getConsentValuesUrl = async (): Promise<ApiResponse<
  ConsentValuesUrl
>> => {
  const response = await get(`/api/cookieconsent/url`);

  return mapToApiResponse<ConsentValuesUrl>({
    response: response,
    propertyToCheck: "url",
    fallBackErrorMessage:
      "Unexpected response type: Type did not match ConsentValues"
  });
};

const useConsentUrl = (): {
  data?: ConsentValuesUrl;
  isLoading: boolean;
  error?: Error;
} => {
  return useFetchData<ConsentValuesUrl, boolean>(getConsentValuesUrl, true);
};

export default useConsentUrl;
