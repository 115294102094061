import { ExcursionPassenger } from "models/Excursion";
import Passenger from "models/Passenger";

export const mapExcursionPassengersToName = (
  excursionPassengers: ExcursionPassenger[],
  passengers: Passenger[]
) => {
  return excursionPassengers.map(passenger => {
    let name = "";
    passengers.forEach(p => {
      if (p.passengerId === passenger.passengerId) {
        name = p.name;
      }
    });

    return name;
  });
};
