import { ApiResponse } from "../models";
import BookingOverviewData from "models/BookingOverviewData";
import { get } from "../utils";
import mapToApiResponse from "../utils/mapToApiResponse";
import mapBookingOverviewData from "./mapping";

export const getBooking = async (
  bookingId?: string
): Promise<ApiResponse<BookingOverviewData>> => {
  const response = await get(`/api/Booking/${bookingId}/BookingOverview`);

  return mapToApiResponse<BookingOverviewData>({
    response,
    propertyToCheck: "bookingId",
    fallBackErrorMessage:
      "Unexpected response type: Type did not match BookingOverviewData",
    mapFunction: mapBookingOverviewData
  });
};
