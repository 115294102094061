import React, { useContext } from "react";
import { useTrans } from "i18n";
import { BookingOverViewContext } from "../BookingLoader";
import LegElement from "./LegElement";
import { SeparatorLine, ExpandableBoxContentContainer } from "components/Grid";
import BookedServices from "./BookedServices";
import { localizedJoin } from "utilities/string";

const VoyageDetails: React.FC = () => {
  const { translate } = useTrans();
  const {
    flightIncluded,
    hotelIncluded,
    railIncluded,
    transferIncluded,
    flightLegs,
    voyages
  } = useContext(BookingOverViewContext);

  const flightLegsString =
    flightLegs.length > 0
      ? " (" + localizedJoin(flightLegs, translate, true) + ")"
      : "";

  const bookedServices = [
    flightIncluded ? translate(x => x.words.flight) + flightLegsString : "",
    hotelIncluded ? translate(x => x.words.hotel) : "",
    railIncluded ? translate(x => x.words.rail) : "",
    transferIncluded ? translate(x => x.words.transfer) : ""
  ].filter(elem => Boolean(elem));

  return (
    <ExpandableBoxContentContainer>
      {voyages.map((voyage, index) => {
        const key = voyage.fromPort.code + "-" + voyage.toPort.code;
        const heading =
          voyage?.editorialContent?.heading || voyage.packageDescription;

        return (
          <React.Fragment key={key}>
            <LegElement
              voyage={voyage}
              heading={heading}
              url={voyage?.editorialContent?.url}
            />
            {index !== voyages.length - 1 && <SeparatorLine />}
          </React.Fragment>
        );
      })}
      <BookedServices bookedServices={bookedServices} />
    </ExpandableBoxContentContainer>
  );
};

export default VoyageDetails;
