import React, { useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { Footer } from "components/Footer";
import Overview from "./Voyage";
import Guests from "./Guests";
import Cabins from "./Cabins";
import Upgrade from "./Upgrade";
import Vehicles from "./Vehicles";
import HeaderBar from "components/HeaderBar";
import ExcursionList from "./ExcursionList";
import useTrackPageView from "hooks/useTrackPageView";
import BookingLoader, { BookingOverViewContext } from "./BookingLoader";
import ErrorBoundary from "./ErrorBoundary";
import KeyInformation from "./KeyInformation";
import FakeBookingAlert from "./FakeBookingAlert";
import UpcomingVoyage from "./Guests/UpcomingVoyage";
import Alert from "./Alert";
import { spacings } from "styling/spacing";
import { media } from "utilities/media";
import hideOnPrint from "styling/print";
import useBookingMarketLanguage from "./useBookingMarketLanguage";
import WelcomeOnBoard from "./Ship/WelcomeOnBoard";
import Cancelled from "./Cancelled";
import { AboutVoyage, Questions } from "./Contact";
import { isActive } from "utilities/featureToggles";
import { useTrans } from "i18n";

const CenterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  ${hideOnPrint}
`;

interface FadedProps {
  faded?: boolean;
}

const Content = styled.div`
  margin: ${spacings.nine} 0 0;
  opacity: ${({ faded }: FadedProps) => (faded ? "0.5" : "1")};

  ${media.maxWidth.medium} {
    padding: 0 20px;
  }
`;

const BoxGrid = styled(CenterContent)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  ${media.maxWidth.medium} {
    flex-direction: column;
    align-items: unset;
  }

  ${hideOnPrint}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.maxWidth.medium} {
    flex-direction: column;
  }

  & > * {
    width: calc(100% / 3 - 30px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    ${media.maxWidth.medium} {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const FullScreenContainer = styled.main`
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-bottom: ${spacings.eight};
`;

interface RouteProps extends RouteComponentProps {
  encryptedId?: string;
}

// This hook needs to be a children of BookingLoader. Otherwise the context will not be updated.
const RedirectToBookingLanguage: React.FC = () => {
  useBookingMarketLanguage();
  return null;
};

const Page: React.FC = () => {
  const { cancelled, isQuotation } = useContext(BookingOverViewContext);
  const { langCode } = useTrans();
  useTrackPageView("/booking", "BookingPage");

  return (
    <>
      <RedirectToBookingLanguage />
      <FullScreenContainer>
        <HeaderBar isLoggedIn={true} />
        <FakeBookingAlert />
        <Content>
          <BoxGrid>
            {cancelled && <Cancelled />}
            <Alert />
          </BoxGrid>
        </Content>
        <Content faded={cancelled}>
          <BoxGrid>
            <UpcomingVoyage />
            {isQuotation && <KeyInformation />}
            <Guests />
            {!cancelled && !isQuotation && <KeyInformation />}
            <Overview />
            <Vehicles />
            <Cabins />
            {isActive("showPlatinumBanner", langCode) && <Upgrade />}
          </BoxGrid>
          <CenterContent hideOnPrint={true}>
            <ExcursionList />
          </CenterContent>
          <BoxGrid hideOnPrint={true}>
            <Row>
              <WelcomeOnBoard />
              <AboutVoyage />
              <Questions />
            </Row>
          </BoxGrid>
        </Content>
      </FullScreenContainer>
      <Footer hideOnPrint={true} />
    </>
  );
};

const BookingPage: React.FC<RouteProps> = ({ encryptedId = "" }) => {
  return (
    <ErrorBoundary>
      <BookingLoader encryptedId={encryptedId}>
        <Page />
      </BookingLoader>
    </ErrorBoundary>
  );
};

export default BookingPage;
