import styled from "styled-components/macro";
import { LinkText } from "@hurtigruten/shared-components";
import { media } from "utilities/media";
import { spacings, MarginProps, spacingBottom } from "./spacing";

interface TextSizes {
  micro: string;
  small: string;
  normal: string;
  medium: string;
  large: string;
  xLarge: string;
}

const textSizes: TextSizes = {
  micro: "12px",
  small: "14px",
  normal: "16px",
  medium: "18px",
  large: "20px",
  xLarge: "24px"
};

type AvailableSizes = keyof TextSizes;

export const LinkTextColored = styled(LinkText)`
  color: inherit;
`;

export const Header = styled.h1`
  font-family: Austin;
  font-size: 48px;
  line-height: 120%;
  margin-top: 0;
  margin-bottom: ${spacings.two};

  ${media.maxWidth.medium} {
    font-size: 36px;
  }
`;

export const SecondaryHeader = styled.h2`
  font-family: Austin;
  font-size: 36px;
  line-height: 120%;
  margin: 0;
  margin-bottom: ${spacings.two};
`;

interface TextProps extends MarginProps {
  bold?: boolean;
  size?: AvailableSizes;
  color?: string;
  textAlign?: "center" | "left" | "right";
}

export const TitleLyon = styled.h2`
  font-family: Lyon Text;
  font-weight: ${({ bold }: TextProps) => (bold ? "600" : "400")};
  font-size: 28px;
  line-height: 30px;
  margin: 0;
  text-align: ${({ textAlign = "left" }: TextProps) => textAlign};
  color: #000000;
  ${spacingBottom}
`;

export const Text = styled.p`
  font-family: Atlas Grotesk;
  font-weight: ${({ bold }: TextProps) => (bold ? "500" : "400")};
  font-size: ${({ size = "normal" }: TextProps) => textSizes[size]};
  color: ${({ color = "black" }: TextProps) => color};
  line-height: 130%;
  margin: 0;
  ${spacingBottom};
`;

export const UppercaseLabel = styled.h3`
  font-family: Atlas Grotesk;
  font-size: ${textSizes.micro};
  text-transform: uppercase;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  margin-bottom: ${spacings.half};
`;
