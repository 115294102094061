import React, { useContext } from "react";
import { useTrans } from "i18n";

import { BookingOverViewContext } from "../BookingLoader";
import { FullWidth, SeparatorLine } from "components/Grid";
import { VehiclesDetails } from "./VehiclesDetails";
import { ExpandableBox } from "@hurtigruten/shared-components";

const Vehicles: React.FC = () => {
  const { translate } = useTrans();
  const { voyages } = useContext(BookingOverViewContext);

  const numberOfVehicles = voyages.reduce((sum, voyage) => {
    let numberOfVehiclesInVoyage = 0;

    if (voyage.vehicles) {
      numberOfVehiclesInVoyage = voyage.vehicles.length;
    }
    return sum + numberOfVehiclesInVoyage;
  }, 0);

  if (numberOfVehicles < 1) {
    return null;
  }

  return (
    <FullWidth>
      <ExpandableBox
        id="vehicles"
        defaultExpanded={false}
        heading={translate(x =>
          numberOfVehicles > 1 ? x.labels.vehicle_plural : x.labels.vehicle
        )}
      >
        <SeparatorLine />
        <VehiclesDetails />
      </ExpandableBox>
    </FullWidth>
  );
};

export default Vehicles;
