import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "@hurtigruten/shared-components";
import { ConsentTexts } from "api/consent/useConsentTexts";

import { List, Link } from "./styles";
import { Consents } from ".";
import { useTrans } from "i18n";
import { Text } from "styling/text";

const ConsentLabel = styled.p`
  margin: 0;
  margin-left: 44px;
  font-family: Atlas Grotesk;
`;

const ConsentText = styled(Text)`
  margin-left: 44px;
  margin-top: 5px;
`;

interface MoreInfoProps {
  texts: ConsentTexts;
  consentValues: Consents;
  setConsentValues: React.Dispatch<React.SetStateAction<Consents>>;
}

const MoreInfo: React.FC<MoreInfoProps> = ({
  texts,
  consentValues,
  setConsentValues
}) => {
  const { translate } = useTrans();

  return (
    <>
      <List>
        <li>
          <ConsentLabel>{texts.consentGeneralName}</ConsentLabel>
          <ConsentText size="small" marginBottom="two">
            {texts.consentGeneralDescription}
          </ConsentText>
        </li>
        <li>
          <Checkbox
            label={texts.consentMarketingName}
            value={consentValues.general.toString()}
            defaultChecked={consentValues.marketing}
            onChange={event => {
              setConsentValues({
                ...consentValues,
                marketing: event.target.value === "false"
              });
            }}
          />
          <ConsentText size="small" marginBottom="two">
            {texts.consentMarketingDescription}
          </ConsentText>
        </li>
      </List>
      <Link href={texts.url} target="_blank">
        {translate(x => x.consents.button.cookieLinkText)}
      </Link>
    </>
  );
};

export default MoreInfo;
