import React, { useContext } from "react";
import { useTrans } from "i18n";
import { TabularHeader, TabularText } from "@hurtigruten/shared-components";
import { Voyage } from "models/BookingOverviewData";
import { BookingOverViewContext } from "../BookingLoader";
import VehiclesElement from "./VehiclesElement";
import { Info, SeparatorLine, Columns } from "components/Grid";

interface LegDetailsProps {
  voyage: Voyage;
  showSeparatorLine: boolean;
  isMultipleLegs: boolean;
}

const LegDetails: React.FC<LegDetailsProps> = ({
  voyage,
  showSeparatorLine,
  isMultipleLegs
}) => {
  const { translate } = useTrans();
  const { vehicles, fromPort, toPort } = voyage;

  if (!vehicles || vehicles.length < 1) {
    // Show nothing if there is no vehicles
    return null;
  }

  const showFromTo = isMultipleLegs && fromPort.name && toPort.name;

  if (!showFromTo) {
    return <VehiclesElement vehicles={vehicles} showLabel={false} />;
  }

  return (
    <>
      <Columns>
        <Info>
          <TabularHeader marginBottom="one">
            {translate(x => x.labels.voyage)}
          </TabularHeader>
          <TabularText as="h4">
            {`${fromPort.name} - ${toPort.name}`}
          </TabularText>
        </Info>
        <VehiclesElement vehicles={vehicles} />
      </Columns>
      {showSeparatorLine && <SeparatorLine />}
    </>
  );
};

export const VehiclesDetails: React.FC = () => {
  const { voyages } = useContext(BookingOverViewContext);

  return (
    <>
      {voyages.map((voyage, index) => (
        <LegDetails
          voyage={voyage}
          key={voyage.fromPort.code}
          showSeparatorLine={index !== voyages.length - 1}
          isMultipleLegs={voyages.length > 1}
        ></LegDetails>
      ))}
    </>
  );
};
