import { ConsentValues } from "./useConsentValues";
import { getCookie, setCookie, eraseCookie } from "utilities/cookies";
import { useEffect } from "react";

const useLocalConsentValues = (globalConsents?: ConsentValues) => {
  useEffect(() => {
    if (globalConsents) {
      const localGeneral = getCookie("HRG_Consent_General") === "true";
      const localMarketing = getCookie("HRG_Consent_Marketing") === "true";

      if (
        globalConsents.general !== localGeneral ||
        globalConsents.marketing !== localMarketing
      ) {
        // Update local cookies
        eraseCookie("HRG_Consent_General");
        eraseCookie("HRG_Consent_Marketing");

        setCookie("HRG_Consent_General", globalConsents.general.toString());
        setCookie("HRG_Consent_Marketing", globalConsents.marketing.toString());

        //GTM handles consents. So we trigger an event after consents have been updated
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "cookieConsent",
            nonInteraction: true
          });
        }
      }
    }
  }, [globalConsents]);
};

export default useLocalConsentValues;
