export const routeSupportsPlatinumUpgrade = (
  fromPort: string,
  toPort: string
) => {
  if (fromPort === "BGO" && toPort === "BGO") return true;
  if (fromPort === "BGO" && toPort === "TRD") return true;
  if (fromPort === "BGO" && toPort === "KNN") return true;
  if (fromPort === "KNN" && toPort === "BGO") return true;
  return false;
};
