import React, { useContext } from "react";
import GuestsDetails from "./GuestsDetails";
import { useTrans } from "i18n";
import { BookingOverViewContext } from "../BookingLoader";
import { ExpandableBox } from "@hurtigruten/shared-components";
import { FullWidth } from "components/Grid";

const Guests: React.FC = () => {
  const { translate } = useTrans();
  const { passengers } = useContext(BookingOverViewContext);

  const passengersNames = passengers.map(passenger => {
    return { content: passenger.name };
  });

  return (
    <FullWidth>
      <ExpandableBox
        id={"guests"}
        heading={translate(x => x.whosTraveling.title)}
        summaryTags={passengersNames}
        defaultExpanded={true}
      >
        <GuestsDetails />
      </ExpandableBox>
    </FullWidth>
  );
};

export default Guests;
