import { LanguageResource } from "i18n/models/resources";

const swedish: LanguageResource = {
  cancelled: {
    title: "Booking cancelled",
    text: "Unfortunately, your booking was cancelled {{date}}"
  },
  error: {
    notsupported: {
      title: "Tyvärr!",
      secondaryTitle: "Det verkar som om din bokning inte är tillgänglig.",
      description:
        "Om du har bokat din resa genom en resebyrå är My Booking tyvärr inte tillgänglig för dig ännu.",
      helpText:
        "Om så inte är fallet, kontakta oss gärna för ytterligare hjälp.",
      button: "Logga in"
    },
    general: {
      title: "Tyvärr!",
      secondaryTitle: "Det fungerade inte.",
      description:
        "Det kan vara något fel med länken du använder. Logga in igen.",
      helpText:
        "Om du fortsätter att se det här felet kanske My Booking inte fungerar korrekt. Kontakta oss gärna för ytterligare hjälp.",
      button: "Logga in"
    },
    bookingCancelled: {
      title: "Tyvärr!",
      secondaryTitle: "Det ser ut som om det här bokningsnumret har ändrats.",
      description:
        "Om du har ett nytt bokningsnummer, använd detta för att logga in och se din uppdaterade bokningsinformation.",
      helpText:
        "Om så inte är fallet, kontakta oss gärna för ytterligare hjälp.",
      button: "Logga in"
    }
  },
  login: {
    applicationName: "My Booking",
    description: "Se din bokning och förbered dig för ditt äventyr",
    formExplanation:
      "Använd dina bokningsuppgifter för att få en överblick över din resa.",
    button: { toMyBooking: "Till My Booking", help: "Har du frågor?" },
    errorMessage: {
      unauthorized:
        "Tyvärr ser det ut som bokningsnumret, e-postadressen för bokningen och/eller det fullständiga namnet är felaktigt. Se till att e-postadressen för bokningen och det fullständiga namnet tillhör personen som bokat resan.",
      systemDown: "Tyvärr verkar våra system ligga nere. Försök igen senare.",
      default: "Något gick fel. Försök igen senare eller kontakta kundtjänst.",
      bookingRef: "Dette feltet skal kun inneholde tall."
    }
  },
  faq: {
    title: "Hur kan vi hjälpa dig?",
    description: "Nedan hittar du svar på de vanligaste frågorna.",
    backLink: "Tillbaka till inloggning",
    questions: {
      about: "Vad är My Booking?",
      credentials: "Var hittar jag informationen som används för att logga in?",
      content: "Vad hittar jag i My Booking?",
      cancelled: "Har jag åtkomst till en avbokad bokning?",
      agency: "Har jag åtkomst till en bokning som gjorts via en resebyrå?",
      otherBookings: "Kan jag se mina andra bokningar i My Booking?",
      cancel: "Kan jag se mina andra bokningar i My Booking?",
      misspelled: "Mitt namn är felstavat, vad bör jag göra?"
    },
    answers: {
      about:
        "Vi åtar oss att ge dig den bästa servicen hela vägen från bokning till säker hemkomst från en av våra kryssningar. My Booking är en webbtjänst där du hittar viktig information om din bokning.",
      credentials:
        "Använd bokningsreferensnumret (som anges på fakturan), e-postadress och fullständigt namn på den person som har bokat (kallas även huvudpassageraren).",
      content:
        "Få en snabb översikt över dina reseuppgifter. Se dina avgångs- och ankomstdatum, bokade utflykter och all transport relaterad till din resa. Granska information om gäst, hytt och betalning. Läs mer om din resplan och bekanta dig med fartyget innan ditt kommande äventyr.",
      cancelled:
        "Nej, bokningar som gjorts genom resebyråer stöds för närvarande inte.",
      agency:
        "Nej, bokningar som gjorts genom resebyråer stöds för närvarande inte.",
      otherBookings:
        "My Booking är en lösning där du kan få information om en enskild bokning.",
      cancel:
        "Om du vill avboka din bokning ska du skicka ett e-postmeddelande till oss så tar vi hand om det.",
      misspelled:
        "Om ditt namn är felstavat ber vi dig att kontakta oss omedelbart."
    }
  },
  contact: {
    contactUs: "Kontakta oss",
    stillGotQuestions: "Har du fortfarande frågor?",
    pleaseContactUsPhrase:
      "Du är en av de första resenärerna som använder My Booking. Vi gör allt vi kan för att stå till tjänst, men vissa uppgifter kan fortfarande saknas i din bokningsöversikt. Hör gärna av dig om du har några frågor."
  },
  feedback: {
    title: "Vill du hjälpa oss att förbättra My Booking?",
    placeholder:
      "Tack för att du hjälper oss att förbättra My Booking! Vad skulle du vilja se ändras eller förbättras?",
    disclaimer:
      "Observera att din förfrågan inte kommer att besvaras. Om du har frågor eller frågor angående en specifik resa, vänligen kontakta kundtjänst.",
    buttonText: "Skicka feedback"
  },
  aboutVoyage: {
    title: "Få hjälp med din resa",
    description: "Vi hjälper gärna till",
    mailto: "Skicka e-post till oss"
  },
  thankyou: {
    title: "Tack!",
    description:
      "Din feedback har skickats. Vi kommer att beakta den för framtida förbättringar av My Booking.",
    button: "Give more feedback"
  },
  questionsTile: {
    title: "Har du några frågor?",
    description:
      "Innan du kontaktar oss får du gärna läsa våra vanliga frågor (FAQ).",
    linkText: "Läs vanliga frågor"
  },
  bookingOverview: {
    links: {
      learnMoreAboutYourVoyage: "Läs mer om din resa",
      managePayment: "Hantera dina betalningar",
      manageQuotePayment: "Betal og bestil"
    },
    detailedInformation:
      "Info: Dina biljetter och detaljerad information om resplan och flyg skickas via e-post 2–4 veckor innan din resa börjar."
  },
  paymentStatus: {
    title: "Översikt och betalning",
    paymentStatus: "Betalningsstatus",
    partlyPaid: "Delvis betald",
    fullyPaid: "Fullt betald",
    noPaymentReceived: "Ingen betalning mottagen",
    due: "Förfaller",
    depositDue: "Deposition förfaller",
    pendingPayment: "Afventer betaling"
  },
  cabins: {
    header: "Mina hytter",
    notYetAvailable: "Ännu inte tillgängliga",
    facilities: {
      window: {
        pothole: "Hyttventil",
        window: "Fönster",
        windowLimitedView: "Fönster med begränsad utsikt",
        noInformation: ""
      },
      bed: {
        doubleBed: "Dubbelsäng",
        twinBed: "Tvåbäddsrum",
        oneBedOneCouch: "1 säng/1 soffa",
        bunkBeds: "Våningssängar",
        beds: "Sängar",
        mixed: "Blandat",
        noInformation: ""
      },
      bathroom: "Badrum",
      balcony: "Balkong",
      sofa: "Soffa",
      tv: "TV",
      dinnerTable: "Matbord"
    },
    cabinGrade: "Hyttkategori"
  },
  excursions: {
    yourExcursions: "Mina utflykter",
    othersExcursions: "Excursions booked by others in your group",
    links: {
      moreInfo: "Mer info",
      viewMore: "Be om fler utflykter"
    },
    customize: "Anpassa din resa!",
    loveToHelp: "Vi vill gärna hjälpa dig att få ut det mesta av din resa",
    summary: "{{date}} för {{count}} person",
    summary_plural: "{{date}} för {{count}} personer",
    information: {
      noExcursions: "Du har inte förbokat några utflykter ännu.",
      moreExcursions:
        "Vissa utflykter i samband med denna resa kanske inte är redo för bokning förrän 6–9 månader innan resan börjar."
    }
  },
  logout: {
    logout: "Logga ut"
  },
  consents: {
    button: {
      accept: "Jag förstår",
      cookieLinkText: "Mer information om cookies"
    }
  },
  meals: {
    bookedMeals: "Beställda måltider"
  },
  words: {
    minute: "minut",
    minute_plural: "minuter",
    hour: "timme",
    hour_plural: "timmar",
    day: "dag",
    day_plural: "dagar",
    cabin: "hytt",
    cabin_plural: "hytter",
    excursion: "utflykt",
    excursion_plural: "utflykter",
    person: "person",
    person_plural: "personer",
    car: "bil",
    car_plural: "bilar",
    motorcycle: "motocykel",
    motorcycle_plural: "motorcyklar",
    flight: "Fly",
    hotel: "Hotell",
    rail: "Tåg",
    transfer: "Transfer",
    breakfast: "frukost",
    lunch: "lunch",
    dinner: "middag",
    and: "och",
    map: "Karta",
    yes: "Ja",
    no: "Nej",
    add: "Add",
    edit: "Edit",
    readMore: "Läs mer",
    arrival: "Ankomst",
    departure: "Avresa",
    stop: "stopp",
    stop_plural: "stopp"
  },
  labels: {
    facilities: "Bekvämligheter",
    category: "Kategori",
    guests: "Gäster",
    leadPassenger: "Huvudresenär",
    membershipLevel: "Medlemsnivå",
    date: "Datum",
    voyage: "Resa",
    ship: "Fartyg",
    included: "Inkluderad",
    bookedServices: "Bokade tjänster",
    travelPeriod: "Reseperiod",
    sailingPeriod: "Seglingsperiod",
    duration: "Längd",
    bookingReferenceNumber: "Bokningsreferens",
    quoteReferenceNumber: "Tilbud",
    quoteValidUntil: "Detta erbjudande gäller till",
    quoteDisclaimer:
      "Om ingen betalning görs före den tidpunkten kommer ditt erbjudande automatiskt att annulleras. Vänligen läs beställningsinformationen noggrant och kontakta oss om du vill ändra någon av detaljerna. Om du vill omvandla ditt erbjudande till en beställning måste du betala.",
    bookingEmail: "E-postbokning",
    fullName: "Fullständigt namn",
    cabinNumber: "Hyttnummer",
    vehicle: "Fordon",
    vehicle_plural: "Fordon",
    fromTo: "Från - till",
    passengerInfo: "Passageraruppgifter",
    travellingWith: "Reser med",
    exploring: "Upptäcktsresa",
    total: "Totalt",
    perPerson: "Per person"
  },
  alternativeTexts: {
    excursionImage: "Utflyktsbild"
  },
  warning: {
    departureTime: "Vänligen notera att din resa avgår efter midnatt."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "Edit email",
    close: "Close",
    updateButtonText: "Update Email",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Show link",
    hideLink: "Hide access link",
    copy: "Copy",
    close: "close",
    description: "Med denne lenken kan {{name}} logge seg inn"
  },
  whosTraveling: {
    title: "Vem reser?"
  },
  upcomingVoyage: {
    voyageDateDeteails: `Resan börjar {{startDate}}, slutar {{endDate}}`
  },
  footer: {
    termsAndConditions: "Användarvillkor",
    statementOfPrivacy: "Sekretesspolicy",
    cookies: "Cookies",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "Läs mer om platinum-biljetter",
    button: "Begär platinum",
    loading: "Loading",
    requestSuccess:
      "Din förfrågan har skickats. Vi kontaktar dig med mer information.",
    requestError:
      "Det verkar som om något gick fel. Försök igen. Eller kontakta oss via telefon eller e-post.",
    upgradeInitiated:
      "Tack för att du uppgraderar till Platinum. Vi kommer att kontakta dig med mer information snart.",
    upgradeInProgress:
      "Vi förbereder oss för att skicka ett e-postmeddelande till dig om din Platinum-uppgradering.",
    upgradeDeclined:
      "Vi har inte fått din bekräftelse, så vi har inte kunnat uppgradera dig till Platinum. Kontakta oss om du bestämmer dig för att uppgradera.",
    upgradeApproved:
      "Grattis – du har framgångsrikt uppgraderats till Platinum!"
  }
};

export default swedish;
