export interface Error {
  errorCode: number;
  errorMessage: string;
}

export enum ResponseStatus {
  "SUCCESS",
  "ERROR"
}

export interface SimpleResponse {
  status: ResponseStatus;
}

export interface ErrorResponse extends SimpleResponse {
  error: Error;
}

export interface LoadedTypeResponse<T> extends SimpleResponse {
  data: T;
}

export type ApiResponse<T> = LoadedTypeResponse<T> | ErrorResponse;
