import React from "react";
import styled from "styled-components/macro";
import { useTrans } from "i18n";

import { CabinGrade } from "models/Cabin";
import WindowIcon from "icons/rawIcons/window.svg";
import CheckIcon from "icons/rawIcons/check.svg";
import CabinSizeIcon from "icons/rawIcons/cabin-size.svg";
import FeatureItem from "components/FeatureItem";
import { Window, Bed } from "i18n/models/resources";
import { TabularText } from "@hurtigruten/shared-components";

const FacilitiesFeature = styled(TabularText)`
  margin-left: 10px;
  display: inline-block;
  vertical-align: top;
`;

const getWindowString = (windowStrings: Window, windowType: string) => {
  switch (windowType) {
    case "Window":
      return windowStrings.window;
    case "Pothole":
      return windowStrings.pothole;
    case "WindowLimitedView":
      return windowStrings.windowLimitedView;
    case "NoInformation":
    default:
      return windowStrings.noInformation;
  }
};

const getBedString = (bedStrings: Bed, bedType: string) => {
  switch (bedType) {
    case "DoubleBed":
      return bedStrings.doubleBed;
    case "TwinBed":
      return bedStrings.twinBed;
    case "OneBedOneCouch":
      return bedStrings.oneBedOneCouch;
    case "BunkBeds":
      return bedStrings.bunkBeds;
    case "Beds":
      return bedStrings.beds;
    case "Mixed":
      return bedStrings.mixed;
    case "NoInformation":
    default:
      return bedStrings.noInformation;
  }
};

interface Props {
  cabinGrade: CabinGrade;
}

const Facilities: React.FC<Props> = ({ cabinGrade }) => {
  const { translate } = useTrans();

  return (
    <>
      <FeatureItem icon={CabinSizeIcon} conditionalValue={true}>
        <FacilitiesFeature>
          {cabinGrade.sizeFrom}-{cabinGrade.sizeTo} m<sup>2</sup>
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem
        icon={WindowIcon}
        conditionalValue={Boolean(
          translate(x =>
            getWindowString(x.cabins.facilities.window, cabinGrade.window)
          )
        )}
      >
        <FacilitiesFeature>
          {translate(x =>
            getWindowString(x.cabins.facilities.window, cabinGrade.window)
          )}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem
        icon={CheckIcon}
        conditionalValue={Boolean(
          translate(x => getBedString(x.cabins.facilities.bed, cabinGrade.bed))
        )}
      >
        <FacilitiesFeature>
          {translate(x =>
            getBedString(x.cabins.facilities.bed, cabinGrade.bed)
          )}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem icon={CheckIcon} conditionalValue={cabinGrade.hasBathroom}>
        <FacilitiesFeature>
          {translate(x => x.cabins.facilities.bathroom)}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem icon={CheckIcon} conditionalValue={cabinGrade.hasBalcony}>
        <FacilitiesFeature>
          {translate(x => x.cabins.facilities.balcony)}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem icon={CheckIcon} conditionalValue={cabinGrade.hasSofa}>
        <FacilitiesFeature>
          {translate(x => x.cabins.facilities.sofa)}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem icon={CheckIcon} conditionalValue={cabinGrade.hasTv}>
        <FacilitiesFeature>
          {translate(x => x.cabins.facilities.tv)}
        </FacilitiesFeature>
      </FeatureItem>
      <FeatureItem
        icon={CheckIcon}
        conditionalValue={cabinGrade.hasDinnerTable}
      >
        <FacilitiesFeature>
          {translate(x => x.cabins.facilities.dinnerTable)}
        </FacilitiesFeature>
      </FeatureItem>
    </>
  );
};

export default Facilities;
