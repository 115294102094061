import React from "react";
import { useTrans } from "i18n";
import styled from "styled-components/macro";

import Passenger from "models/Passenger";
import { Info } from "components/Grid";
import BookedMeal from "./BookedMeal";
import { getMealStringForAllVoyages } from "./helper";
import { Voyage } from "models/BookingOverviewData";
import { RouteComponentProps } from "@reach/router";
import GuestAccessLink from "./GuestAccessLink";

import { isActive } from "utilities/featureToggles";
import { spacings } from "styling/spacing";
import { TabularHeader, TabularText } from "@hurtigruten/shared-components";

const FixedWidth = styled(Info)`
  flex: unset;
  width: 292px;
  margin-bottom: ${spacings.three};
`;

interface GuestProps extends RouteComponentProps {
  passenger: Passenger;
  voyages: Voyage[];
  loggedInPassengerIsLead?: boolean;
}

const Guest: React.FC<GuestProps> = ({
  passenger,
  voyages,
  loggedInPassengerIsLead = false
}) => {
  const { translate, langCode } = useTrans();
  const meals = getMealStringForAllVoyages(
    translate,
    voyages,
    passenger.passengerId
  );

  const accessLinkActive = isActive("accessLink", langCode);
  const membershipLevelActive = isActive("membershipLevel", langCode);

  return (
    <FixedWidth>
      <TabularHeader marginBottom="two">{passenger.name}</TabularHeader>
      {passenger.isLead && (
        <TabularText>{translate(x => x.labels.leadPassenger)}</TabularText>
      )}
      {membershipLevelActive && passenger.ambassadorLevel && (
        <TabularText>
          {translate(x => x.labels.membershipLevel)}:{" "}
          {passenger.ambassadorLevel}
        </TabularText>
      )}

      {passenger.email && <TabularText>{passenger.email}</TabularText>}
      {meals.length > 0 &&
        meals.map(meal => (
          <BookedMeal
            meal={meal}
            showVoyageDescription={meals.length > 1}
            key={meal.voyage}
          />
        ))}
      {loggedInPassengerIsLead && accessLinkActive && (
        <GuestAccessLink passenger={passenger} />
      )}
    </FixedWidth>
  );
};

export default Guest;
