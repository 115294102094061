import { LanguageResource } from "i18n/models/resources";

const english: LanguageResource = {
  cancelled: {
    title: "Booking cancelled",
    text: "Unfortunately, your booking was cancelled {{date}}"
  },
  error: {
    // Error pages
    notsupported: {
      // This booking is not supported by the solution
      title: "Sorry!",
      secondaryTitle: "It seems like your booking is not available.",
      description:
        "If you have booked your voyage through a travel agent, My Booking is unfortunately not available to you yet.",
      helpText: "If this is not the case, please contact us for further help.",
      button: "Sign in"
    },
    general: {
      // General error. For instance if the unique url is not valid
      title: "Sorry!",
      secondaryTitle: "That didn't work.",
      description:
        "There might be something wrong with the link you are using. Please sign in again.",
      helpText:
        "If you keep seeing this error, My Booking may not be working properly. Please contact us for further help.",
      button: "Sign in"
    },
    bookingCancelled: {
      // If the booking is cancelled in Polar Global
      title: "Sorry!",
      secondaryTitle: "Looks like this booking number has been changed.",
      description:
        "If you have a new booking number, use this to sign in and see your updated booking information.",
      helpText: "If this is not the case, please contact us for further help.",
      button: "Sign in"
    }
  },
  login: {
    // Login page
    applicationName: "My Booking",
    description: "View your booking and prepare for your adventure",
    formExplanation:
      "Use your booking details to get an overview of your voyage.",
    button: {
      toMyBooking: "To My Booking",
      help: "Have any questions?"
    },
    errorMessage: {
      unauthorized:
        "Sorry, looks like the booking number, booking email and/or full name is incorrect. Make sure the email and full name is of the person who booked the voyage.",
      systemDown:
        "Sorry, our systems seems to be down. Please try again later.",
      default:
        "Something went wrong. Please try again later or contact customer service.",
      bookingRef: "This field can only contain numbers."
    }
  },
  faq: {
    // FAQ page
    title: "How can we help you?",
    description:
      "Below you'll find answer to the questions we get asked the most.",
    backLink: "Back to log in",
    questions: {
      about: "What is My Booking?",
      credentials: "Where do I find the information used to log in?",
      content: "What do I find in My Booking?",
      cancelled: "Can I access a cancelled booking?",
      agency: "Can I access a booking made through a travel agency?",
      otherBookings: "Can I see my other bookings in My Booking?",
      cancel: "I'd like to cancel my voyage, what should I do?",
      misspelled: "My name is spelled wrong, what should I do?"
    },
    answers: {
      about:
        "We are committed to offer you the best service all the way from your booking, to your safe return from one of our cruises. My Booking is a web service where you can find key information about your booking.",
      credentials:
        "Use the booking reference number (as stated on the invoice), email address and full name of the person who booked (also known as the lead passenger).",
      content:
        "Get a quick overview of your voyage details. See your departure and arrival dates, booked excursions and any transportation related to your voyage. Review guest, cabin and payment information. Read more about your itinerary and get to know the ship before your upcoming adventure.",
      cancelled:
        "No, if you enter the credentials of a cancelled booking you will not be able to access it.",
      agency:
        "No, bookings made through travel agencies are currently not supported.",
      otherBookings:
        "My Booking is a solution where you can access information about a single booking.",
      cancel:
        "If you want to cancel your booking you should send us an email and we'll handle it for you.",
      misspelled:
        "If your name is spelled wrong, please contact us immediately."
    }
  },
  contact: {
    contactUs: "Contact us",
    stillGotQuestions: "Still got questions?",
    pleaseContactUsPhrase:
      "Don’t hesitate to contact us, we’ll be happy to help you."
  },
  aboutVoyage: {
    title: "Get help with your voyage",
    description: "We’re more than happy to assist you",
    mailto: "Send us an email"
  },
  feedback: {
    title: "Do you want to help us improve My Booking?",
    placeholder:
      "Thanks for helping us make My Booking better! What would you like to see changed or improved?",
    disclaimer:
      "Please note that the feedback will not be answered. If you have questions or inquiries regarding a specific voyage, please contact customer service.",
    buttonText: "Send feedback"
  },
  thankyou: {
    title: "Thank you!",
    description:
      "Your feedback was successfully submitted. We will take it into account for future improvements of My Booking.",
    button: "Give more feedback"
  },
  questionsTile: {
    title: "Have any questions?",
    description:
      "Before you get in touch with us, why not check out our frequently asked questions (FAQ)?",
    linkText: "Read FAQ"
  },
  bookingOverview: {
    // Booking overview block that contains the most relevant information about your voyage
    links: {
      learnMoreAboutYourVoyage: "Learn more about your voyage",
      managePayment: "Manage your payments",
      manageQuotePayment: "Pay and book here"
    },
    detailedInformation:
      "Info: Your tickets and specific information about your itinerary and flights will be sent by email 2-4 weeks before your voyage starts."
  },
  paymentStatus: {
    title: "Summary and payment",
    paymentStatus: "Payment status",
    partlyPaid: "Partly paid",
    fullyPaid: "Fully paid",
    noPaymentReceived: "No payment received",
    due: "Due",
    depositDue: "Deposit due",
    pendingPayment: "Pending payment"
  },
  cabins: {
    // Cabin block with information about the cabin for each passenger
    header: "Cabins",
    notYetAvailable: "Not yet available",
    facilities: {
      // Facilities available in the cabin
      window: {
        pothole: "Pothole",
        window: "Window",
        windowLimitedView: "Window with limited view",
        noInformation: "" // Should be empty
      },
      bed: {
        doubleBed: "Double bed",
        twinBed: "Twin bed",
        oneBedOneCouch: "1 bed/1 couch",
        bunkBeds: "Bunk beds",
        beds: "Beds",
        mixed: "Mixed",
        noInformation: "" // Should be empty
      },
      bathroom: "Bathroom",
      balcony: "Balcony",
      sofa: "Sofa",
      tv: "TV",
      dinnerTable: "Dinner table"
    },
    cabinGrade: "Cabin grade"
  },
  excursions: {
    // Excursion block with information about the excursions you have booked
    yourExcursions: "Your booked excursions",
    othersExcursions: "Additional excursions in your booking",
    links: {
      moreInfo: "More info",
      viewMore: "View more excursions"
    },
    customize: "Customize your voyage!",
    loveToHelp: "We’d love to help you get the most out of your trip.",
    summary: "{{date}} for {{count}} person",
    summary_plural: "{{date}} for {{count}} people",
    information: {
      noExcursions: "You have not pre-booked any excursions yet.",
      moreExcursions:
        "Some excursions related to this voyage may not be ready for booking until 6-9 months before the voyage starts."
    }
  },
  logout: {
    logout: "Log out"
  },
  consents: {
    button: {
      accept: "I understand",
      cookieLinkText: "More information about cookies"
    }
  },
  meals: {
    bookedMeals: "Booked meals"
  },
  words: {
    // Words for general use
    minute: "minute",
    minute_plural: "minutes",
    day: "day",
    day_plural: "days",
    cabin: "cabin",
    cabin_plural: "cabins",
    excursion: "excursion",
    excursion_plural: "excursions",
    hour: "hour",
    hour_plural: "hours",
    person: "person",
    person_plural: "people",
    car: "car",
    car_plural: "cars",
    motorcycle: "motorcycle",
    motorcycle_plural: "motorcycles",
    flight: "Flight",
    hotel: "Hotel",
    rail: "Rail",
    transfer: "Transfer",
    breakfast: "breakfast",
    lunch: "lunch",
    dinner: "dinner",
    and: "and",
    map: "Map",
    yes: "Yes",
    no: "No",
    edit: "Edit",
    add: "Add",
    readMore: "Read more",
    arrival: "Arrival",
    departure: "Departure",
    stop: "stop",
    stop_plural: "stops"
  },
  labels: {
    facilities: "Facilities",
    category: "Category",
    guests: "Guests",
    leadPassenger: "Lead passenger",
    membershipLevel: "Membership level",
    date: "Date",
    voyage: "Voyage",
    ship: "Ship",
    included: "Included",
    bookedServices: "Booked services",
    travelPeriod: "Travel period",
    sailingPeriod: "Sailing Period",
    duration: "Duration",
    bookingReferenceNumber: "Booking reference number",
    quoteReferenceNumber: "Quote",
    quoteValidUntil: "This quote is valid until",
    quoteDisclaimer:
      "If no payment is made before that time, your quote will auto-cancel. Please read the booking details carefully and contact us if you would like change any of the details. If you would like to convert your quote into a booking, a payment must be made.",
    bookingEmail: "Booking email",
    fullName: "Full name",
    cabinNumber: "Cabin number",
    vehicle: "Vehicle",
    vehicle_plural: "Vehicles",
    fromTo: "From — to",
    passengerInfo: "Passenger info", //TODO: Translate me
    travellingWith: "Travelling with",
    exploring: "Exploring",
    total: "Total",
    perPerson: "Per person"
  },
  alternativeTexts: {
    // These texts are used by screen readers to tell what an image contains
    excursionImage: "Excursion image"
  },
  warning: {
    departureTime: "Please note that your voyage departs after midnight."
  },
  updateGuestEmail: {
    addEmail: "Add email",
    editEmail: "edit",
    close: "close",
    updateButtonText: "Save",
    placeholder: "Email@domain.com",
    resultSuccess: "Email updated successfully!",
    resultError: "Something went wrong, please try again"
  },
  accessLink: {
    showLink: "Show access link",
    hideLink: "Hide access link",
    copy: "Copy",
    close: "close",
    description: "Can access their booking information with this link."
  },
  whosTraveling: {
    title: "Who's traveling?"
  },
  upcomingVoyage: {
    voyageDateDeteails: `Voyage begins {{startDate}}, ends {{endDate}}`
  },
  footer: {
    termsAndConditions: "Terms and Conditions",
    statementOfPrivacy: "Statement of Privacy",
    cookies: "Cookies",
    faq: "FAQ"
  },
  upgrade: {
    title: "Possible upgrade",
    readMore: "Read more about platinum fare",
    button: "Request platinum",
    loading: "Loading",
    requestSuccess:
      "Your request has been sent. We will contact you with further information.",
    requestError:
      "Looks like something went wrong. Please try again. Or contact us by phone or by email.",
    upgradeInitiated:
      "Thank you for upgrading to Platinum. We’ll be in touch with more details soon.",
    upgradeInProgress:
      "We’re preparing to send you an email about your Platinum upgrade.",
    upgradeDeclined:
      "We didn’t receive your confirmation, so we haven’t been able to upgrade you to Platinum. Please contact us if you do decide to upgrade.",
    upgradeApproved:
      "Congratulations – you've successfully been upgraded to Platinum!"
  }
};

export default english;
