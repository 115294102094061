import { useState, useEffect } from "react";

const getSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
};

export const useScreenWidth = () => {
  const [width, setWidth] = useState(getSize().width);

  const handleResize = () => {
    setWidth(getSize().width);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // empty dependency array, to call function once
  return width;
};
