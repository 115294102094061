import React, { useState } from "react";
import { useTrans } from "i18n";
import { Margin } from "styling/spacing";
import { dateToString } from "utilities/time";
import { SailingPlanDetail } from "models/BookingOverviewData";
import styled from "styled-components/macro";
import { spacings, spacingBottom } from "styling/spacing";
import {
  colors,
  Icon,
  TabularText,
  TagText,
  InfoText
} from "@hurtigruten/shared-components";
import { TextWithLine } from "./EndPort";

const GreyButton = styled.button`
  display: flex;
  align-items: center;
  padding: ${spacings.one};
  background: ${colors.warmGray.shade1};
  border: none;
  color: black;
  font-family: Atlas Grotesk;
  cursor: pointer;
  ${spacingBottom}

  &:hover {
    background: ${colors.warmGray.shade2};
  }

  & > * {
    font-size: 16px;
  }
`;

interface SailingPlanProps {
  sailingPlan?: SailingPlanDetail[];
  duration: string;
}

const SailingPlan: React.FC<SailingPlanProps> = ({ sailingPlan, duration }) => {
  const { translate, langCode } = useTrans();
  const [showSailingPlan, setShowSailingPlan] = useState(false);

  if (!sailingPlan || sailingPlan.length < 2) {
    return (
      <Margin marginBottom="three" marginLeft="two">
        <TabularText>{duration}</TabularText>
      </Margin>
    );
  }

  const labelId = `label-${sailingPlan[0].arrivalPortName}`;
  const regionId = `region-${sailingPlan[0].arrivalPortName}`;

  const numberOfStops = sailingPlan.length - 1;

  return (
    <Margin marginBottom="three">
      <GreyButton
        marginBottom={showSailingPlan ? "two" : "none"}
        as="button"
        role="button"
        aria-expanded={showSailingPlan}
        aria-controls={regionId}
        aria-label="label"
        id={labelId}
        tabIndex={0}
        onClick={() => setShowSailingPlan(!showSailingPlan)}
      >
        <Icon
          iconType={
            showSailingPlan ? "circleWithMinusSmall" : "circleWithPlusSmall"
          }
        />
        <TagText marginLeft="two">{`${numberOfStops} ${translate(x =>
          numberOfStops > 1 ? x.words.stop_plural : x.words.stop
        )} (${duration})`}</TagText>
      </GreyButton>
      <div
        role="region"
        aria-labelledby={labelId}
        id={regionId}
        hidden={!showSailingPlan}
      >
        {showSailingPlan &&
          sailingPlan.map((sailingPlanDetails, index) => {
            if (index === numberOfStops) {
              return null;
            }

            return (
              <Margin marginLeft="one" key={sailingPlanDetails.arrivalPortName}>
                <TextWithLine marginBottom="one">
                  {sailingPlanDetails.arrivalPortName}
                </TextWithLine>
                <InfoText marginBottom="one">
                  {translate(x => x.words.arrival)}:{" "}
                  {dateToString(
                    sailingPlanDetails.arrivalDateTime,
                    langCode,
                    true
                  )}
                </InfoText>
                <InfoText marginBottom="two">
                  {translate(x => x.words.departure)}:{" "}
                  {dateToString(
                    sailingPlan[index + 1].departureDateTime,
                    langCode,
                    true
                  )}
                </InfoText>
              </Margin>
            );
          })}
      </div>
    </Margin>
  );
};

export default SailingPlan;
