import styled from "styled-components";
import { media } from "utilities/media";

const ExpandableBoxContentContainer = styled.div`
  padding: 20px;

  ${media.maxWidth.medium} {
    padding: 0 10px;
  }
`;

export default ExpandableBoxContentContainer;
