import { Translate } from "i18n";

export const calculateDaysUntilDeparture = (departureDate: Date): number => {
  const currentDate = Date.now();

  return Math.floor(
    (departureDate.getTime() - currentDate) / (60 * 60 * 24 * 1000)
  );
};

export const getDateDifferenceInDays = (
  startDateTime: Date,
  endDateTime: Date
) => {
  // Removes time from Date object and counts number of times midnight is passed
  return Math.floor(
    (Date.UTC(
      endDateTime.getFullYear(),
      endDateTime.getMonth(),
      endDateTime.getDate()
    ) -
      Date.UTC(
        startDateTime.getFullYear(),
        startDateTime.getMonth(),
        startDateTime.getDate()
      )) /
      (1000 * 60 * 60 * 24)
  );
};

export const getDuration = ({
  useHours,
  useMinutes = false,
  useMinutesIfLessThanDay = false,
  startDateTime,
  endDateTime,
  translate
}: {
  useHours: boolean;
  useMinutes?: boolean;
  useMinutesIfLessThanDay?: boolean;
  startDateTime: Date;
  endDateTime: Date;
  translate: Translate;
}): string => {
  const diffMs = Math.ceil(endDateTime.getTime() - startDateTime.getTime());
  const diffDays = Math.floor(diffMs / 86400000); // days
  const diffHours = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  const addHours = useHours && diffHours > 0;
  const addMinutes =
    (useMinutes || (useMinutesIfLessThanDay && diffDays < 1)) &&
    diffMinutes > 0;

  let durationString = "";

  if (diffDays > 0) {
    durationString = `${diffDays} ${translate(x =>
      diffDays > 1 ? x.words.day_plural : x.words.day
    )}`;

    if (addHours) {
      durationString += " ";
    }
  }

  if (addHours) {
    durationString += `${diffHours} ${translate(x =>
      diffHours > 1 ? x.words.hour_plural : x.words.hour
    )}`;

    if (addMinutes) {
      durationString += " ";
    }
  }

  if (addMinutes) {
    durationString += `${diffMinutes} ${translate(x =>
      diffMinutes > 1 ? x.words.minute_plural : x.words.minute
    )}`;
  }

  return durationString;
};

const mapLanguageToLocale = (lang: string): string => {
  switch (lang) {
    case "au":
      return "en-au";
    case "gb":
      return "en-gb";
    case "us":
      return "en-us";
    case "no":
      return "nb-no";
    case "sv":
      return "sv-se";
    case "da":
      return "da-dk";
    case "de":
      return "de-de";
    case "fr":
      return "fr-fr";
    default:
      return "en-gb";
  }
};

export const dateToString = (
  date: Date,
  lang: string,
  useHours = false,
  useYear = true,
  shortMonth = true
): string => {
  return date.toLocaleDateString(mapLanguageToLocale(lang), {
    year: useYear ? "numeric" : undefined,
    month: shortMonth ? "short" : "long",
    day: "numeric",
    hour: useHours ? "numeric" : undefined,
    minute: useHours ? "numeric" : undefined,
    timeZone: "UTC"
  });
};
