import React from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components/macro";
import { media } from "utilities/media";
import { useLazyLoadedImage } from "hooks/useLazyLoadedImage";
import FullScreen from "./FullScreen";

const BackgroundImage = styled(FullScreen)`
  background-image: url(${(props: { source: string }) => props.source});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${media.minWidth.medium} {
    padding-top: 50px;
  }
  ${media.maxWidth.medium} {
    background: white;
  }
`;

const FullscreenBackgroundImage: React.FC<RouteComponentProps> = ({
  children
}) => {
  const source = useLazyLoadedImage(
    "/images/large-hrg-mybooking-background.jpg",
    "/images/small-hrg-mybooking-background.jpg"
  );

  return <BackgroundImage source={source}>{children}</BackgroundImage>;
};

export default FullscreenBackgroundImage;
